import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthStore } from './hooks.config';

export const useAuth = () => {
    const dispatch = useDispatch();
    const [loggedIn, setLoggedIn] = useState(false);
    useEffect(() => {
        AuthStore.authHeader()
            .then(() => {
                setLoggedIn(true);
            })
            .catch(() => {
                setLoggedIn(false);
            });
    }, []);
    const login = () => {
        setLoggedIn(true);
    };
    const logout = () => {
        // eslint-disable-next-line no-restricted-globals
        const cnfrm = confirm('Offline data will be lost on logout. Make sure there is no red sync sign in the top right corner!');
        if (cnfrm) {
            dispatch({ type: 'persist/PURGE', result: () => {} });
            AuthStore.logout();
            setLoggedIn(false);
            window.location.reload();
        }
    };

    return [loggedIn, login, logout];
};
