import * as R from 'ramda';
import React from 'react';
import { ListRow } from '../../Common/ListRow';
import { ProtocolTop } from './ProtocolTop';
import { TopButtonRow } from './TopButtonRow';

export const OppActions = ({
    stack,
    actionBags,
    dissocAction,
    canWriteProject,
    canAdminProject,
    editMessage,
    subprojectId,
    noEdit,
}) => (
    <div>
        {R.map(ab => (
            <ListRow
                key={ab.id}
                style={{ boxShadow: '0px 0px 10px 0px var(--shadow-color-light)' }}
            >
                <div className="top-search-row">
                    <TopButtonRow
                        stack={stack}
                        top={ab}
                        scorecard
                        actions
                        canAdminProject={canAdminProject}
                        canWriteProject={canWriteProject}
                        dissocAction={dissocAction}
                        oppId={ab.oppId}
                        actionId={ab.nodeId}
                        editMessage={editMessage}
                        subprojectId={subprojectId}
                        noEdit={noEdit}
                    />
                    <ProtocolTop
                        stack={stack}
                        topId={ab.id}
                        editable={false}
                        scorecard
                    />
                </div>
            </ListRow>
        ))(actionBags)}
    </div>
);
