import React from 'react';
import * as R from 'ramda';
import { FaSave } from 'react-icons/fa';
import { RiHomeSmileFill } from 'react-icons/ri';
import { useModalState } from '../../../hooks/new/useModal';
import { Button } from '../../Common/Controls';
import { ImageField, TextField } from '../../Common/Fields';
import { Modal } from '../../Common/Modal';

export const NewOrgModal = ({
    organization,
    organizationId,
    organizationLogoUrl,
    save,
    update,
    setFile,
}) => {
    const [isOpen, close] = useModalState('new-org');
    return (
        <Modal
            name="new-org"
            style={{
                height: '55vh',
                width: '38vw',
                top: '22.5vh',
                left: '31vw',
            }}
            topbar={(
                <>
                    <RiHomeSmileFill size={25} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? (
                    <NewOrgModalContent
                        close={close}
                        isOpen={isOpen}
                        organization={organization}
                        organizationId={organizationId}
                        organizationLogoUrl={organizationLogoUrl}
                        save={save}
                        update={update}
                        setFile={setFile}
                    />
                )
                : null}
        </Modal>
    );
};

const NewOrgModalContent = ({
    organization,
    organizationId,
    organizationLogoUrl,
    save,
    update,
    setFile,
    close,
}) => (
    <div className="columns">
        <div>
            <div className="opp-headline" style={{ marginBottom: 15 }}>
                New organization
            </div>
            <TextField
                label="Organization name"
                value={organization.name || ''}
                onChange={value => update(R.assoc('name', value, organization))}
            />
            <ImageField
                label="Organization logo"
                value={organizationLogoUrl || ''}
                onChange={file => {
                    setFile(organizationId)('organizationLogo', file.name, file);
                    save();
                }}
                className="org-img-field"
            />
            <div style={{
                display: 'flex', flex: 1, justifyContent: 'center',
            }}
            >
                <Button
                    onClick={() => {
                        save();
                        close();
                    }}
                    style={{ width: 200 }}
                >
                    <FaSave size={24} color="var(--corporate-color-1)" />
                    <p style={{ fontSize: 16, marginLeft: 12, fontWeight: 'bold' }}>Save</p>
                </Button>
            </div>
        </div>
    </div>
);
