import React, { useEffect, useState } from 'react';
import { IoReaderOutline } from 'react-icons/io5';
import { FaProjectDiagram, FaRegEdit, FaSave } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import {
    DASHBOARD,
    MAIN,
    MEETING_ATTACHMENT_EDGE,
    MEETING_ATTENDEE_EDGE,
    MEETING_ID_KEY,
    MEETING_TOP_EDGE,
    OFFLINE,
    PROJECT_ID_KEY,
    PROTOCOL,
    PROTOCOL_VARS,
    SUBPROJECT_ID_KEY,
    TOP_RESPONSIBLE_EDGE,
    useGraph,
} from '../../../common/hooks.config.new';
import { useAttachments } from '../../../hooks/new/useAttachments';
import { useInitProjectChoices } from '../../../hooks/new/useChoices';
import { useModal } from '../../../hooks/new/useModal';
import { useToast } from '../../../hooks/new/useToast';
import { Button } from '../../Common/Controls';
import { Screen } from '../../Common/Screen';
import { MeetingAttendees } from '../../Elements/MeetingAttendees';
import { MeetingTops } from '../../Elements/MeetingTops';
import { Meeting } from '../../Elements/new/Meeting';
import { OfflineSyncer } from '../../Elements/new/OfflineSyncer';
import { ProjectIndicator } from '../../Elements/new/ProjectSelector';
import { Toast } from '../../Elements/new/Toast';
import { AttachmentsModal } from '../../Modals/new/AttachmentsModal';
import { ContactsModal } from '../../Modals/new/ContactsModal';
import { PrintModal } from '../../Modals/new/PrintModal';
import { ReminderModal } from '../../Modals/new/ReminderModal';
import { ProjectOppsModal } from '../../Modals/new/ProjectOppsModal';
import { ScoreCardModal } from '../../Modals/new/ScoreCardModal';
import { useNode } from '../../../hooks/new/useNode';
import '../ProtocolScreen.css';
import { ProtocolTopCalculator } from '../../Elements/new/ProtocolTopCalculator';
import { createOfflinePush } from '../../../common/got-adjunct';
import { useNavigator } from '../../../hooks/new/useNavigator';
import { ProjectActionsModal } from '../../Modals/new/ProjectActionsModal';
import { ReadingView } from '../../Elements/new/ReadingView';

const stack = [MAIN, OFFLINE, DASHBOARD, PROTOCOL_VARS, PROTOCOL];
const varsStack = [MAIN, OFFLINE, DASHBOARD, PROTOCOL, PROTOCOL_VARS];

export const ProtocolScreen = () => {
    const {
        useVar,
        pull,
    } = useGraph(...stack);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [meetingId] = useVar(MEETING_ID_KEY);

    const { refresh } = useAttachments(stack, meetingId, MEETING_ATTACHMENT_EDGE);

    useInitProjectChoices([MAIN, DASHBOARD]);

    const meetingView = {
        [meetingId]: {
            include: {
                node: true,
                // files: true,
            },
            edges: {
                [MEETING_TOP_EDGE]: {
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                    edges: {
                        [TOP_RESPONSIBLE_EDGE]: {
                            include: {
                                node: true,
                                edges: true,
                            },
                        },
                    },
                },
                [MEETING_ATTENDEE_EDGE]: {
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                },
            },
        },
    };

    const { node: meeting } = useNode(stack, meetingId);

    useEffect(() => {
        meetingId && pull(meetingView);
    }, [meetingId]);

    useEffect(() => {
        meetingId && refresh();
    }, [meetingId]);

    const openContactsModal = useModal('contacts');
    const openAttachmentsModal = useModal('attachments');
    const openPrintModal = useModal('print');
    const setProtocolToast = useToast('protocol');
    const setOfflineSyncToast = useToast('offlineSync');

    const toastConfig = {
        textOnStart: 'Saving protocol data...',
        textOnSuccess: 'Successfully saved.',
    };
    const push = createOfflinePush(stack, setProtocolToast, toastConfig);

    const { navigateToDashboard } = useNavigator();

    const [readingMode, setReadingMode] = useState(false);

    return (
        <>
            <Screen
                topbar={(
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="protocol-screen-upper-topbar">
                            <div style={{ display: 'flex', flex: '1' }}>
                                <Button
                                    className="dashboard-toggle"
                                    onClick={() => {
                                        navigateToDashboard();
                                    }}
                                >
                                    <FaProjectDiagram size={20} />
                                    <p style={{ marginLeft: 9 }}>Project Dashboard</p>
                                </Button>
                            </div>
                            <div style={{ display: 'flex', flex: '2', justifyContent: 'center' }}>
                                <ProjectIndicator stack={stack} />
                            </div>
                            <div style={{ display: 'flex', flex: '1', justifyContent: 'flex-end' }}>
                                <div style={{ display: 'flex', flex: 2, justifyContent: 'flex-end' }}>
                                    <Button
                                        onClick={() => setReadingMode(!readingMode)}
                                        style={{ color: 'white' }}
                                    >

                                        {readingMode
                                            ? (
                                                <div
                                                    style={{ display: 'flex', alignItems: 'center', color: 'white' }}
                                                >
                                                    <FaRegEdit size={21} />
                                                    <p style={{ marginLeft: 9 }}>Editing mode</p>
                                                </div>
                                            )
                                            : (
                                                <div
                                                    style={{ display: 'flex', alignItems: 'center', color: 'white' }}
                                                >
                                                    <IoReaderOutline size={22} />
                                                    <p style={{ marginLeft: 9 }}>Reading mode</p>
                                                </div>
                                            )}
                                    </Button>
                                </div>
                                <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                                    <OfflineSyncer
                                        graphNames={[OFFLINE, PROTOCOL]}
                                        mergeToGraphName={MAIN}
                                        onProgress={() => setOfflineSyncToast('spinner', 'Syncing offline data...')}
                                        onSuccess={() => setOfflineSyncToast('success', 'Successfully saved.', 5000)}
                                        onError={error => {
                                            if (error.status) {
                                                setOfflineSyncToast('error', error.message ? error.message : error.toString());
                                            } else {
                                                setOfflineSyncToast('error', 'You are offline. Please sync when back online.', 5000);
                                            }
                                        }}
                                    >
                                        <FaSave size={24} color="white" />
                                    </OfflineSyncer>
                                    <Button
                                        onClick={async () => {
                                            await push();
                                            openPrintModal();
                                        }}
                                    >
                                        <FiMail size={28} color="white" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                lpheight={60}
            >
                {meeting && (
                    <>
                        {readingMode
                            ? (

                                <div>
                                    <ReadingView
                                        stack={stack}
                                        meetingId={meetingId}
                                    />
                                </div>
                            )
                            : (
                                <>
                                    <div
                                        className="meeting-header"
                                    >
                                        <Meeting
                                            stack={stack}
                                            meeting={meeting}
                                            meetingId={meetingId}
                                            subprojectId={subprojectId}
                                            projectId={projectId}
                                            openAttachmentsModal={openAttachmentsModal}
                                        />
                                    </div>
                                    {meeting.type && meeting.businessId
                                        ? (
                                            <>
                                                <div className="content-header-container">
                                                    <div className="attendees-section">
                                                        <div style={{
                                                            display: 'flex', justifyContent: 'center', marginBottom: 6, color: 'var(--corporate-color-1-light2)',
                                                        }}
                                                        >
                                                            Meeting Attendees
                                                        </div>
                                                        <MeetingAttendees
                                                            stack={stack}
                                                            meetingId={meetingId}
                                                            openContactsModal={openContactsModal}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: 0, marginBottom: 12 }}>
                                                    <div style={{
                                                        display: 'flex', justifyContent: 'center', marginTop: 6, color: 'var(--corporate-color-1-light2)',
                                                    }}
                                                    >
                                                        Meeting Content
                                                    </div>
                                                    <MeetingTops
                                                        stack={stack}
                                                        meetingId={meetingId}
                                                        inheritFromId={subprojectId}
                                                    />
                                                </div>
                                            </>
                                        )
                                        : (
                                            <div
                                                className="protocol-notice"
                                            >
                                                Please select a meeting type in the dropdown menu at the top left to create a meeting ID
                                            </div>
                                        )}
                                </>
                            )}
                    </>
                ) }
            </Screen>
            <ContactsModal
                stack={stack}
            />
            <AttachmentsModal
                stack={stack}
                fromId={meetingId}
                edgeTypes={MEETING_ATTACHMENT_EDGE}
                inheritFromId={subprojectId}
            />
            <ProjectOppsModal
                stack={stack}
                noEdit
            />
            <ProjectActionsModal
                stack={stack}
                noEdit
            />
            <ScoreCardModal
                stack={stack}
                noEdit
            />
            <PrintModal
                stack={stack}
            />
            <ReminderModal
                stack={stack}
            />
            <ProtocolTopCalculator stack={varsStack} />
            <Toast name="protocol" />
            <Toast name="offlineSync" />
        </>
    );
};
