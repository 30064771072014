import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { FaSave } from 'react-icons/fa';
import { SiOpenaccess } from 'react-icons/si';
import { ImCross } from 'react-icons/im';
import { Modal } from '../../Common/Modal';
import {
    ListField,
} from '../../Common/Fields';
import { DropdownField } from '../../Common/Dropdown';
import { Button, Checkbox } from '../../Common/Controls';
import { useModalState } from '../../../hooks/new/useModal';
import { constructNodeView, rightsOverView } from '../../../common/got-adjunct';
import {
    ORGANIZATION_ID_KEY,
    CONTACT_DIRECTORY_CONTACT_EDGE,
    MEETING_TOP_EDGE,
    PROJECT_SUBPROJECT_EDGE,
    store,
    SUBPROJECT_MEETING_EDGE,
    useGraph,
} from '../../../common/hooks.config.new';
import { useUserEmail } from '../../../hooks/new/useUserEmail';
import { Id } from '../../../common/util';

const constructContactsView = projectId => projectId
    ? {
        [Id.contactDirectory(projectId)]: {
            as: 'contactDirectory',
            edges: {
                [CONTACT_DIRECTORY_CONTACT_EDGE]: {
                    as: 'contacts',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

export const ProjectRightsModal = ({
    stack, save,
}) => {
    const [isOpen] = useModalState('project-rights');
    return (
        <Modal
            name="project-rights"
            style={{
                width: '50vw',
                left: '25vw',
            }}
            topbar={(
                <>
                    <SiOpenaccess size={23} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? <ModalContent stack={stack} save={save} />
                : null}
        </Modal>
    );
};

export const ModalContent = ({
    stack,
    save,
}) => {
    const {
        useView, mergeGraph, pull, useVar, setRights,
    } = useGraph(...stack);
    const [organizationId] = useVar(ORGANIZATION_ID_KEY);
    const [projectId] = useModalState('project-rights');
    const projectRightsView = constructNodeView(projectId, { rights: true });
    const { [projectId]: { rights } = {} } = useView(projectRightsView);

    const user = useUserEmail();
    const userRights = R.compose(
        R.sortBy(R.ifElse(
            R.propOr(false, 'isCurrentUser'),
            R.always('0000'),
            R.prop('user'),
        )),
        R.filter(R.anyPass([
            R.propOr(false, 'read'),
            R.propOr(false, 'write'),
            R.propOr(false, 'admin'),
        ])),
        R.values,
        R.mapObjIndexed(
            (val, key) => ({ ...val, user: key, isCurrentUser: key === user }),
        ),
        R.prop('user'),
    )(rights);

    const fullProjectView = constructProjectView(projectId);
    const [pullFullProjectPromise, setPullFullProjectPromise] = useState();
    useEffect(() => {
        const promise = store.pull(fullProjectView, R.head(stack));
        setPullFullProjectPromise(promise);
    }, []);

    const setProjectRights = async (email, rightsPatch) => {
        await pullFullProjectPromise;
        const graph = rightsOverView(...stack)(fullProjectView)(email, rightsPatch)({});
        mergeGraph(graph);
    };

    const contactsView = constructContactsView(projectId);
    useEffect(() => projectId && pull(contactsView), [projectId]);
    const { contactDirectory } = useView(contactsView);
    const contacts = R.compose(
        R.values,
        R.map(R.prop('node')),
        R.propOr({}, 'contacts'),
    )(contactDirectory);

    return (
        <div className="columns">
            <div>
                <div className="field-column">
                    <DropdownField
                        options={R.compose(
                            R.sortBy(R.prop('label')),
                            R.map(contact => ({ value: contact.id, label: contact.name, body: contact })),
                        )(contacts)}
                        onChange={contact => {
                            setProjectRights(contact.body.email, { read: true });
                            setRights(organizationId)(contact.body.email, {
                                read: true,
                            });
                        }}
                        label="Existing contacts"
                    />
                </div>
                <ListField
                    className
                    style={{ flex: 1 }}
                    label="Authorized contacts"
                >
                    { R.map(
                        userRight => (
                            <div
                                key={userRight.user}
                                className="row"
                            >
                                <div>{userRight.user}</div>
                                <div className="buttons">
                                    <Checkbox
                                        label="Read"
                                        value={userRight.read}
                                        onChange={isRight => {
                                            setProjectRights(userRight.user, { read: isRight });
                                        }}
                                    />
                                    <Checkbox
                                        label="Write"
                                        value={userRight.write}
                                        onChange={isRight => setProjectRights(userRight.user, { write: isRight })}
                                    />
                                    <Checkbox
                                        label="Admin"
                                        value={userRight.admin}
                                        onChange={isRight => setProjectRights(userRight.user, { admin: isRight })}
                                    />
                                    <Button
                                        onClick={() => {
                                            setProjectRights(userRight.user, {
                                                read: false,
                                                write: false,
                                                admin: false,
                                            });
                                        }}
                                        disabled={userRight.isCurrentUser}
                                    >
                                        <ImCross size={10} />
                                    </Button>
                                </div>
                            </div>
                        ),
                    )(userRights)}
                </ListField>
                <div style={{
                    display: 'flex', flex: 1, justifyContent: 'center',
                }}
                >
                    <Button
                        onClick={save}
                        style={{ width: 200 }}
                    >
                        <FaSave size={24} color="var(--corporate-color-1)" />
                        <p style={{ fontSize: 16, marginLeft: 12, fontWeight: 'bold' }}>Save</p>
                    </Button>
                </div>
            </div>
        </div>
    );
};

const constructProjectView = projectId => projectId
    ? {
        [projectId]: {
            edges: {
                [PROJECT_SUBPROJECT_EDGE]: {
                    include: {
                        edges: true,
                    },
                    edges: {
                        [SUBPROJECT_MEETING_EDGE]: {
                            include: {
                                edges: true,
                            },
                            edges: {
                                [MEETING_TOP_EDGE]: {
                                    include: {
                                        edges: true,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        [Id.contactDirectory(projectId)]: {
            edges: {
                [CONTACT_DIRECTORY_CONTACT_EDGE]: {
                    include: {
                        edges: true,
                    },
                },
            },
        },
    }
    : {};
