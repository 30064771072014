import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { FaSave } from 'react-icons/fa';
import { SiOpenaccess } from 'react-icons/si';
import { ImCross } from 'react-icons/im';
import { Modal } from '../../Common/Modal';
import {
    ListField,
} from '../../Common/Fields';
import { DropdownField } from '../../Common/Dropdown';

import { Button, Checkbox } from '../../Common/Controls';
import { useModalState } from '../../../hooks/new/useModal';
import { rightsOverView } from '../../../common/got-adjunct';
import {
    CONTACT_DIRECTORY_CONTACT_EDGE,
    PROJECT_ID_KEY, useGraph,
} from '../../../common/hooks.config.new';
import { useUserEmail } from '../../../hooks/new/useUserEmail';
import { Id } from '../../../common/util';

const constructContactsView = projectId => projectId
    ? {
        [Id.contactDirectory(projectId)]: {
            as: 'contactDirectory',
            include: {
                rights: true,
            },
            edges: {
                [CONTACT_DIRECTORY_CONTACT_EDGE]: {
                    as: 'contacts',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

export const CDRightsModal = ({
    stack, push,
}) => {
    const [isOpen] = useModalState('cd-rights');
    return (
        <Modal
            name="cd-rights"
            style={{
                width: '50vw',
                left: '25vw',
            }}
            topbar={(
                <>
                    <SiOpenaccess size={23} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? <ModalContent stack={stack} push={push} />
                : null}
        </Modal>
    );
};

export const ModalContent = ({
    stack,
    push,
}) => {
    const {
        useView, mergeGraph, pull, useVar,
    } = useGraph(...stack);
    const [projectId] = useVar(PROJECT_ID_KEY);

    const contactsView = constructContactsView(projectId);

    const [pullFullSubprojectPromise, setPullFullSubprojectPromise] = useState();
    useEffect(() => {
        const promise = pull(contactsView);
        setPullFullSubprojectPromise(promise);
    }, []);

    const { contactDirectory } = useView(contactsView);

    const contacts = R.compose(
        R.values,
        R.map(R.prop('node')),
        R.propOr({}, 'contacts'),
    )(contactDirectory);

    const user = useUserEmail();
    const userRights = R.compose(
        R.sortBy(R.ifElse(
            R.propOr(false, 'isCurrentUser'),
            R.always('0000'),
            R.prop('user'),
        )),
        R.filter(R.anyPass([
            R.propOr(false, 'read'),
            R.propOr(false, 'write'),
            R.propOr(false, 'admin'),
        ])),
        R.values,
        R.mapObjIndexed(
            (val, key) => ({ ...val, user: key, isCurrentUser: key === user }),
        ),
        R.pathOr({}, ['rights', 'user']),
    )(contactDirectory);

    const setRights = async (email, rightsPatch) => {
        await pullFullSubprojectPromise;
        const graph = rightsOverView(...stack)(contactsView)(email, rightsPatch)({});
        mergeGraph(graph);
    };

    return (
        <div className="columns">
            <div>
                <div className="field-column">
                    <DropdownField
                        options={R.compose(
                            R.sortBy(R.prop('label')),
                            R.map(contact => ({ value: contact.id, label: contact.name, body: contact })),
                        )(contacts)}
                        onChange={contact => setRights(contact.body.email, { read: true })}
                        label="Existing contacts"
                    />
                </div>
                <ListField
                    className
                    style={{ height: '47.5vh' }}
                    label="Authorized contacts"
                >
                    { R.map(
                        userRight => (
                            <div
                                key={userRight.user}
                                className="row"
                            >
                                <div>{userRight.user}</div>
                                <div className="buttons">
                                    <Checkbox
                                        label="Read"
                                        value={userRight.read}
                                        onChange={isRight => setRights(userRight.user, { read: isRight })}
                                    />
                                    <Checkbox
                                        label="Write"
                                        value={userRight.write}
                                        onChange={isRight => setRights(userRight.user, { write: isRight })}
                                    />
                                    <Checkbox
                                        label="Admin"
                                        value={userRight.admin}
                                        onChange={isRight => setRights(userRight.user, { admin: isRight })}
                                    />
                                    <Button
                                        onClick={() => {
                                            setRights(userRight.user, {
                                                read: false,
                                                write: false,
                                                admin: false,
                                            });
                                        }}
                                        disabled={userRight.isCurrentUser}
                                    >
                                        <ImCross size={10} />
                                    </Button>
                                </div>
                            </div>
                        ),
                    )(userRights)}
                </ListField>
                <div style={{
                    display: 'flex', flex: 1, justifyContent: 'center',
                }}
                >
                    <Button
                        onClick={push}
                        style={{ width: 200 }}
                    >
                        <FaSave size={24} color="var(--corporate-color-1)" />
                        <p style={{ fontSize: 16, marginLeft: 12, fontWeight: 'bold' }}>Save</p>
                    </Button>
                </div>
            </div>
        </div>
    );
};
