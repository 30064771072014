import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

export const applyFilter = filter => R.compose(
    sort(
        R.path(['meetingDate', 'sort'], filter),
        R.path(['meetingDate']),
    ),
    sort(
        R.path(['dueDate', 'sort'], filter),
        R.path(['top', 'dueDate']),
    ),
    sort(
        R.path(['status', 'sort'], filter),
        R.path(['top', 'status']),
    ),
    R.filter(R.compose(
        between(
            R.pathOr('2000-01-01', ['meetingDate', 'from'], filter),
            R.pathOr('9999-31-12', ['meetingDate', 'to'], filter),
        ),
        R.prop('meetingDate'),
    )), // ['meetingDate']
    R.filter(R.compose(
        between(
            R.pathOr('2000-01-01', ['dueDate', 'from'], filter),
            R.pathOr('9999-31-12', ['dueDate', 'to'], filter),
        ),
        R.pathOr('2000-01-02', ['top', 'dueDate']),
    )), // ['top', 'dueDate']
    R.filter(t => {
        const statuses = filter && filter.status
            ? hashKeysTrue(filter.status.values)
            : [];
        return R.ifElse(
            () => statuses.length > 0,
            R.includes(t.top.status),
            R.T,
        )(statuses);
    }), // ['top', 'status']
    R.filter(R.propOr(false, 'topType')),
    R.defaultTo([]),
);

const hashKeysTrue = R.compose(
    R.keys,
    R.pickBy(R.identity),
);

const between = (v1, v2) => value => v1 <= value && value <= v2;

const sort = (direction, selector) => direction === 'asc' || direction === 'desc'
    ? R.sortWith([
        direction === 'asc' ? R.ascend(selector) : R.descend(selector),
    ])
    : R.identity;

export const keywordSearch = (searchString, tops) => { // TODO a single function to filter for a whole search string
    const string = searchString.replace(/[^a-zA-Z0-9" "]/g, '').toUpperCase();
    if (string.length > 0
        && !R.startsWith('#', string)
        && !R.startsWith('+', string)
        && !R.startsWith('@', string)) {
        return tops.filter(t => (t.top.description
                && t.top.description.toUpperCase().search(string) !== -1)
                || (t.previousVersions.length > 0
                    && t.previousVersions.length * -1
                    !== t.previousVersions.map(
                        p => !p.top.description
                            ? -1
                            : p.top.description.toUpperCase().search(string),
                    ).reduce((a, b) => a + b, 0)
                )
                || (
                    t.businessId && t.businessId.toUpperCase().search(string) !== -1
                ));
    }

    return [];
};

export const searchAllTops = (searchInput, searchTops) => {
    const searchTerms = searchInput.split(',').filter(a => a);

    const filterSearchTops = term => R.cond([
        [R.always(R.startsWith('#', term)), R.filter(R.propSatisfies(R.includes(R.tail(term)), 'subproject'))],
        [R.always(R.startsWith('@', term)), R.filter(t => R.includes(R.tail(term), R.pathOr(false, ['responsible', 'name'], t)))],
        [R.always(R.startsWith('+', term)), R.filter(t => R.includes(R.tail(term), R.propOr(false, 'topType', t)))],
        [R.always(R.T), R.filter(top => top.topType === term)],
    ]);
    return searchTerms.length > 0
        ? R.compose(
            ...R.map(
                filterSearchTops,
            )(searchTerms),
        )(searchTops)
        : [];
};

export const filterTops = ({
    filter, searchString, dashboardState, subprojectBusinessId, userName,
}) => topRows => {
    let tops = [];

    switch (dashboardState) {
    case 'project':
        tops = applyFilter(filter)(topRows);
        break;
    case 'subproject':
        tops = applyFilter(filter)(searchAllTops(`#${subprojectBusinessId}`, topRows));
        break;
    case 'user':
    default:
        tops = applyFilter(filter)(searchAllTops(`@${userName}`, topRows));
        break;
    }

    if (RA.isNonEmptyString(searchString)) { // TODO if we could search for selectors and string at the same time, this would be way easier and smoother
        const searchedTops = searchAllTops(searchString, tops);
        if (searchedTops.length > 0) {
            return { searchedTops };
        }

        const keywordSearchedTops = keywordSearch(searchString, tops);
        return { searchedTops: keywordSearchedTops };
    }

    const unsortedActions = searchAllTops('a', tops);
    const actions = R.sortBy(R.pathOr('3000', ['top', 'dueDate']), unsortedActions);
    const opps = searchAllTops('o', tops);
    return { actions, opps };
};
