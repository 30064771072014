import React from 'react';
import * as R from 'ramda';
import { FaSave } from 'react-icons/fa';
import { BsPeopleFill } from 'react-icons/bs';
import { useModalState } from '../../../hooks/new/useModal';
import { Button } from '../../Common/Controls';
import { TextField } from '../../Common/Fields';
import { Modal } from '../../Common/Modal';

export const NewContactModal = ({
    contact,
    setContact,
    push,
}) => {
    const [isOpen, close] = useModalState('new-contact');
    return (
        <Modal
            name="new-contact"
            style={{
                height: '40vh',
                width: '38vw',
                top: '30vh',
                left: '31vw',
            }}
            topbar={(
                <>
                    <BsPeopleFill size={23} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? (
                    <NewContactModalContent
                        close={close}
                        contact={contact}
                        push={push}
                        setContact={setContact}
                    />
                )
                : null}
        </Modal>
    );
};

const NewContactModalContent = ({
    contact,
    setContact,
    push,
    close,
}) => (
    <div className="columns">
        <div>
            <div className="opp-headline" style={{ marginBottom: 15 }}>
                Contact
            </div>
            <TextField
                label="Name"
                value={contact.name || ''}
                onChange={value => setContact(R.assoc('name', value))}
            />
            <TextField
                label="E-Mail"
                value={contact.email || ''}
                onChange={value => setContact(R.assoc('email', value))}
            />
            <TextField
                label="Department"
                value={contact.department || ''}
                onChange={value => setContact(R.assoc('department', value))}
            />
            <div style={{
                display: 'flex', flex: 1, justifyContent: 'center',
            }}
            >
                <Button
                    onClick={() => {
                        push();
                        close();
                    }}
                    style={{ width: 200 }}
                >
                    <FaSave size={24} color="var(--corporate-color-1)" />
                    <p style={{ fontSize: 16, marginLeft: 12, fontWeight: 'bold' }}>Save</p>
                </Button>
            </div>
        </div>
    </div>
);
