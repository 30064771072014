import * as R from 'ramda';
// import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { FiMail } from 'react-icons/fi';
import { RiSendPlaneFill } from 'react-icons/ri';
import axios from 'axios';
import html2canvas from 'html2canvas';
import { useStore } from 'react-redux';
import { useModalState } from '../../../hooks/new/useModal';
import { Modal } from '../../Common/Modal';
import { LargeTextField, TextField } from '../../Common/Fields';
import '../PrintModal.css';
import { MAIN, useGraph } from '../../../common/hooks.config.new';
import { BACKEND_API_DOMAIN } from '../../../common/config';
import { AuthStore } from '../../../common/hooks.config';
import { Button } from '../../Common/Controls';
import { useToast } from '../../../hooks/new/useToast';
import { Toast } from '../../Elements/new/Toast';
import { useUserEmail } from '../../../hooks/new/useUserEmail';
import { useContact } from '../../../hooks/new/useContact';
import { ProtocolTop } from '../../Elements/new/ProtocolTop';
import { useNode } from '../../../hooks/new/useNode';
import { useProjectCurrency } from '../../../hooks/new/useChoices';

export const ReminderModal = ({
    stack,
}) => {
    const [isOpen, close] = useModalState('reminder');
    return (
        <Modal
            name="reminder"
            style={{
                height: '80vh',
                top: '10vh',
                width: '80vw',
                left: '10vw',
            }}
            topbar={(
                <>
                    <FiMail
                        size={24}
                        color="var(--corporate-color-7)"
                    />
                </>
            )}
        >
            {isOpen
                ? (
                    <ReminderModalContent
                        stack={stack}
                        isOpen={isOpen}
                        close={close}
                    />
                )
                : null}
        </Modal>
    );
};
//
const ReminderModalContent = ({ stack, isOpen, close }) => {
    const {
        useVar, setFile, push, pull,
    } = useGraph(...stack, 'top-reminder');
    const store = useStore();

    const [projectId] = useVar('projectId');
    const printRef = useRef();
    const [selectedTop] = useModalState('reminder');

    const topScreenshotView = {
        [selectedTop.top.id]: {
            as: 'top',
            include: {
                files: true,
            },
        },
    };

    const handleImage = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        canvas.toBlob(async blob => {
            const filename = `${selectedTop.top.businessId}.png`;
            setFile(selectedTop.top.id)('screenshot', filename, blob);
            const { uploads } = await push();
            uploads.subscribe({
                next: () => {},
                error: action => { throw new Error(action); },
                complete: async () => {
                    await pull(topScreenshotView);
                    const url = R.path(['got2', MAIN, 'graph', 'files', selectedTop.top.id, 'screenshot', 'url'], store.getState());
                    sendReminder({ filename, url });
                },
            });
            uploads.start();
        });
    };

    const { node: project } = useNode(stack, projectId);

    const user = useUserEmail();
    const { contact } = useContact(stack, projectId, user);

    const sender = contact && {
        name: contact.node.name,
        email: contact.node.email,
    };

    const currency = useProjectCurrency(stack);
    const formato = val => new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency,
    }).format(val);

    const elseBody = `Dear ${selectedTop.responsible ? selectedTop.responsible.name : 'responsible'},

please have a look at the following ${R.propOr('', selectedTop.top.type)({
        's': 'statement', 'd': 'decision', 'i': 'information',
    })}:

Business ID: ${selectedTop.top.businessId}
Description: ${selectedTop.top.description}

Best regards,
${selectedTop.userName}`;

    const riskBody = `Dear ${selectedTop.responsible ? selectedTop.responsible.name : 'responsible'},

please have a look at the following risk:

Business ID: ${selectedTop.top.businessId}
Description: ${selectedTop.top.description}
Reference: ${selectedTop.top.reference}
Category: ${selectedTop.top.category}
Cost increase: ${formato(selectedTop.top.costIncrease)}

Best regards,
${selectedTop.userName}`;

    const oppBody = `Dear ${selectedTop.responsible ? selectedTop.responsible.name : 'responsible'},

please have a look at the following opportunity:

Business ID: ${selectedTop.top.businessId}
Description: ${selectedTop.top.description}
Status: ${selectedTop.top.status}
Category: ${selectedTop.top.category}
Savings: ${formato(selectedTop.top.savings)}

Best regards,
${selectedTop.userName}`;

    const actionBody = `Dear ${selectedTop.responsible ? selectedTop.responsible.name : 'responsible'},

please have a look at the following action:

Business ID: ${selectedTop.top.businessId}
Description: ${selectedTop.top.description}
Status: ${selectedTop.top.status}
Due date: ${selectedTop.top.dueDate}

Best regards,
${selectedTop.userName}`;

    const [fields, setFields] = useState({});
    useEffect(() => {
        setFields({
            subject: `[${project.title}] - ${R.propOr('', selectedTop.top.type)({
                'a': 'Action', 'o': 'Opportunity', 'r': 'Risk', 's': 'Statement', 'd': 'Decision', 'i': 'Information',
            })} reminder`,
            body: R.propOr(elseBody, selectedTop.top.type)({ 'a': actionBody, 'o': oppBody, 'r': riskBody }),
        });
    }, [isOpen]);

    const setPrintToast = useToast('print');

    const sendReminder = async topScreenshot => {
        const authHeader = await AuthStore.authHeader();

        const recipients = selectedTop.responsible
            ? [
                {
                    name: selectedTop.responsible.name,
                    email: selectedTop.responsible.email,
                },
            ]
            : [];

        if (sender.name && recipients.length > 0) {
            setPrintToast('spinner', 'Sending mail...');
            await axios.post(
                `https://${BACKEND_API_DOMAIN}/send-protocol`,
                // 'http://localhost:4000/dev/send-protocol',
                {
                    sender,
                    recipients,
                    ...fields,
                    protocolUrl: '',
                    attachmentUrls: [topScreenshot],
                },
                authHeader,
            )
                .then(() => {
                    setPrintToast('success', 'Mail successfully sent.', 5000);
                    close();
                })
                .catch(error => setPrintToast('error', error.toString()));
        } else {
            setPrintToast('error', 'Some parts of your mail are missing.', 10000);
        }
    };
    return (
        <>
            <div className="columns">
                <div>
                    <TextField
                        label="Subject"
                        value={fields.subject}
                        onChange={value => setFields(R.assoc('subject', value))}
                    />
                    <LargeTextField
                        label="Body"
                        rows={18}
                        value={fields.body}
                        onChange={value => setFields(R.assoc('body', value))}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            onClick={() => {
                                handleImage();
                                // sendProtocol();
                            }}
                            style={{ width: '30%' }}
                        >
                            <div className="button-icon">
                                <RiSendPlaneFill size={25} />
                                <p style={{ marginLeft: 12, fontSize: 17 }}>Send</p>
                            </div>
                        </Button>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        Attachment as PNG:
                    </div>
                    <div
                        className="top-search-row"
                        ref={printRef}
                        style={{
                            border: '1px solid var(--corporate-color-1)',
                            borderRadius: '12px',
                            padding: 12,
                        }}
                    >
                        <div style={{ fontWeight: 'bold', color: 'var(--corporate-color-1)' }}>
                            {selectedTop.top.businessId}
                        </div>
                        <ProtocolTop
                            noButton
                            stack={stack}
                            editable={false}
                            topId={R.prop('id', selectedTop.top)}
                        />
                    </div>
                </div>
            </div>
            <Toast name="print" />
        </>
    );
};
