import { Util } from '@gothub-team/got-react';
import * as R from 'ramda';
import { useEffect, useMemo } from 'react';
import {
    MEETING_TOP_EDGE, PROJECT_SUBPROJECT_EDGE, SUBPROJECT_MEETING_EDGE, useGraph,
} from '../../common/hooks.config.new';

export const useProjectOpps = (stack, projectId) => {
    const { useView, pull } = useGraph(...stack);

    const projectTopsView = {
        [projectId]: {
            as: 'project',
            edges: {
                [PROJECT_SUBPROJECT_EDGE]: {
                    as: 'subprojects',
                    include: {
                        edges: true,
                    },
                    edges: {
                        [SUBPROJECT_MEETING_EDGE]: {
                            as: 'meetings',
                            include: {
                                edges: true,
                            },
                            edges: {
                                [MEETING_TOP_EDGE]: {
                                    as: 'tops',
                                    include: {
                                        node: true,
                                        edges: true,
                                        metadata: true,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    };

    useEffect(() => projectId && pull(projectTopsView), [projectId]);

    const {
        project: {
            subprojects,
        },
    } = useView(projectTopsView);

    const opps = useMemo(() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [getAllTops, setAllTops] = Util.useResult([]);
        R.map(
            ({ meetings }) => R.map(
                ({ tops }) => R.map(
                    ({ node: top }) => R.propEq('type', 'o', top) && setAllTops(
                        R.append(top),
                    ),
                )(tops || {}),
            )(meetings || {}),
        )(subprojects || {});
        return getAllTops();
    }, [subprojects]);

    return opps;
};
