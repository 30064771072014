import React from 'react';
import { IoMdLogOut } from 'react-icons/io';
import logo from '../../../logos/pclogo.svg';
import { Button } from '../../Common/Controls';
import { useAuth } from '../../../common/auth';
import { ProjectSelector } from './ProjectSelector';

export const GlobalDashboardTopBar = ({ stack }) => {
    const [, , logout] = useAuth();

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="dashboard-screen-upper-topbar border-radius-shadow">
                <div style={{ display: 'flex', flex: '1' }}>
                    <img alt="logo" src={logo} style={{ width: 200 }} />
                </div>
                <div style={{ display: 'flex', flex: '1', justifyContent: 'center' }}>
                    <ProjectSelector stack={stack} global />
                </div>
                <div style={{ display: 'flex', flex: '1', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={logout}
                    >
                        <IoMdLogOut size={27} color="white" />
                    </Button>
                </div>
            </div>
        </div>
    );
};
