import React from 'react';
import * as R from 'ramda';
import { FaProjectDiagram, FaSave } from 'react-icons/fa';
import { useModalState } from '../../../hooks/new/useModal';
import { Button } from '../../Common/Controls';
import { TextField } from '../../Common/Fields';
import { Modal } from '../../Common/Modal';

export const NewSubprojectModal = ({
    save,
    update,
    subproject,
    canWriteSubproject,
}) => {
    const [isOpen, close] = useModalState('new-subproject');
    return (
        <Modal
            name="new-subproject"
            style={{
                height: '55vh',
                width: '40vw',
                top: '22.5vh',
                left: '30vw',
            }}
            topbar={(
                <>
                    <FaProjectDiagram size={23} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? (
                    <NewSubprojectModalContent
                        close={close}
                        save={save}
                        update={update}
                        subproject={subproject}
                        canWriteSubproject={canWriteSubproject}
                    />
                )
                : null}
        </Modal>
    );
};

const NewSubprojectModalContent = ({
    save,
    update,
    close,
    subproject,
    canWriteSubproject,
}) => (
    <div className="columns">
        <div>
            <div className="opp-headline" style={{ marginBottom: 15 }}>
                New subproject
            </div>
            <TextField
                label="Subproject name"
                value={subproject.title || ''}
                onChange={value => update(R.assoc('title', value, subproject))}
                editable={canWriteSubproject}
            />
            <TextField
                label="Subproject ID"
                value={subproject.businessId || ''}
                onChange={value => update(R.assoc('businessId', value, subproject))}
                editable={canWriteSubproject}
            />
            <div style={{
                display: 'flex', flex: 1, justifyContent: 'center',
            }}
            >
                <Button
                    onClick={() => {
                        save();
                        close();
                    }}
                    style={{ width: 200 }}
                >
                    <FaSave size={24} color="var(--corporate-color-1)" />
                    <p style={{ fontSize: 16, marginLeft: 12, fontWeight: 'bold' }}>Save</p>
                </Button>
            </div>
        </div>
    </div>
);
