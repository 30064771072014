/* eslint-disable react-hooks/rules-of-hooks */
import { newId, newIsoDate, newMsDate } from '@gothub-team/got';
import * as R from 'ramda';
import { useDummyGraph } from './got-adjunct';
import {
    store,
    MEETING_ATTENDEE_EDGE,
    MEETING_TOP_EDGE,
    SUBPROJECT_MEETING_EDGE,
    TOP_RESPONSIBLE_EDGE,
    TOPS_TO_UPDATE_KEY,
} from './hooks.config.new';
import { forEachIndexed } from './util';

export const cloneAsTemplate = async ({
    stack, meeting, newMeetingId, subprojectId,
}) => {
    const topBags = await getMeetingTops(stack, meeting.id);
    const attendeeBags = await getMeetingAttendees(stack, meeting.id);
    const topsToUpdate = store.getVar(...stack)(TOPS_TO_UPDATE_KEY);

    const {
        add, inheritRights, assoc, getGraph,
    } = useDummyGraph();

    // copy meeting
    // const newMeetingBusinessId = `${meeting.businessId}-copy`;
    const newMeeting = R.compose(
        R.assoc('businessId', undefined),
        R.assoc('type', undefined),
        R.assoc('id', newMeetingId),
        R.assoc('closed', false),
        R.assoc('submitted', false),
        R.omit(['id', 'date']),
    )(meeting);

    // add meeting to subproject
    add(SUBPROJECT_MEETING_EDGE)(subprojectId)(newMeeting, { order: newMsDate() });
    // inherit rights from subproject
    inheritRights(newMeetingId)(subprojectId);

    // foreach top
    // const createBusinessId = (type, index) => ['1', '2', '3'].includes(type)
    //     ? undefined
    //     : [
    //         projectBusinessId,
    //         subprojectBusinessId,
    //         newMeetingBusinessId,
    //         R.toUpper(type),
    //         R.drop(2, (index / 1000).toString()),
    //     ].join('-');

    forEachIndexed(
        ({ node: top }) => {
            const newTopId = newId();
            // create new Top from top type
            const newTop = R.compose(
                _top => ({
                    ..._top, createdDate: newIsoDate(), id: newTopId,
                }),
                R.pick(['type']),
            )(top);
            // add top
            add(MEETING_TOP_EDGE)(newMeetingId)(newTop, { order: newMsDate() });
            // inherit rights from subproject
            inheritRights(newTopId)(subprojectId);
        },
    )(R.values(topBags));

    // foreach topToUpdate
    forEachIndexed(
        ({
            top, responsible,
        }) => {
            // copy topsToUpdate
            const newTopId = newId();
            const newTop = R.compose(
                R.assoc('modifiedDate', newIsoDate()),
                R.assoc('id', newTopId),
            )(top);
            // add topsToUpdate
            add(MEETING_TOP_EDGE)(newMeetingId)(newTop, { order: newMsDate() });
            // inherit rights from subproject
            inheritRights(newTopId)(subprojectId);
            // assoc responsibles
            responsible && assoc(TOP_RESPONSIBLE_EDGE)(newTopId)(responsible);
        },
    )(R.values(topsToUpdate));

    // foreach attendee
    R.forEach(
        ({ node: attendee }) => {
            // assoc attendees
            assoc(MEETING_ATTENDEE_EDGE)(newMeetingId)(attendee);
        },
    )(R.values(attendeeBags));

    return getGraph();
};

const getMeetingTops = async (stack, meetingId) => {
    const view = {
        [meetingId]: {
            edges: {
                [MEETING_TOP_EDGE]: {
                    as: 'tops',
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                },
            },
        },
    };

    await store.pull(view, R.head(stack));

    const {
        [meetingId]: {
            tops: topBags,
        },
    } = store.getView(...stack)(view);

    return topBags;
};

const getMeetingAttendees = async (stack, meetingId) => {
    const view = {
        [meetingId]: {
            edges: {
                [MEETING_ATTENDEE_EDGE]: {
                    as: 'attendees',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    };

    await store.pull(view, R.head(stack));

    const {
        [meetingId]: {
            attendees: attendeeBags,
        },
    } = store.getView(...stack)(view);

    return attendeeBags;
};
