import React from 'react';
import { useProjectChoices } from '../../../hooks/new/useChoices';
import { DropdownField, ResponsibleDropdownField } from '../../Common/Dropdown';

export const ChoiceDropdownField = ({
    stack,
    choiceType,
    label,
    value,
    onChange,
    editable,
}) => {
    const { choices } = useProjectChoices(stack, choiceType);
    return (
        <DropdownField
            label={label}
            value={value}
            editable={editable}
            options={choices}
            onChange={onChange}
        />
    );
};

export const ResponsibleChoiceDropdownField = ({
    stack,
    choiceType,
    label,
    value,
    onChange,
    editable,
}) => {
    const { choices } = useProjectChoices(stack, choiceType);
    return (
        <ResponsibleDropdownField
            stack={stack}
            label={label}
            value={value}
            editable={editable}
            options={choices}
            onChange={onChange}
        />
    );
};
