import { useGraph } from '../../common/hooks.config.new';

export const useAttachments = (stack, fromId, edgeTypes) => {
    const {
        push,
        pull,
        useView,
        inheritRights,
        add,
        remove,
        setFile,
    } = useGraph(...stack);
    const attachmentsView = {
        [fromId]: {
            edges: {
                [edgeTypes]: {
                    as: 'attachments',
                    include: {
                        node: true,
                        edges: true,
                        files: true,
                    },
                },
            },
        },
    };

    const {
        [fromId]: {
            attachments: attachmentBags = {},
        },
    } = useView(attachmentsView);

    return {
        attachmentBags,
        refresh: () => pull(attachmentsView),
        push,
        inheritRights: nodeId => inheritRights(nodeId)(fromId),
        add: add(edgeTypes)(fromId),
        remove: remove(edgeTypes)(fromId),
        setFile,
    };
};
