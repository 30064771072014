import React, { memo } from 'react';
import * as R from 'ramda';
import { _Top } from './Top';

const _ProtocolTop = ({
    stack,
    topId,
    editable = true,
    noButton,
    scorecard,
    noId,
}) => (
    // eslint-disable-next-line react/jsx-pascal-case
    <_Top
        stack={stack}
        topId={topId}
        editable={editable}
        noButton={noButton}
        scorecard={scorecard}
        noId={noId}
    />
);

export const ProtocolTop = memo(_ProtocolTop, R.equals);
