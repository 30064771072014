import React from 'react';
import * as R from 'ramda';
import { FaProjectDiagram, FaSave } from 'react-icons/fa';
import { useModalState } from '../../../hooks/new/useModal';
import { Button } from '../../Common/Controls';
import {
    ImageField, LargeTextField, TextField,
} from '../../Common/Fields';
import { DropdownField } from '../../Common/Dropdown';
import { Modal } from '../../Common/Modal';

export const NewProjectModal = ({
    save,
    update,
    setFile,
    project,
    canWriteProject,
    customerLogo,
    projectId,
}) => {
    const [isOpen, close] = useModalState('new-project');
    return (
        <Modal
            name="new-project"
            style={{
                height: '80vh',
                width: '40vw',
                top: '10vh',
                left: '30vw',
            }}
            topbar={(
                <>
                    <FaProjectDiagram size={23} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? (
                    <NewProjectModalContent
                        close={close}
                        save={save}
                        update={update}
                        setFile={setFile}
                        project={project}
                        canWriteProject={canWriteProject}
                        customerLogo={customerLogo}
                        projectId={projectId}
                    />
                )
                : null}
        </Modal>
    );
};

const NewProjectModalContent = ({
    save,
    update,
    setFile,
    close,
    project,
    canWriteProject,
    customerLogo,
    projectId,
}) => (
    <div className="columns">
        <div>
            <div className="opp-headline" style={{ marginBottom: 15 }}>
                New project
            </div>
            <TextField
                label="Project name"
                value={project.title || ''}
                onChange={value => update(R.assoc('title', value, project))}
                editable={canWriteProject}
            />
            <TextField
                label="Project ID"
                value={project.businessId || ''}
                onChange={value => update(R.assoc('businessId', value, project))}
                editable={canWriteProject}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 3 }}>
                    <TextField
                        label="Customer name"
                        value={project.customerName || ''}
                        onChange={value => update(R.assoc('customerName', value, project))}
                        editable={canWriteProject}
                    />
                </div>
                <div style={{ flex: 1, marginLeft: 20 }}>
                    <DropdownField
                        options={[
                            { value: 'EUR', label: 'Euro' },
                            { value: 'USD', label: 'US-Dollar' },
                            { value: 'CHF', label: 'Swiss Franc' },
                        ]}
                        onChange={value => {
                            update(R.assoc('currency', value.value, project));
                        }}
                        value={project.currency || ''}
                        label="Currency"
                    />
                </div>
            </div>
            <ImageField
                label="Customer logo"
                value={customerLogo || ''}
                onChange={file => {
                    setFile(projectId)('customerLogo', file.name, file);
                    save();
                }}
            />
            <div className="types-container">
                <div style={{ width: '47.5%' }}>
                    <LargeTextField
                        style={{ resize: 'none' }}
                        label="Meeting Types"
                        rows="6"
                        value={R.join('\n', project.meetingTypes || [])}
                        onChange={value => update(R.assoc('meetingTypes', R.split('\n', value), project))}
                        editable={canWriteProject}
                    />
                </div>
                <div style={{ width: '47.5%' }}>
                    <LargeTextField
                        style={{ resize: 'none' }}
                        label="Action Status Types"
                        rows="6"
                        value={R.join('\n', project.actionStatusTypes || [])}
                        onChange={value => update(R.assoc('actionStatusTypes', R.split('\n', value), project))}
                        editable={canWriteProject}
                    />
                </div>
            </div>
            <div className="types-container">
                <div style={{ width: '47.5%' }}>
                    <LargeTextField
                        style={{ resize: 'none' }}
                        label="Opportunity Status Types"
                        rows="6"
                        value={R.join('\n', project.oppStatusTypes || [])}
                        onChange={value => update(R.assoc('oppStatusTypes', R.split('\n', value), project))}
                        editable={canWriteProject}
                    />
                </div>
                <div style={{ width: '47.5%' }}>
                    <LargeTextField
                        style={{ resize: 'none' }}
                        label="Opportunity Category Types"
                        rows="6"
                        value={R.join('\n', project.oppCategoryTypes || [])}
                        onChange={value => update(R.assoc('oppCategoryTypes', R.split('\n', value), project))}
                        editable={canWriteProject}
                    />
                </div>
            </div>
            <div style={{
                display: 'flex', flex: 1, justifyContent: 'center',
            }}
            >
                <Button
                    onClick={() => {
                        save();
                        close();
                    }}
                    style={{ width: 200 }}
                >
                    <FaSave size={24} color="var(--corporate-color-1)" />
                    <p style={{ fontSize: 16, marginLeft: 12, fontWeight: 'bold' }}>Save</p>
                </Button>
            </div>
        </div>
    </div>
);
