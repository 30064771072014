import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { IoMdOpen } from 'react-icons/io';
import { newId, newIsoDate, newMsDate } from '@gothub-team/got';
import { Modal } from '../../Common/Modal';
import {
    ListField,
} from '../../Common/Fields';
import {
    EXISTING_MEETINGS_MODAL,
    MEETING_TOP_EDGE,
    PROTOCOL,
    store,
    SUBPROJECT_ID_KEY, SUBPROJECT_MEETING_EDGE, TOPS_TO_UPDATE_KEY, TOP_RESPONSIBLE_EDGE, useGraph,
} from '../../../common/hooks.config.new';
import { useModalState } from '../../../hooks/new/useModal';
import { useNavigator } from '../../../hooks/new/useNavigator';
import { createOfflinePush, useDummyGraph } from '../../../common/got-adjunct';
import { Button } from '../../Common/Controls';
import { useToast } from '../../../hooks/new/useToast';
import { Toast } from '../../Elements/new/Toast';

const constructMeetingsView = subprojectId => subprojectId
    ? {
        [subprojectId]: {
            as: 'subproject',
            edges: {
                [SUBPROJECT_MEETING_EDGE]: {
                    as: 'meetings',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

export function ExistingMeetingsModal({ stack }) {
    const [isOpen, close] = useModalState('existingMeetings');
    return (
        <Modal
            name="existingMeetings"
            style={{
                width: '90vw',
                left: '5vw',
                height: '87vh',
                top: '6.5vh',
            }}
            topbar={(
                <>
                    <IoMdOpen size={25} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? <ExistingMeetingsModalContent stack={stack} close={close} />
                : null}
        </Modal>
    );
}

const ExistingMeetingsModalContent = ({ stack: parentStack, close }) => {
    const stack = R.append(EXISTING_MEETINGS_MODAL, parentStack);
    const {
        useVar, useView, pull, update, dissoc,
    } = useGraph(...stack);

    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);

    const meetingsView = constructMeetingsView(subprojectId);
    useEffect(() => subprojectId && pull(meetingsView), [subprojectId]);
    const { subproject } = useView(meetingsView);
    const meetingChoices = R.compose(
        R.map(meetingBag => ({
            value: R.path(['node', 'id'], meetingBag),
            label: R.path(['node', 'title'], meetingBag),
            body: R.path(['node'], meetingBag),
        })),
        R.reverse,
        R.sortBy(R.path(['node', 'date'])),
        R.filter(meetingBag => !R.pathOr(false, ['node', 'closed'], meetingBag)),
        R.values,
        R.propOr({}, 'meetings'),
    )(subproject);

    const submittedMeetings = R.filter(mc => mc.body.submitted === true)(meetingChoices);
    const draftMeetings = R.filter(mc => !mc.body.submitted ? true : mc.body.submitted === false)(meetingChoices);

    const { navigateToProtocol } = useNavigator(parentStack);

    const addTopsToUpdate = meetingId => {
        const topsToUpdate = store.getVar(...stack)(TOPS_TO_UPDATE_KEY);

        const {
            add, inheritRights, assoc, getGraph,
        // eslint-disable-next-line react-hooks/rules-of-hooks
        } = useDummyGraph();

        R.forEach(
            ({
                top, responsible,
            }) => {
                const newTopId = newId();
                const newTop = R.compose(
                    R.assoc('modifiedDate', newIsoDate()),
                    R.assoc('id', newTopId),
                )(top);
                add(MEETING_TOP_EDGE)(meetingId)(newTop, { order: newMsDate() });
                inheritRights(newTopId)(subprojectId);
                responsible && assoc(TOP_RESPONSIBLE_EDGE)(newTopId)(responsible);
            },
        )(R.values(topsToUpdate));

        store.mergeGraph(getGraph(), PROTOCOL);
        navigateToProtocol(meetingId);
    };

    const setToast = useToast('existing-meetings');
    const toastConfig = {
        textOnStart: 'Saving meeting data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const _push = createOfflinePush(stack, setToast, toastConfig);

    const closeMeeting = meeting => {
        // eslint-disable-next-line no-restricted-globals
        const confirmed = confirm('Are you sure to freeze this meeting? The meeting will not be accessible via dashboard.');
        if (confirmed) {
            update(
                R.assoc('closed', true, meeting),
            );
            _push(); // = Push
        }
    };

    const deleteMeeting = meeting => {
        // eslint-disable-next-line no-restricted-globals
        const confirmed = confirm('Are you sure to delete this meeting?');
        if (confirmed) {
            dissoc(SUBPROJECT_MEETING_EDGE)(subprojectId)(meeting);
            _push(); // = Push
        }
    };

    const submitMeeting = meeting => {
        // eslint-disable-next-line no-restricted-globals
        const confirmed = confirm('Are you sure to submit this meeting?');
        if (confirmed) {
            update(R.assoc('submitted', true, meeting));
            _push(); // = Push
        }
    };

    const meetingSearch = (searchString, meetings) => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.filter(m => (m.body.title
                    && m.body.title.toUpperCase().search(string) !== -1))(meetings);
        }
        return [];
    };

    const [draftSearch, setDraftSearch] = useState('');
    const [submittedSearch, setSubmittedSearch] = useState('');

    return (
        <>
            <div className="columns">
                <div>
                    <div className="opp-headline">
                        Drafts
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <input
                            className="searchbar"
                            placeholder="search draft meetings..."
                            value={draftSearch}
                            onChange={e => setDraftSearch(e.target.value)}
                            style={{ margin: 12 }}
                        />
                    </div>
                    <ListField
                        className
                        style={{ height: '68vh' }}
                        label="Drafts"
                    >
                        {R.map(
                            meetingChoice => (
                                <div
                                    key={meetingChoice.body.id}
                                    className="row"
                                    style={{ cursor: 'default' }}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flex: 2 }}>
                                        <div>{meetingChoice.label}</div>
                                        <div style={{ color: 'var(--color-2)' }}>{meetingChoice.body.businessId}</div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                                        <Button
                                            onClick={() => {
                                                addTopsToUpdate(meetingChoice.body.id);
                                                close();
                                            }}
                                            style={{ color: 'var(--corporate-color-2)' }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            style={{ marginLeft: 12, color: 'var(--corporate-color-2)' }}
                                            onClick={() => submitMeeting(meetingChoice.body)}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            style={{ marginLeft: 12, color: 'var(--corporate-color-2)' }}
                                            onClick={() => deleteMeeting(meetingChoice.body)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </div>
                            ),
                        )((draftSearch.length > 0
                            ? meetingSearch(draftSearch, draftMeetings)
                            : draftMeetings))}
                    </ListField>
                </div>
                <div>
                    <div className="opp-headline">
                        Submitted
                    </div>
                    <div style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                    }}
                    >
                        <input
                            className="searchbar"
                            placeholder="search submitted meetings..."
                            value={submittedSearch}
                            onChange={e => setSubmittedSearch(e.target.value)}
                            style={{ margin: 12 }}
                        />
                    </div>
                    <ListField
                        className
                        style={{ height: '68vh' }}
                        label="Submitted"
                    >
                        {R.map(
                            meetingChoice => (
                                <div
                                    key={meetingChoice.body.id}
                                    className="row"
                                    style={{ cursor: 'default' }}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flex: 2 }}>
                                        <div>{meetingChoice.label}</div>
                                        <div style={{ color: 'var(--color-2)' }}>{meetingChoice.body.businessId}</div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                                        <Button
                                            onClick={() => {
                                                addTopsToUpdate(meetingChoice.body.id);
                                                close();
                                            }}
                                            style={{ color: 'var(--corporate-color-2)' }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            style={{ marginLeft: 24, color: 'var(--corporate-color-2)' }}
                                            onClick={() => closeMeeting(meetingChoice.body)}
                                        >
                                            Freeze
                                        </Button>
                                    </div>
                                </div>
                            ),
                        )(submittedSearch.length > 0
                            ? meetingSearch(submittedSearch, submittedMeetings)
                            : submittedMeetings)}
                    </ListField>
                </div>
            </div>
            <Toast name="existing-meetings" />
        </>
    );
};
