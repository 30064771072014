/* eslint-disable react-hooks/rules-of-hooks */
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import {
    Core, Util, gotReducer, createStore, createHooks,
} from '@gothub-team/got-react';
import { OFFLINE, store } from './hooks.config.new';
/**
 * Constructs a view for a single node based on a nodeId.
 * Includes the node by default.
 */
export const constructNodeView = (nodeId, include = { node: true }) => ({
    [nodeId]: {
        include,
    },
});

/**
 * Constructs a view for a single edge based on a nodeId and edgeTypes.
 * Includes the node for the parent node as well as nodes, edges and metadata for the edge by default.
 */
export const constructEdgeView = (
    nodeId,
    edgeTypes,
    nodeInclude = {
        node: true,
    },
    edgeInclude = {
        node: true,
        edges: true,
        metadata: true,
    },
) => ({
    [nodeId]: {
        include: nodeInclude,
        edges: {
            [edgeTypes]: {
                include: edgeInclude,
            },
        },
    },
});

/**
 * Intakes a Hashmap or Array of nodeBags and returns a sorted array by their metadatas order prop
 */
export const sortBagsByOrder = R.compose(
    R.sortBy(
        R.compose(
            Number,
            R.pathOr(Number.MAX_SAFE_INTEGER, ['metadata', 'order']),
        ),
    ),
    R.defaultTo([]),
    R.when(
        R.is(Object),
        R.values,
    ),
);

export const createSelectors = baseState => ({
    selectMetadata: (stack, edgeTypes, fromId, toId) => state => {
        const [fromType, toType] = R.split('/', edgeTypes);
        return Core.selectPathFromStack(
            ['graph', 'edges', fromType, fromId, toType, toId],
            stack,
            Util.mergeLeft,
            R.propOr({}, baseState, state),
        );
    },
});

export const setOrder = edgeTypes => fromId => {
    const [fromType, toType] = R.split('/', edgeTypes);
    return R.compose(
        R.pathOr({}, ['temp', 'graph']),
        RA.reduceIndexed(
            (state, toId, order) => gotReducer(state, {
                type: 'GOT/ASSOC',
                payload: {
                    graphName: 'temp',
                    fromId,
                    fromType,
                    toType,
                    toNode: { id: toId },
                    metadata: { order },
                },
            }),
            {},
        ),
    );
};

export const getOrderAfter = currentIndex => list => {
    const thisOrder = R.compose(
        Number,
        R.path([currentIndex, 'metadata', 'order']),
    )(list);
    const nextOrder = R.compose(
        Number,
        R.pathOr(Date.now(), [currentIndex + 1, 'metadata', 'order']),
    )(list);
    return (thisOrder + nextOrder) / 2;
};

export const setNodes = R.compose(
    R.pathOr({}, ['temp', 'graph']),
    R.reduce(
        (state, node) => (node && node.id)
            ? gotReducer(state, {
                type: 'GOT/SET_NODE',
                payload: {
                    graphName: 'temp',
                    node,
                },
            })
            : state,
        {},
    ),
);

export const useDummyState = (initialState = {}) => {
    let state = R.clone(initialState);

    const getState = () => state;

    const select = selector => selector(state);

    const dispatch = action => {
        try {
            state = gotReducer(state, action);
        } catch (error) {
            console.error(error);
        }
    };

    const dummyStore = createStore({
        api: {},
        dispatch,
        select,
        onError: console.error,
        onWarn: () => {},
    });

    return {
        store: dummyStore,
        select,
        dispatch,
        getState,
    };
};

export const useDummyGraph = (initialState = {}) => {
    const { store: dummyStore, getState } = useDummyState(initialState);
    const { useGraph } = createHooks({ store: dummyStore });
    const getGraph = () => R.pathOr({}, ['temp', 'graph'], getState());
    return { ...useGraph('temp-main', 'temp'), getGraph };
};

export const rightsOverView = (...stack) => view => (email, rights) => (initialState = {}) => {
    const { setRights, getGraph } = useDummyGraph(initialState);
    const stackGetEdgeToIds = (fromType, fromId, toType) => store.getEdge(...stack)(`${fromType}/${toType}`)(fromId);
    Core.doViewGraph({
        nodes: (queryObj, nodeId) => {
            setRights(nodeId)(email, rights);
        },
    }, view, stackGetEdgeToIds);

    return getGraph();
};

// export const selectIdsWithAlias = (...stack) => view => type => {
//     const [getResult, setResult] = Util.useResult({});
//     const stackGetEdgeToIds = (fromType, fromId, toType) => store.getEdge(...stack)(`${fromType}/${toType}`)(fromId);
//     Core.doViewGraph({
//         nodes: (queryObj, nodeId, edgePath) => {
//             if (edgePath[2] === type) {
//                 setResult(R.assoc(nodeId, nodeId));
//             }
//         },
//     }, view, stackGetEdgeToIds);

//     return getResult();
// };

export const createOfflinePush = (stack, setToast, {
    textOnStart, textOnSuccess, textOnError, mergeToOfflineName = OFFLINE,
}) => async () => {
    const currentGraphName = R.nth(-1, stack);
    const bottomGraphName = R.head(stack);

    setToast && textOnStart && setToast('spinner', textOnStart);
    return store.push(currentGraphName, bottomGraphName)
        .then(res => {
            setToast && textOnSuccess && setToast('success', textOnSuccess, 5000);
            return res;
        })
        .catch(err => {
            // no internet connection
            setToast && setToast('error', textOnError || err.toString());
            store.merge(currentGraphName, mergeToOfflineName);
            store.clear(currentGraphName);
            throw err;
        });
};
