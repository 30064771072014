import React, { memo, useState } from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import * as R from 'ramda';
import '../ChartsArea.css';
import {
    PROJECT_ACTION_STATUS_TYPE_CHOICES,
    PROJECT_OPP_CATEGORY_TYPE_CHOICES,
    PROJECT_OPP_STATUS_TYPE_CHOICES,
    useProjectChoices,
    useProjectCurrency,
} from '../../../hooks/new/useChoices';

const colors = ['#E3D0A1', '#64A6C1', '#A5668B', '#FFD166', '#003459', '#96BACE', '#0ea700e1', '#007EA7', '#70163C',
    '#E3D0A1CC', '#64A6C1CC', '#A5668BCC', '#FFD166CC', '#003459CC', '#96BACECC', '#0ea700e1CC', '#007EA7CC', '#70163CCC',
    '#E3D0A166', '#64A6C166', '#A5668B66', '#FFD16666', '#00345966', '#96BACE66', '#007EA766', '#70163C66'];

const donutOptions = {
    aspectRatio: 1,
    offset: 2,
    responsive: false,
    cutout: '80%',
    radius: '80%',
    animation: false,
    plugins: {
        legend: {
            display: false,
        },
    },
};

export const OppsCountDonut = ({
    chartedOpps,
    setChartOrKeywordTops,
    criticalOppsCount,
    donutHeight = 300,
    donutWidth = 300,
    containerWidth,
    fontBig,
    fontSmall,
    topMargin,
    global,
}) => {
    const oppsCount = R.compose(
        R.sum,
        R.map(
            R.compose(
                R.length,
                R.prop('tops'),
            ),
        ),
    )(chartedOpps);

    const globalCount = oppsCount - criticalOppsCount;

    const data = {
        labels: chartedOpps.map(ct => ct.type),
        datasets: [
            {
                label: 'Reference',
                data: chartedOpps.map(ct => ct.tops.length),
                backgroundColor: global ? ['#005980', '#FF3A20'] : colors,
                borderRadius: global ? 10 : 5,
            },
        ],
    };

    return (
        <>
            <div className="doughnut-container" style={{ width: containerWidth }}>
                <Doughnut
                    data={data}
                    options={donutOptions}
                    getElementAtEvent={a => a.length > 0 && setChartOrKeywordTops(chartedOpps[a[0].index].tops)}
                    height={donutHeight}
                    width={donutWidth}
                />
                <div className="inner-text">
                    <p className="title" style={{ fontSize: fontBig }}>Opportunities:</p>
                    <p className="info" style={{ fontSize: fontBig, marginTop: topMargin }}>{global ? globalCount : oppsCount}</p>
                    <p className="title-critical" style={{ color: criticalOppsCount === 0 && 'var(--color-2)', fontSize: fontSmall, marginTop: topMargin && topMargin + 20 }}>Critical:</p>
                    <p className="info-critical" style={{ color: criticalOppsCount === 0 && 'var(--color-2)', fontSize: fontSmall, marginTop: topMargin }}>{criticalOppsCount}</p>
                </div>
            </div>
        </>
    );
};

const OppLegend = ({ oppStatusTypeChoices }) => {
    const legend = oppStatusTypeChoices.map((o, i) => {
        const el = { label: o.label, color: colors[i] };
        return el;
    });
    return (
        <div>
            <div className="legend-item">
                <div className="legend-title">
                    Status
                </div>
            </div>
            {legend.map(item => (
                <div className="legend-item" key={item.label}>
                    <div className="legend-color" style={{ backgroundColor: item.color }} />
                    <div className="legend-label">{item.label}</div>
                </div>
            ))}
        </div>
    );
};

const OppsSavingsDonut = ({
    stack, chartedOpps, setChartOrKeywordTops, criticalOpps,
}) => {
    const currency = useProjectCurrency(stack);
    const formato = val => new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency,
    }).format(val);

    const chartedSavings = R.map(
        R.compose(
            R.sum,
            R.map(R.pathOr(0, ['top', 'savings'])),
            R.prop('tops'),
        ),
    )(chartedOpps);
    const totalSavingsSum = R.sum(chartedSavings);
    const criticalSavings = R.sum(R.map(co => co.top.savings)(criticalOpps));

    const data = {
        labels: chartedOpps.map(ct => ct.type),
        datasets: [
            {
                label: 'Reference',
                data: chartedSavings,
                backgroundColor: colors,
                borderRadius: 5,
            },
        ],
    };

    return (
        <>
            <div className="doughnut-container">
                <Doughnut
                    data={data}
                    options={donutOptions}
                    getElementAtEvent={a => a.length > 0 && setChartOrKeywordTops(chartedOpps[a[0].index].tops)}
                    height={300}
                    width={300}
                />
                <div className="inner-text">
                    <p className="title">Savings:</p>
                    <p className="info">{formato(totalSavingsSum)}</p>
                    <p className="title-critical" style={{ color: criticalSavings === 0 && 'var(--color-2)' }}>Critical:</p>
                    <p className="info-critical" style={{ color: criticalSavings === 0 && 'var(--color-2)' }}>{formato(criticalSavings)}</p>
                </div>
            </div>
        </>
    );
};

const OppsTable = ({
    stack, status, opps, criticalOpps, chartedOpps, setChartOrKeywordTops,
}) => {
    const cleanOpps = opps.filter(o => o.top.category ? o : null);

    const { choices: oppCategoryTypeChoices } = useProjectChoices(stack, PROJECT_OPP_CATEGORY_TYPE_CHOICES);
    const oppCategories = R.groupBy(R.path(['top', 'category']), opps);
    const preCategorizedOpps = oppCategoryTypeChoices.map(categoryType => ({ type: categoryType.label, tops: oppCategories[categoryType.label] || [] }));
    const categorizedOpps = R.append({ type: 'Critical', tops: criticalOpps })(preCategorizedOpps);

    const categorizedSavings = R.map(category => {
        const savings = R.compose(
            R.sum,
            R.map(R.pathOr(0, ['top', 'savings'])),
            R.prop('tops'),
        )(category);
        return { ...category, savings };
    })(categorizedOpps);

    const { choices: oppStatusTypeChoices } = useProjectChoices(stack, PROJECT_OPP_STATUS_TYPE_CHOICES);

    const preSortedCategorizedOpps = R.map(
        categorySavings => {
            const _oppStatus = R.groupBy(R.path(['top', 'status']), R.propOr([], 'tops', categorySavings));
            const _chartedOpps = oppStatusTypeChoices.map(statusType => ({ type: statusType.label, tops: _oppStatus[statusType.label] || [] }));
            return { ...categorySavings, sortedTops: _chartedOpps };
        },
    )(categorizedSavings);

    const sortedCategorizedOpps = R.init(preSortedCategorizedOpps);
    const sortedCriticalOpps = R.last(preSortedCategorizedOpps);

    const totalSavingsSum = R.compose(
        R.sum,
        R.map(R.propOr(0, 'savings')),
    )(categorizedSavings);

    const currency = useProjectCurrency(stack);
    const formato = val => new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency,
    }).format(val);

    const [highlight, setHighlight] = useState({});
    console.log(highlight);

    return (
        <>
            <div className="table-container">
                <div className="table-header">
                    <div className="header-entry-left">
                        {status ? 'Status' : 'Category'}
                    </div>
                    <div
                        className="header-entry-middle"
                        style={{ fontWeight: highlight && highlight.columnIndex === -1 && 900 }}
                    >
                        Opportunities
                    </div>
                    {oppStatusTypeChoices.map((stc, i) => (
                        <div
                            className="header-entry-middle"
                            key={stc.label}
                            style={{ fontWeight: highlight && highlight.columnIndex === i && 900 }}
                        >
                            {stc.label}
                        </div>
                    ))}
                    <div className="header-entry-right">
                        Savings
                    </div>
                </div>
                {sortedCategorizedOpps.map((c, i) => (
                    <div className="table-row" key={c.type}>
                        <div
                            className="table-entry-left"
                            style={{
                                color: c.type === 'Critical' && 'var(--color-error)',
                                fontWeight: highlight && highlight.rowIndex === i && 900,
                            }}
                        >
                            {c.type}
                        </div>
                        <div
                            className="table-entry-middle"
                            style={{
                                color: c.tops.length === 0 && 'var(--color-2)',
                                cursor: c.tops.length === 0 && 'default',
                                backgroundColor: c.tops.length === 0 && 'transparent',
                                fontWeight: c.tops.length === 0 && 'normal',
                            }}
                            onMouseEnter={() => c.tops.length > 0 && setHighlight({ rowIndex: i, columnIndex: -1 })}
                            onMouseLeave={() => c.tops.length > 0 && setHighlight()}
                            onClick={() => c.tops.length > 0 && setChartOrKeywordTops(c.tops)}
                        >
                            {c.tops.length}
                        </div>
                        {c.sortedTops.map((cc, index) => (
                            <div
                                onMouseEnter={() => cc.tops.length > 0 && setHighlight({ rowIndex: i, columnIndex: index })}
                                onMouseLeave={() => cc.tops.length > 0 && setHighlight()}
                                className="table-entry-middle"
                                key={cc.type}
                                style={{
                                    color: cc.tops.length === 0 && 'var(--color-2)',
                                    cursor: cc.tops.length === 0 && 'default',
                                    backgroundColor: cc.tops.length === 0 && 'transparent',
                                    fontWeight: cc.tops.length === 0 && 'normal',
                                }}
                                onClick={() => cc.tops.length > 0 && setChartOrKeywordTops(cc.tops)}
                            >
                                {cc.tops.length}
                            </div>
                        ))}
                        <div className="table-entry-right" style={{ color: c.savings === 0 && 'var(--color-2)' }}>
                            {formato(c.savings)}
                        </div>
                    </div>
                ))}
                <div className="table-footer">
                    <div className="header-entry-left" style={{ color: 'var(--corporate-color-11)' }}>Total</div>
                    <div
                        className="footer-entry-middle"
                        style={{
                            color: R.length(opps) === 0 && 'var(--color-2)',
                            cursor: R.length(opps) === 0 && 'default',
                            backgroundColor: R.length(opps) === 0 && 'transparent',
                            fontWeight: R.length(opps) === 0 && 'normal',
                        }}
                        onClick={() => R.length(opps) > 0 && setChartOrKeywordTops(cleanOpps)}
                        onMouseEnter={() => setHighlight({ columnIndex: -1 })}
                        onMouseLeave={() => setHighlight()}
                    >
                        {R.length(cleanOpps)}
                    </div>
                    {chartedOpps.map((stt, i) => (
                        <div
                            className="table-entry-middle"
                            key={stt.type}
                            style={{
                                color: stt.tops.length === 0 && 'var(--color-2)',
                                cursor: stt.tops.length === 0 && 'default',
                                backgroundColor: stt.tops.length === 0 && 'transparent',
                                fontWeight: stt.tops.length === 0 && 'normal',
                            }}
                            onMouseEnter={() => stt.tops.length > 0 && setHighlight({ columnIndex: i })}
                            onMouseLeave={() => stt.tops.length > 0 && setHighlight()}
                            onClick={() => stt.tops.length > 0 && setChartOrKeywordTops(stt.tops)}
                        >
                            {stt.tops.length}
                        </div>
                    ))}
                    <div className="footer-entry-right">
                        {formato(totalSavingsSum)}
                    </div>
                </div>
            </div>

            <div className="table-container" style={{ marginTop: 6, marginBottom: 30 }}>
                <div className="table-footer-critical">
                    <div
                        className="header-entry-left"
                        style={{ color: R.length(sortedCriticalOpps.tops) === 0 ? 'var(--color-2)' : 'var(--corporate-color-12' }}
                    >
                        Critical

                    </div>
                    <div
                        className="footer-entry-middle"
                        style={{
                            color: R.length(sortedCriticalOpps.tops) === 0 ? 'var(--color-2)' : 'var(--corporate-color-12',
                            cursor: R.length(sortedCriticalOpps.tops) === 0 && 'default',
                            backgroundColor: R.length(sortedCriticalOpps.tops) === 0 && 'transparent',
                            fontWeight: R.length(sortedCriticalOpps.tops) === 0 && 'normal',
                        }}
                        onClick={() => R.length(sortedCriticalOpps.tops) > 0 && setChartOrKeywordTops(sortedCriticalOpps.tops)}
                        onMouseEnter={() => R.length(sortedCriticalOpps.tops) > 0 && setHighlight({ columnIndex: -1 })}
                        onMouseLeave={() => R.length(sortedCriticalOpps.tops) > 0 && setHighlight()}
                    >
                        {R.length(sortedCriticalOpps.tops)}
                    </div>
                    {sortedCriticalOpps.sortedTops.map((stt, i) => (
                        <div
                            className="table-entry-middle"
                            key={stt.type}
                            style={{
                                color: stt.tops.length === 0 ? 'var(--color-2)' : 'var(--corporate-color-12',
                                cursor: stt.tops.length === 0 && 'default',
                                backgroundColor: stt.tops.length === 0 && 'transparent',
                                fontWeight: stt.tops.length === 0 && 'normal',
                            }}
                            onMouseEnter={() => stt.tops.length > 0 && setHighlight({ columnIndex: i })}
                            onMouseLeave={() => stt.tops.length > 0 && setHighlight()}
                            onClick={() => stt.tops.length > 0 && setChartOrKeywordTops(stt.tops)}
                        >
                            {stt.tops.length}
                        </div>
                    ))}
                    <div
                        className="footer-entry-right"
                        style={{
                            color: sortedCriticalOpps.savings === 0 ? 'var(--color-2)' : 'var(--corporate-color-12',
                        }}
                    >
                        {formato(sortedCriticalOpps.savings)}
                    </div>
                </div>

            </div>
        </>
    );
};

const _Opportunities = ({
    stack, opps, criticalOpps, setChartOrKeywordTops, dashboardState,
}) => {
    const { choices: oppStatusTypeChoices } = useProjectChoices(stack, PROJECT_OPP_STATUS_TYPE_CHOICES);
    const oppStatus = R.groupBy(R.path(['top', 'status']), opps);
    const chartedOpps = oppStatusTypeChoices.map(statusType => ({ type: statusType.label, tops: oppStatus[statusType.label] || [] }));

    return (
        <div style={{
            borderRadius: 30, marginLeft: 24, marginRight: 24, marginTop: 18,
        }}
        >
            <div className="headline" style={{ border: 'none' }}>
                {dashboardState === 'project' ? 'Project' : dashboardState === 'subproject' ? 'Subproject' : 'Your'}
                {' '}
                Opportunities
            </div>
            <div className="layout">
                <div className="opp-charts">
                    <div className="chart-legend">
                        <OppsCountDonut
                            stack={stack}
                            chartedOpps={chartedOpps}
                            criticalOppsCount={R.length(criticalOpps)}
                            setChartOrKeywordTops={setChartOrKeywordTops}
                        />
                        <OppLegend
                            oppStatusTypeChoices={oppStatusTypeChoices}
                        />
                        <OppsSavingsDonut
                            stack={stack}
                            chartedOpps={chartedOpps}
                            criticalOpps={criticalOpps}
                            setChartOrKeywordTops={setChartOrKeywordTops}
                        />
                    </div>
                </div>
                <div className="tables">
                    <OppsTable
                        stack={stack}
                        opps={opps}
                        criticalOpps={criticalOpps}
                        chartedOpps={chartedOpps}
                        setChartOrKeywordTops={setChartOrKeywordTops}
                    />
                </div>
            </div>
        </div>
    );
};
const Opportunities = memo(_Opportunities, R.equals);

const actionsChartOptions = {
    animation: false,
    indexAxis: 'y',
    barThickness: 22,
    responsive: true,
    scales: {
        x: {
            grid: {
                drawBorder: false,
            },
            ticks: {
                stepSize: 1,
            },
        },
        y: {
            grid: {
                display: false,
                drawBorder: false,
            },
        },
    },
    plugins: {
        indexAxis: {
            stepSize: 1,
        },
        legend: {
            display: false,
        },
    },
};

export const ActionsChart = ({
    stack, actions, fnSelectNode = R.prop('node'), setChartOrKeywordTops,
}) => {
    const { choices: actionsStatusTypeChoices } = useProjectChoices(stack, PROJECT_ACTION_STATUS_TYPE_CHOICES);
    const actionStatus = stat => actions.length > 0 ? actions.filter(a => fnSelectNode(a).status === stat) : [];
    const chartedTops = R.append({ type: 'total', tops: actions }, actionsStatusTypeChoices.map(ast => {
        const types = { type: ast.label, tops: actionStatus(ast.label) };
        return types;
    }));

    const data = {
        labels: chartedTops.map(ct => ct.type),
        datasets: [
            {
                label: 'Number of actions',
                data: chartedTops.map(ct => ct.tops.length),
                backgroundColor: colors,
                borderRadius: 50,
            },
        ],
    };

    return (
        <>
            <Bar
                data={data}
                options={actionsChartOptions}
                getElementAtEvent={a => a.length > 0 && setChartOrKeywordTops(chartedTops[a[0].index].tops)}
            />
        </>
    );
};

const _ChartsArea = ({
    stack,
    opps,
    criticalOpps,
    actions,
    setChartOrKeywordTops,
    dashboardState,
    fnSelectNode,
}) => (
    <div className="charts-area">
        <Opportunities
            stack={stack}
            opps={opps}
            criticalOpps={criticalOpps}
            setChartOrKeywordTops={setChartOrKeywordTops}
            dashboardState={dashboardState}
        />
        <div
            style={{
                borderRadius: 30,
                marginLeft: 24,
                marginRight: 24,
                marginTop: 50,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 'none',
            }}
        >
            <div className="headline" style={{ border: 'none' }}>
                {dashboardState === 'project' ? 'Project' : dashboardState === 'subproject' ? 'Subproject' : 'Your'}
                {' '}
                Actions
            </div>
            <div className="actions-chart">
                <div className="inner">
                    <p className="actions-headline" style={{ fontWeight: 'normal' }}>Actions by status</p>
                    <ActionsChart
                        stack={stack}
                        actions={actions}
                        setChartOrKeywordTops={setChartOrKeywordTops}
                        fnSelectNode={fnSelectNode}
                    />
                </div>
            </div>
        </div>
    </div>
);
export const ChartsArea = memo(_ChartsArea, R.equals);
