import React from 'react';
import { DASHBOARD_STATE_KEY, SEARCH_STRING_KEY, useGraph } from '../../../common/hooks.config.new';

export const SearchBar = ({
    stack,
}) => {
    const { useVar } = useGraph(...stack);
    const [dashboardState] = useVar(DASHBOARD_STATE_KEY);
    const [searchString, setSearchString] = useVar(SEARCH_STRING_KEY);

    return (
        <input
            className="searchbar"
            value={searchString || ''}
            onChange={e => setSearchString(e.target.value)}
            placeholder={dashboardState === 'user'
                ? 'search your agenda items...'
                : dashboardState === 'project'
                    ? 'search project agenda items...'
                    : 'search subproject agenda items...'}
        />
    );
};
