import * as R from 'ramda';
// import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FiMail } from 'react-icons/fi';
import { RiSendPlaneFill } from 'react-icons/ri';
import axios from 'axios';
import { useModalState } from '../../../hooks/new/useModal';
import { Modal } from '../../Common/Modal';
import { LargeTextField, TextField } from '../../Common/Fields';
import '../PrintModal.css';
import { usePrintProtocol } from '../../../hooks/new/usePrintProtocol';
import { useMeetingAttendees } from '../../../hooks/new/useMeetingAttendees';
import { MEETING_ATTACHMENT_EDGE, store, useGraph } from '../../../common/hooks.config.new';
import { useNode } from '../../../hooks/new/useNode';
import { BACKEND_API_DOMAIN, defaultEmailBody } from '../../../common/config';
import { formatShortDate } from '../../../common/util';
import { AuthStore } from '../../../common/hooks.config';
import { Button } from '../../Common/Controls';
import { useToast } from '../../../hooks/new/useToast';
import { Toast } from '../../Elements/new/Toast';

export const PrintModal = ({
    stack,
}) => {
    const [isOpen, close] = useModalState('print');
    return (
        <Modal
            name="print"
            style={{
                height: '80vh',
                top: '10vh',
                width: '80vw',
                left: '10vw',
            }}
            topbar={(
                <>
                    <FiMail
                        size={24}
                        color="var(--corporate-color-7)"
                    />
                </>
            )}
        >
            {isOpen
                ? (
                    <PrintModalContent
                        stack={stack}
                        isOpen={isOpen}
                        close={close}
                    />
                )
                : null}
        </Modal>
    );
};

const PrintModalContent = ({ stack, isOpen, close }) => {
    const [protocolUrl, generateProtocol] = usePrintProtocol(stack);
    useEffect(() => isOpen && generateProtocol(), [isOpen]);

    const { useVar, update } = useGraph(...stack);

    const [projectId] = useVar('projectId');
    const [meetingId] = useVar('meetingId');

    const { node: project } = useNode(stack, projectId);
    const { node: meeting } = useNode(stack, meetingId);

    const attendeeBags = useMeetingAttendees(stack, meetingId);
    const [fields, setFields] = useState({});
    useEffect(() => {
        setFields({
            subject: `[${project.title}] ${meeting.title} (${R.compose(
                formatShortDate,
                R.prop('date'),
            )(meeting)})`,
            body: defaultEmailBody(meeting.title, R.compose(
                formatShortDate,
                R.prop('date'),
            )(meeting), sender.name),
        });
    }, [isOpen]);

    const sender = R.compose(
        R.pick(['name', 'email']),
        R.propOr({}, 'node'),
        R.find(R.pathEq(['metadata', 'presence'], 'M')),
        R.values,
    )(attendeeBags);

    const setPrintToast = useToast('print');

    const sendProtocol = async () => {
        const authHeader = await AuthStore.authHeader();
        const attachmentsView = {
            [meetingId]: {
                edges: {
                    [MEETING_ATTACHMENT_EDGE]: {
                        as: 'attachments',
                        include: {
                            node: true,
                            edges: true,
                            files: true,
                        },
                    },
                },
            },
        };

        await store.pull(attachmentsView, R.head(stack));
        const {
            [meetingId]: {
                attachments: attachmentBags = {},
            },
        } = store.getView(...stack)(attachmentsView);

        const attachmentUrls = R.compose(
            R.filter(obj => obj.filename && obj.url),
            R.values,
            R.map(
                attachmentBag => ({ filename: R.path(['node', 'filename'], attachmentBag), url: R.path(['files', 'file', 'url'], attachmentBag) }),
            ),
        )(attachmentBags);

        const recipients = R.compose(
            R.values,
            R.map(
                R.compose(
                    R.pick(['name', 'email']),
                    R.prop('node'),
                ),
            ),
        )(attendeeBags);

        if (sender.name && recipients.length > 0 && protocolUrl) {
            setPrintToast('spinner', 'Sending protocol...');
            await axios.post(
                `https://${BACKEND_API_DOMAIN}/send-protocol`,
                // 'http://localhost:4000/dev/send-protocol',
                {
                    sender,
                    recipients,
                    protocolUrl,
                    attachmentUrls,
                    ...fields,
                },
                authHeader,
            )
                .then(() => {
                    setPrintToast('success', 'Protocol successfully sent.', 5000);
                    update(R.assoc('submitted', true, meeting));
                    close();
                })
                .catch(error => setPrintToast('error', error.toString()));
        } else {
            setPrintToast('error', 'Some parts of your mail are missing. Make sure you chose a minutes keeper, meeting attendees and the protocol was generated.', 10000);
        }
    };
    return (
        <>
            <div className="columns">
                <div>
                    <TextField
                        label="Subject"
                        value={fields.subject}
                        onChange={value => setFields(R.assoc('subject', value))}
                    />
                    <LargeTextField
                        label="Body"
                        rows={18}
                        value={fields.body}
                        onChange={value => setFields(R.assoc('body', value))}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            onClick={() => sendProtocol()}
                            style={{ width: '30%' }}
                        >
                            <div className="button-icon">
                                <RiSendPlaneFill size={25} />
                                <p style={{ marginLeft: 12, fontSize: 17 }}>Send</p>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="pdf-viewer">
                    {protocolUrl && (
                    <object
                        style={{ width: '100%', height: '100%' }}
                        data={`${protocolUrl}&toolbar=0&navpanes=0&scrollbar=0`}
                        type="application/pdf"
                    >
                        <embed src={`${protocolUrl}&toolbar=0&navpanes=0&scrollbar=0`} type="application/pdf" />
                    </object>
                    )}
                </div>
            </div>
            <Toast name="print" />
        </>
    );
};
