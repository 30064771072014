import * as R from 'ramda';
import React, { memo } from 'react';
import { TextField } from '../Common/Fields';
import { DropdownField } from '../Common/Dropdown';

const _Attendee = ({
    attendee,
    metadata,
    assocAttendee,
}) => (
    <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        alignItems: 'center',
    }}
    >
        <div style={{ width: '50%', marginLeft: 12, color: 'var(--corporate-color-1)' }}>
            {`${attendee.name}, ${attendee.email}${attendee.department ? `, ${attendee.department}` : ''}`}
        </div>
        <>
            <TextField
                label="Comment"
                value={R.propOr('', 'comment', metadata)}
                onChange={value => assocAttendee(
                    attendee,
                    R.assoc('comment', value, metadata),
                )}
            />
            <DropdownField
                label="Presence"
                value={R.propOr('', 'presence', metadata)}
                options={[
                    { value: 'A', label: 'Attended' },
                    { value: 'P', label: 'Partly Attended' },
                    { value: 'N', label: 'Not Attended' },
                    { value: 'M', label: 'Minutes Keeper' },
                ]}
                onChange={option => assocAttendee(
                    attendee,
                    R.assoc('presence', option.value, metadata),
                )}
            />
        </>
    </div>
);

export const Attendee = memo(_Attendee, R.equals);
