import React, { useEffect } from 'react';
import { FiMail } from 'react-icons/fi';
import { SiMarketo } from 'react-icons/si';
import { Button } from '../../../Common/Controls';
import '../LandingPage.css';
import logo from '../../../../logos/pclogo.png';
import welcomeSolutions from '../Imgs/solutions.png';

const SolutionsPage = ({ setPage }) => {
    useEffect(() => [window.scrollTo(0, 0)], []);
    const solutionsImage = (
        <div>
            <img
                style={{ width: '100%' }}
                alt="welcomeSolutions"
                src={welcomeSolutions}
            />
            <div>
                <p className="image-text" style={{ color: 'var(--corporate-color-7)' }}>
                    Sofort starten, jederzeit kündbar.
                </p>
            </div>
            <img
                alt="logo"
                src={logo}
                className="image-logo"
            />
        </div>
    );
    const solutionsOverview = (
        <div className="content-box">
            <SiMarketo size={100} color="var(--corporate-color-4)" />
            <p className="content-box-headline" style={{ marginTop: 24 }}>
                Wählen Sie ihre Option und starten Sie noch heute
            </p>
            <div className="box-row">
                <div className="row-column-solution" style={{ boxShadow: '0px 0px 25px 0px var(--shadow-color-normal)' }}>
                    <img
                        alt="logoSolution"
                        src={logo}
                        className="solution-logo"
                    />
                    <p className="solution">Start</p>
                    <p className="solution">69,95 €</p>
                    <p className="claim">
                        pro User pro Monat
                        <sup>1</sup>
                    </p>
                    <p className="top-margin p-lp">ab 1 User </p>
                    <p className="top-margin p-lp" style={{ textAlign: 'center' }}>Vollzugriff auf alle Funktionen online als SaaS mit stets aktuellster Version</p>
                    <Button
                        onClick={() => setPage('contact')}
                        className="page-button"
                    >
                        Kontakt
                    </Button>
                </div>
                <div className="row-column-solution" style={{ boxShadow: '0px 0px 25px 0px var(--shadow-color-normal)' }}>
                    <img
                        alt="logoSolution"
                        src={logo}
                        className="solution-logo"
                    />
                    <p className="solution">Small Business</p>
                    <p className="solution">49,95 €</p>
                    <p className="claim">
                        pro User pro Monat
                        <sup>1</sup>
                    </p>
                    <p className="top-margin p-lp">ab 20 User</p>
                    <p className="top-margin p-lp" style={{ textAlign: 'center' }}>Vollzugriff auf alle Funktionen online als SaaS mit stets aktuellster Version</p>
                    <Button
                        onClick={() => setPage('contact')}
                        className="page-button"
                    >
                        Kontakt
                    </Button>
                </div>
                <div className="row-column-solution" style={{ justifyContent: 'space-between', boxShadow: '0px 0px 25px 0px var(--shadow-color-normal)' }}>
                    <div>
                        <img
                            alt="logoSolution"
                            src={logo}
                            className="solution-logo"
                        />
                        <p className="solution">Enterprise</p>
                        <p className="solution">Preis auf Anfrage</p>
                        <p className="top-margin p-lp" style={{ textAlign: 'center' }}>mehr als 50 User</p>
                        <p className="top-margin p-lp" style={{ textAlign: 'center' }}>Vollzugriff auf alle Funktionen online oder on-premise</p>
                    </div>
                    <Button
                        onClick={() => setPage('contact')}
                        className="page-button"
                    >
                        Kontakt
                    </Button>
                </div>
            </div>
            <p className="content-box-subheadline" style={{ width: '90%' }}>
                Kostenlose Benutzer-Schulung (live, aufgezeichnet und Dokumentation)
                Kostenlose System-Administration und Software-Updates
            </p>
            <div className="row-column-solution" style={{ width: '60%', boxShadow: '0px 0px 25px 0px var(--shadow-color-normal)' }}>
                <p className="content-box-subheadline" style={{ marginTop: 12 }}>
                    Sie sind noch unsicher, welche Lösung zu Ihnen
                    passt oder Sie benötigen individuelle Beratung?
                </p>
                <FiMail size={80} color="var(--corporate-color-2)" />
                <Button
                    onClick={() => setPage('contact')}
                    className="page-button"
                    style={{ marginTop: 24 }}
                >
                    Kontakt
                </Button>
            </div>
            <div className="content-box">
                <p className="footnotes">
                    <sup>1</sup>
                    {' '}
                    inkl. 15 Aktionen
                    <sup>2</sup>
                    {' '}
                    und 1 GB Speicher pro User pro Monat, weitere
                    Aktionen
                    <sup>2</sup>
                    {' '}
                    und Speicher zubuchbar, zzgl. einmaliger Set-up Gebühr, Preise auf Anfrage
                </p>
                <p className="footnotes">
                    <sup>2</sup>
                    {' '}
                    1 Aktion wird fällig je erzeugtem/versendeten Protokoll
                </p>
            </div>
        </div>
    );
    return (
        <div>
            {solutionsImage}
            {solutionsOverview}
        </div>
    );
};

export default SolutionsPage;
