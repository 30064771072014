import * as R from 'ramda';
import React, { useEffect } from 'react';
import { FaFilter, FaSortAmountDown, FaSortAmountDownAlt } from 'react-icons/fa';
import { DASHBOARD_FILTER_KEY, useGraph } from '../../../common/hooks.config.new';
import { PROJECT_ACTION_STATUS_TYPE_CHOICES, useProjectChoices } from '../../../hooks/new/useChoices';
import { useModalState } from '../../../hooks/new/useModal';
import { Button } from '../../Common/Controls';
import { DateField, ListField } from '../../Common/Fields';
import { Modal } from '../../Common/Modal';
import '../PrintModal.css';

const initialFilter = () => ({
    meetingDate: {
        from: null, // ISO date e.g. '2021-12-23'
        to: null, // ISO date e.g. '2021-12-23'
        sort: null, // null, 'asc', 'desc'
    },
    dueDate: {
        from: null, // ISO date e.g. '2021-12-23'
        to: null, // ISO date e.g. '2021-12-23'
        sort: null, // null, 'asc', 'desc'
    },
    status: {
        values: {}, // String values
        sort: null, // null, 'asc', 'desc'
    },
});

export const FilterModal = ({
    stack,
}) => {
    const [isOpen] = useModalState('filter');
    return (
        <Modal
            name="filter"
            style={{
                width: '50vw',
                left: '25vw',
            }}
            topbar={(
                <FaFilter size={23} color="var(--corporate-color-7)" />
            )}
        >
            {isOpen
                ? (
                    <FilterModalContent
                        stack={stack}
                    />
                )
                : null}
        </Modal>
    );
};

const FilterModalContent = ({
    stack,
}) => {
    const { useVar } = useGraph(...stack);

    const { choices } = useProjectChoices(stack, PROJECT_ACTION_STATUS_TYPE_CHOICES);
    const statuses = R.map(R.prop('label'))(choices);

    const [filter, _setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const setFilter = val => {
        if (R.is(Function, val)) {
            _setFilter(val(filter));
        } else {
            _setFilter(val);
        }
    };
    useEffect(() => !filter && setFilter(initialFilter()), [filter]);
    return (
        <div className="columns">
            <div>
                <div className="field-column">
                    <SortButton
                        value={R.path(['meetingDate', 'sort'], filter)}
                        onChange={value => setFilter(R.assocPath(['meetingDate', 'sort'], value))}
                    />
                    <DateField
                        label="Meeting Date from"
                        value={R.path(['meetingDate', 'from'], filter)}
                        onChange={value => setFilter(R.assocPath(['meetingDate', 'from'], value))}
                    />
                    <DateField
                        label="to"
                        value={R.path(['meetingDate', 'to'], filter)}
                        onChange={value => setFilter(R.assocPath(['meetingDate', 'to'], value))}
                    />
                </div>
                <div className="field-column">
                    <SortButton
                        value={R.path(['dueDate', 'sort'], filter)}
                        onChange={value => setFilter(R.assocPath(['dueDate', 'sort'], value))}
                    />
                    <DateField
                        label="Due Date from"
                        value={R.path(['dueDate', 'from'], filter)}
                        onChange={value => setFilter(R.assocPath(['dueDate', 'from'], value))}
                    />
                    <DateField
                        label="to"
                        value={R.path(['dueDate', 'to'], filter)}
                        onChange={value => setFilter(R.assocPath(['dueDate', 'to'], value))}
                    />
                </div>
                <div className="field-column">
                    <SortButton
                        value={R.path(['status', 'sort'], filter)}
                        onChange={value => setFilter(R.assocPath(['status', 'sort'], value))}
                    />
                    <ListField
                        label="Status"
                    >
                        {R.map(
                            status => (
                                <div
                                    key={status}
                                    onClick={() => setFilter(R.over(
                                        R.lensPath(['status', 'values']),
                                        R.ifElse(
                                            R.propOr(false, status),
                                            R.dissoc(status),
                                            R.assoc(status, true),
                                        ),
                                    ))}
                                    className={`row ${R.when(
                                        R.pathOr(false, ['status', 'values', status]),
                                        R.always('selected'),
                                    )(filter)}`}
                                >
                                    {status}
                                </div>
                            ),
                        )(statuses)}
                    </ListField>
                </div>
                <Button onClick={() => setFilter(initialFilter())}>Clear Filter</Button>
            </div>
        </div>
    );
};

const SortButton = ({ value = 'asc', onChange }) => (
    <Button
        onClick={() => {
            !value && onChange('asc');
            value === 'asc' && onChange('desc');
            value === 'desc' && onChange(null);
        }}
    >
        {!value && 'No Sorting'}
        {value === 'asc' && (
            <span>
                <FaSortAmountDownAlt size={20} />
                Ascending
            </span>
        )}
        {value === 'desc' && (
            <span>
                <FaSortAmountDown size={20} />
                Descending
            </span>
        )}
    </Button>
);
