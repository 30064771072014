import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { newMsDate } from '@gothub-team/got';
import React, { useCallback } from 'react';
import { BsPeopleFill } from 'react-icons/bs';
import { setOrder } from '../../common/got-adjunct';
import { MEETING_ATTENDEE_EDGE, useGraph } from '../../common/hooks.config.new';
import { Button } from '../Common/Controls';
import { ListRow } from '../Common/ListRow';
import { Attendee } from './Attendee';
import { ChoiceDropdownField } from './new/ChoiceDropdownField';
import { useMeetingAttendees } from '../../hooks/new/useMeetingAttendees';
import { PROJECT_CONTACT_CHOICES } from '../../hooks/new/useChoices';

export const MeetingAttendees = ({
    stack,
    meetingId,
    openContactsModal,
}) => {
    const {
        assoc,
        dissoc,
        mergeGraph,
    } = useGraph(...stack);
    const sortedAttendeeBags = useMeetingAttendees(stack, meetingId);

    const assocAttendee = useCallback(assoc(MEETING_ATTENDEE_EDGE)(meetingId), [meetingId]);
    const dissocAttendee = useCallback(dissoc(MEETING_ATTENDEE_EDGE)(meetingId), [meetingId]);
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <ChoiceDropdownField
                    stack={stack}
                    choiceType={PROJECT_CONTACT_CHOICES}
                    label="Attendees"
                    onChange={({ node: contact }) => assocAttendee(contact, { order: newMsDate() })}
                />
                <Button
                    onClick={openContactsModal}
                >
                    <div className="button-icon">
                        <BsPeopleFill size={25} />
                        <p style={{ marginLeft: '9px' }}>Contacts</p>
                    </div>
                </Button>
            </div>
            {RA.mapIndexed(({ node: attendee, metadata }, index) => (
                <ListRow
                    key={attendee.id}
                    onRemove={() => dissocAttendee(attendee)}
                    onMove={s => R.compose(
                        mergeGraph,
                        setOrder(MEETING_ATTENDEE_EDGE)(meetingId),
                        R.map(R.prop('nodeId')),
                        R.move(index, index + s),
                    )(sortedAttendeeBags)}
                    style={{ boxShadow: '0px 0px 10px 0px var(--shadow-color-ultralight)' }}
                >
                    <div className="attendee-row">
                        <Attendee
                            attendee={attendee}
                            metadata={metadata}
                            assocAttendee={assocAttendee}
                        />
                    </div>
                </ListRow>
            ))(sortedAttendeeBags)}
        </>
    );
};
