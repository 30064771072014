import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

export const Toast = ({
    name,
}) => {
    const toast = useSelector(R.pathOr(undefined, ['toasts', name]), R.equals);
    return toast
        ? (
            <div style={{ zIndex: 10 }} className={`toast ${toast.type}`}>{toast.message}</div>
        )
        : null;
};
