import { configure } from '@gothub-team/got';
import { API_DOMAIN, CLIENT_ID, USER_POOL_ID } from './config';

export const {
    AuthStore,
    useView,
    useFile,
} = configure({
    // host: 'http://localhost:4000/dev',
    host: `https://${API_DOMAIN}`,
    userPoolId: USER_POOL_ID,
    clientId: CLIENT_ID,
});
