import React, { useState } from 'react';
import * as R from 'ramda';
import { RiDashboardFill } from 'react-icons/ri';
import { FaProjectDiagram } from 'react-icons/fa';
import {
    DASHBOARD, MAIN, OFFLINE, useGraph,
} from '../../../common/hooks.config.new';
import { Screen } from '../../Common/Screen';
import '../DashboardScreen.css';
import { GlobalDashboardTopBar } from '../../Elements/new/GlobalDashboardTopBar';
import { ActionsChart, OppsCountDonut } from '../../Elements/new/ChartsArea';
import { TopButtonRow } from '../../Elements/new/TopButtonRow';
import { DashboardTop } from '../../Elements/new/DashboardTop';
import { ListRow } from '../../Common/ListRow';
import '../../Elements/ChartsArea.css';
import { ProjectsOverview } from '../../Elements/new/ProjectsOverview';
import { Button } from '../../Common/Controls';
import { GloablDashboardCalculator } from '../../Elements/new/GlobalDashboardCalculator';
import { GlobalTable } from '../../Elements/new/GlobalTable';

export const GlobalDashboardScreen = () => {
    const stack = [MAIN, OFFLINE, DASHBOARD];

    return (

        <>
            <Screen
                topbar={(
                    <GlobalDashboardTopBar stack={stack} />
                )}
                lpheight={60}
            >
                <GloablDashboardCalculator stack={stack} />
                <GlobalDashboardContent stack={stack} />
            </Screen>
        </>
    );
};

const GlobalDashboardContent = ({
    stack,
}) => {
    const [dashboardTops, setDashboardTops] = useState([]);
    const [dashboardState, setDashboardState] = useState('dashboard');

    switch (dashboardState) {
    case 'dashboard':
        return (
            <GlobalUserOverview
                stack={stack}
                setDashboardTops={setDashboardTops}
                setDashboardState={setDashboardState}
            />
        );
    case 'projects':
        return (
            <ProjectsOverview
                stack={stack}
                setDashboardTops={setDashboardTops}
                setDashboardState={setDashboardState}
            />
        );
    case 'tops':
        return (
            <TopsList
                stack={stack}
                setDashboardTops={setDashboardTops}
                setDashboardState={setDashboardState}
                dashboardTops={dashboardTops}
            />
        );
    default:
        return (
            <GlobalUserOverview
                stack={stack}
                setDashboardTops={setDashboardTops}
                setDashboardState={setDashboardState}
            />
        );
    }
};

const GlobalUserOverview = ({
    stack,
    setDashboardTops,
    setDashboardState,
}) => {
    const { useVar } = useGraph(...stack);
    const [projectsUserTops] = useVar('userTops');
    const projectsCount = R.length(R.keys(projectsUserTops));

    const allActions = R.compose(
        R.unnest,
        R.map(R.pathOr({}, ['tops', 'actions'])),
        R.values,
    )(projectsUserTops);
    const allCriticalActions = R.compose(
        R.unnest,
        R.map(R.pathOr({}, ['tops', 'criticalActions'])),
        R.values,
    )(projectsUserTops);
    const allOpps = R.compose(
        R.unnest,
        R.map(R.pathOr({}, ['tops', 'opps'])),
        R.values,
    )(projectsUserTops);
    const allCriticalOpps = R.compose(
        R.unnest,
        R.map(R.pathOr({}, ['tops', 'criticalOpps'])),
        R.values,
    )(projectsUserTops);
    const chartedOpps = {
        tops:
        [{ type: 'all', tops: R.without(allCriticalOpps, allOpps) },
            { type: 'critical', tops: allCriticalOpps }],
    };

    return (
        <>
            <div className="global-dashboard-area" style={{ paddingTop: 12, paddingBottom: 12 }}>
                <div className="content-container">
                    <div
                        className="column2 hoverwienrover"
                        style={{ alignItems: 'center' }}
                        onClick={() => {
                            setDashboardState('projects');
                        }}
                    >
                        <p className="headline">Projects</p>
                        <p className="count">{projectsCount}</p>
                    </div>
                    <div
                        className="column2 hoverwienrover"
                        style={{ alignItems: 'center' }}
                        onClick={() => {
                            setDashboardTops(allOpps);
                            setDashboardState('tops');
                        }}
                    >
                        <p className="headline">Opportunities</p>
                        <p className="count">{R.length(allOpps)}</p>
                    </div>
                    <div
                        className="column2 hoverwienrover"
                        style={{ alignItems: 'center' }}
                        onClick={() => {
                            setDashboardTops(allActions);
                            setDashboardState('tops');
                        }}
                    >
                        <p className="headline">Actions</p>
                        <p className="count">{R.length(allActions)}</p>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                <div
                    className="global-dashboard-area"
                    style={{
                        width: '30vw', marginRight: 0, paddingTop: 0, paddingBottom: 0,
                    }}
                >
                    <div className="content-container">
                        <div className="diagram-container">
                            <div className="charts-area">
                                <OppsCountDonut
                                    chartedOpps={chartedOpps.tops}
                                    criticalOppsCount={R.length(allCriticalOpps)}
                                    setChartOrKeywordTops={e => {
                                        setDashboardTops(e);
                                        setDashboardState('tops');
                                    }}
                                    global
                                    donutWidth={500}
                                    donutHeight={500}
                                    containerWidth={500}
                                    fontBig={30}
                                    fontSmall={26}
                                    topMargin={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <GlobalTable
                    stack={stack}
                    projectsUserTops={projectsUserTops}
                    allActions={allActions}
                    allCriticalActions={allCriticalActions}
                    allOpps={allOpps}
                    allCriticalOpps={allCriticalOpps}
                    setDashboardState={setDashboardState}
                    setDashboardTops={setDashboardTops}
                />
            </div>
            <div className="global-dashboard-area" style={{ marginBottom: 24, paddingTop: 0 }}>

                <div style={{
                    fontSize: 22, color: 'var(--corporate-color-1)', position: 'relative', top: 24, fontWeight: 'bold',
                }}
                >
                    Actions
                </div>
                <div className="content-container">
                    <div className="diagram-container">
                        <ActionsChart
                            stack={stack}
                            actions={allActions}
                            setChartOrKeywordTops={e => {
                                setDashboardTops(e);
                                setDashboardState('tops');
                            }}
                        />
                    </div>
                </div>
                <div className="content-container">
                    <div className="column">
                        {R.map(a => (
                            <ListRow
                                key={a.nodeId}
                            >
                                <div className="top-search-row">
                                    <TopButtonRow stack={stack} top={a} noEdit />
                                    <DashboardTop
                                        stack={stack}
                                        topId={a.nodeId}
                                        editable
                                        noButton
                                        noId
                                    />
                                </div>
                            </ListRow>
                        ))(R.slice(0, 20)(allActions))}
                    </div>
                </div>
            </div>
        </>
    );
};

const TopsList = ({
    stack,
    setDashboardTops,
    setDashboardState,
    dashboardTops,
}) => (
    <>
        <div className="content-container">
            <div style={{
                display: 'flex', padding: 24, paddingBottom: 0, paddingTop: 12,
            }}
            >
                <Button onClick={() => {
                    setDashboardTops([]);
                    setDashboardState('dashboard');
                }}
                >
                    <RiDashboardFill size={24} />
                    <p style={{ marginLeft: 9, fontWeight: 'bold' }}>Global Dashboard</p>
                </Button>
                <Button
                    style={{ marginLeft: 24 }}
                    onClick={() => {
                        setDashboardState('projects');
                    }}
                >
                    <FaProjectDiagram size={22} />
                    <p style={{ marginLeft: 9, fontWeight: 'bold' }}>Projects</p>
                </Button>
            </div>
            <div className="column">
                {R.map(a => (
                    <ListRow
                        key={a.nodeId}
                        style={{ marginTop: 12 }}
                    >
                        <div className="top-search-row">
                            <TopButtonRow stack={stack} top={a} noEdit />
                            <DashboardTop
                                stack={stack}
                                topId={a.nodeId}
                                editable
                                noButton
                                noId
                            />
                        </div>
                    </ListRow>
                ))(dashboardTops)}
            </div>
        </div>
    </>
);
