import * as R from 'ramda';

export const formatShortDate = iso => iso && new Date(iso).toLocaleDateString(
    'en-GB',
);

export const topHierarchy = tops => {
    const result = {};
    let currentLevel = 0;
    let levelNumbers = [0, 0, 0, 0];
    tops.forEach(({ type, id }) => {
        const headerLevel = Number(type);
        let takeN = 1;
        if (headerLevel >= 0) {
            currentLevel = headerLevel;
            takeN = headerLevel;
        } else {
            takeN = currentLevel + 1;
        }
        levelNumbers[takeN - 1] = (levelNumbers[takeN - 1] || 0) + 1;
        levelNumbers = R.take(takeN)(levelNumbers);
        result[id] = levelNumbers.join('.');
    });
    return result;
};

export const businessNodeString = node => node ? `${node.title ? node.title : ''}${node.businessId ? ` [${node.businessId}]` : ''}` : '';

export const insertElement = currentIndex => list => {
    const thisOrder = R.compose(
        Number,
        R.path([currentIndex, 'order']),
    )(list);
    const nextOrder = R.compose(
        Number,
        R.pathOr(Date.now(), [currentIndex + 1, 'order']),
    )(list);
    return (thisOrder + nextOrder) / 2;
};

export const Id = {
    contactDirectory: projectId => `${projectId}-cd`,
};

export const forEachIndexed = R.addIndex(R.forEach);
