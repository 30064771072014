import { useEffect } from 'react';
import { useGraph } from '../../common/hooks.config.new';
import { constructNodeView } from '../../common/got-adjunct';

export const useNode = (stack, nodeId, include) => {
    const { useView } = useGraph(...stack);
    const nodes = useView(constructNodeView(nodeId, include));
    return nodes && nodes[nodeId];
};

export const usePullNode = (stack, nodeId, include) => {
    const { pull } = useGraph(...stack);
    useEffect(() => nodeId && pull(constructNodeView(nodeId, include)), [nodeId]);
};

export const useNodeFromVar = (stack, prop, include) => {
    const { useVar } = useGraph(...stack);
    const [nodeId] = useVar(prop);
    return useNode(stack, nodeId, include);
};
