import React, { useEffect } from 'react';
import '../LandingPage.css';
import logo from '../../../../logos/pclogo.png';
import welcomeContact from '../Imgs/contact.png';

const ImprintPage = () => {
    useEffect(() => [window.scrollTo(0, 0)], []);
    const imprintImage = (
        <div>
            <img
                style={{ width: '100%' }}
                alt="welcomeImprint"
                src={welcomeContact}
            />
            <div>
                <p className="image-text" style={{ color: 'var(--corporate-color-7)' }}>
                    Impressum
                </p>
            </div>
            <img
                alt="logo"
                src={logo}
                className="image-logo"
            />
        </div>
    );
    const imprintFields = (
        <div className="content-box" style={{ textAlign: 'left' }}>
            <p className="content-box-headline" style={{ marginTop: 72 }}>Impressum</p>
            <p className="content-box-subheadline">
                Inhaltlich Verantwortlicher gemäß § 10 Absatz
                3 MDStV (im nachfolgenden Anbieter genannt)
            </p>
            <p className="content-box-text margin-left">
                procubion GmbH
            </p>
            <p className="content-box-text margin-left">
                Geschäftsführer: Dr. Christian Barysch und Martin Hühn
            </p>
            <p className="content-box-text margin-left">
                Am Pferchelhang 16
            </p>
            <p className="content-box-text margin-left">
                69118 Heidelberg
            </p>
            <p className="content-box-text margin-left">
                Tel.: (06221) 8682803
            </p>
            <p className="content-box-text margin-left">
                E-Mail: info@procubion.com
            </p>
            <p className="content-box-text margin-left">
                Web: www.procubion.com
            </p>
            <p className="content-box-text margin-left">
                Registergericht: Amtsgericht Mannheim
            </p>
            <p className="content-box-text margin-left">
                Registernummer: HRB 741070
            </p>
            <p className="content-box-subheadline">Internetpräsenz, Layout und Gestaltung</p>
            <p className="content-box-text margin-left">
                procubion GmbH
            </p>
            <p className="content-box-text margin-left">
                Geschäftsführer: Dr. Christian Barysch und Martin Hühn
            </p>
            <p className="content-box-text margin-left">
                Am Pferchelhang 16
            </p>
            <p className="content-box-text margin-left">
                69118 Heidelberg
            </p>
            <p className="content-box-text margin-left">
                Tel.: (06221) 8682803
            </p>
            <p className="content-box-text margin-left">
                E-Mail: info@procubion.com
            </p>
            <p className="content-box-text margin-left">
                Web: www.procubion.com
            </p>
            <p className="content-box-subheadline">Haftungshinweis / Haftungsausschluss</p>
            <p className="content-box-text margin-left">
                Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der Anbieter keine Haftung
                für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind
                ausschließlich deren Betreiber verantwortlich. Der Anbieter ist bemüht,
                sein Webangebot stets aktuell und inhaltlich richtig sowie vollständig
                anzubieten. Dennoch ist das Auftreten von Fehlern nicht völlig auszuschließen.
                Der Anbieter übernimmt keine Haftung für die Aktualität, die inhaltliche
                Richtigkeit sowie für die Vollständigkeit, der in seinem Webangebot
                eingestellten Informationen, es sei denn die Fehler wurden vorsätzlich
                oder grob fahrlässig aufgenommen. Dies bezieht sich auf eventuelle Schäden
                materieller oder ideeller Art Dritter, die durch die Nutzung dieses Webangebotes
                verursacht wurden.
            </p>
            <p className="content-box-subheadline">Urheberrecht</p>
            <p className="content-box-text margin-left">
                Inhalt und Struktur dieser Webseiten sind urheberrechtlich geschützt (§§ 4, 69a UrhG).
                Die Verwertung und Vervielfältigung von Inhalten oder Daten bedarf der vorherigen
                schriftlichen Zustimmung des Anbieters (§§ 53, 54 UrhG). Dies gilt auch für die der
                Datenbank zugrunde liegenden Strukturen und Programme (§ 69 c UrhG).
            </p>
            <p className="content-box-subheadline">Datenschutz</p>
            <p className="content-box-text margin-left">siehe Datenschutzhinweise</p>
            <p className="content-box-subheadline">Bildmaterial</p>
            <p className="content-box-text margin-left">
                Das auf der Seite verwendete Bildmaterial stammt von pixabay.com ist in entsprechend
                der dort veröffentlichten Lizenz verwendbar.
            </p>
        </div>
    );
    return (
        <div>
            {imprintImage}
            {imprintFields}
        </div>
    );
};

export default ImprintPage;
