import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { BsArrowRight } from 'react-icons/bs';
import { FaProjectDiagram } from 'react-icons/fa';
import { useHistory } from 'react-router';
import {
    ORGANIZATION_ID_KEY,
    ORGANIZATION_PROJECT_EDGE,
    PROJECT_ID_KEY,
    PROJECT_SUBPROJECT_EDGE,
    ROOT_ORGANIZATION_EDGE,
    store,
    SUBPROJECT_ID_KEY,
    TOPS_TO_UPDATE_KEY,
    useGraph,
} from '../../../common/hooks.config.new';
import { useNodeFromVar } from '../../../hooks/new/useNode';
import { APPLICATION_ROOT_NODE } from '../../../common/config';
import { DropdownField } from '../../Common/Dropdown';

import '../ProjectSelector.css';
import { useModal } from '../../../hooks/new/useModal';
import { Button } from '../../Common/Controls';

const customStyles = {
    singleValue: base => ({
        ...base,
        color: 'white',
    }),
    control: base => ({
        ...base,
        'background': 'transparent',
        'borderColor': 'transparent',
    }),
};

const constructOrgsView = rootNodeId => rootNodeId
    ? {
        [rootNodeId]: {
            as: 'root',
            include: {
                node: true,
            },
            edges: {
                [ROOT_ORGANIZATION_EDGE]: {
                    as: 'organizations',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

const constructProjectsView = organizationId => organizationId
    ? {
        [organizationId]: {
            as: 'organization',
            edges: {
                [ORGANIZATION_PROJECT_EDGE]: {
                    as: 'projects',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

const constructSubprojectsView = projectId => projectId
    ? {
        [projectId]: {
            as: 'project',
            edges: {
                [PROJECT_SUBPROJECT_EDGE]: {
                    as: 'subprojects',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

export const ProjectSelector = ({ stack, global }) => {
    const { useView, useVar, pull } = useGraph(...stack);

    const [preSetId, setPreSetId] = useState('');

    const orgsView = constructOrgsView(APPLICATION_ROOT_NODE);
    useEffect(() => APPLICATION_ROOT_NODE && pull(orgsView), [APPLICATION_ROOT_NODE]);
    const { root: { organizations = {} } = {} } = useView(orgsView);
    const organizationChoices = R.map(organizationBag => ({
        value: R.path(['node', 'id'], organizationBag),
        label: R.path(['node', 'name'], organizationBag),
        body: R.path(['node'], organizationBag),
    }))(R.values(organizations));

    // get projects
    const [organizationId, setOrganizationId] = useVar(ORGANIZATION_ID_KEY);
    const projectsView = constructProjectsView(organizationId);
    useEffect(() => organizationId && pull(projectsView), [organizationId]);
    useEffect(() => global && setPreSetId(projectId), [global]);
    const { organization: { projects = {} } = {} } = useView(projectsView);
    const projectChoices = R.map(projectBag => ({
        value: R.path(['node', 'id'], projectBag),
        label: R.path(['node', 'title'], projectBag),
        body: R.path(['node'], projectBag),
    }))(R.values(projects));

    // get subprojects
    const [projectId, setProjectId] = useVar(PROJECT_ID_KEY);
    const subprojectsView = constructSubprojectsView(projectId);
    useEffect(() => projectId && pull(subprojectsView), [projectId]);
    const { project: { subprojects = {} } = {} } = useView(subprojectsView);
    const subprojectChoices = R.map(subprojectBag => ({
        value: R.path(['node', 'id'], subprojectBag),
        label: R.path(['node', 'title'], subprojectBag),
        body: R.path(['node'], subprojectBag),
    }))(R.values(subprojects));

    const [subprojectId, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const setLoading = useModal('loader');
    const history = useHistory();
    return (
        <div className="project-selector">
            <DropdownField
                styled
                label="Organization"
                options={organizationChoices}
                value={organizationId || ''}
                onChange={option => {
                    setOrganizationId(option.value);
                    setProjectId(undefined);
                    setSubprojectId(undefined);
                }}
                customStyles={customStyles}
            />
            <p style={{
                color: 'var(--corporate-color-6)',
                marginRight: '24px',
                marginLeft: '24px',
            }}
            >
                <BsArrowRight size={25} />
            </p>
            <DropdownField
                styled
                label="Project"
                options={projectChoices}
                value={global ? preSetId : projectId}
                onChange={option => {
                    if (option.value !== projectId) {
                        if (global) {
                            setPreSetId(option.value);
                        }
                        !global && setProjectId(option.value);
                        !global && store.setVar(R.last(stack))(TOPS_TO_UPDATE_KEY, {});
                        setSubprojectId(undefined);
                        !global && setLoading();
                    }
                }}
                customStyles={customStyles}
            />
            <p style={{
                color: 'var(--corporate-color-6)',
                marginRight: '24px',
                marginLeft: '24px',
            }}
            >
                <BsArrowRight size={25} />
            </p>
            {!global
                ? (
                    <>

                        <DropdownField
                            styled
                            label="Subproject"
                            options={subprojectChoices}
                            value={subprojectId || ''}
                            onChange={option => {
                                setSubprojectId(option.value);
                            }}
                            customStyles={customStyles}
                        />
                    </>
                )
                : (
                    <Button
                        className="dashboard-toggle"
                        onClick={() => {
                            setLoading();
                            setProjectId(preSetId);
                            history.push('dashboard');
                        }}
                    >
                        <FaProjectDiagram size={20} />
                        <p style={{ marginLeft: 9 }}>Project Dashboard</p>
                    </Button>
                )}
        </div>
    );
};

export function ProjectIndicator({ stack }) {
    const {
        node: organization,
    } = useNodeFromVar(stack, ORGANIZATION_ID_KEY);
    const {
        node: project,
    } = useNodeFromVar(stack, PROJECT_ID_KEY);
    const {
        node: subproject,
    } = useNodeFromVar(stack, SUBPROJECT_ID_KEY);

    return (
        <div style={{ display: 'flex' }}>
            <p style={{ color: 'var(--corporate-color-6)', fontSize: '18px', cursor: 'default' }}>{R.propOr('', 'name', organization)}</p>
            <p style={{ color: 'var(--corporate-color-6)', marginRight: '24px', marginLeft: '24px' }}><BsArrowRight size={25} /></p>
            <p style={{ color: 'var(--corporate-color-6)', fontSize: '18px', cursor: 'default' }}>{R.propOr('', 'title', project)}</p>
            <p style={{ color: 'var(--corporate-color-6)', marginRight: '24px', marginLeft: '24px' }}><BsArrowRight size={25} /></p>
            <p style={{ color: 'var(--corporate-color-6)', fontSize: '18px', cursor: 'default' }}>{R.propOr('', 'title', subproject)}</p>
        </div>
    );
}
