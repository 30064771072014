import * as RA from 'ramda-adjunct';
import * as R from 'ramda';
import React from 'react';
import {
    DateField, LargeTextField, TextField, Focusable,
} from '../../Common/Fields';
import { useGraph } from '../../../common/hooks.config.new';
import { useNode } from '../../../hooks/new/useNode';
import { ChoiceDropdownField, ResponsibleChoiceDropdownField } from './ChoiceDropdownField';
import { useTopResponsible } from '../../../hooks/new/useTopResponsible';
import { usePreviousVersions } from '../../../hooks/new/useTopHistories';
import { CurrencyField } from './CurrencyField';
import {
    PROJECT_ACTION_STATUS_TYPE_CHOICES, PROJECT_CONTACT_CHOICES, PROJECT_OPP_CATEGORY_TYPE_CHOICES, PROJECT_OPP_STATUS_TYPE_CHOICES,
} from '../../../hooks/new/useChoices';
import { formatShortDate } from '../../../common/util';

export const _Top = ({
    stack,
    topId,
    editable = true,
    renderPreviousControls,
    renderLatestControls,
    noButton,
    scorecard,
    noId,
}) => {
    const { node: top } = useNode(stack, topId);

    const { update: updateTop } = useGraph(...stack);
    const [responsible, setResponsible] = useTopResponsible(stack, topId);

    const previousVersions = usePreviousVersions(stack, R.prop('businessId', top));
    const lastVersion = scorecard && previousVersions.length > 0
        ? R.last(previousVersions).top
        : top;

    const lastVersionAuthor = R.compose(
        R.pathOr('', ['author', 'name']),
        R.last,
    )(previousVersions);

    const lastVersionDate = R.compose(
        formatShortDate,
        R.defaultTo(R.prop('modifiedDate', top) || R.prop('createdDate', top) || ''),
        R.prop('editedDate'),
        R.last,
    )(previousVersions);

    const updateTopType = type => updateTop(
        R.assoc('type', type, top),
    );
    if (top) {
        return (
            <div className="top" key={top.id}>
                {editable && !noId && <div className="top-business-id">{top.businessId}</div>}
                {previousVersions
                && previousVersions.length > 0
                && (
                <div className="previous">
                    {R.compose(
                        RA.mapIndexed((previousVersion, index) => (
                            <React.Fragment
                                key={previousVersion.top.id}
                            >
                                <div
                                    style={{
                                        color: 'var(--corporate-color-1-light)',
                                        marginTop: 6,
                                        marginBottom: 3,
                                    }}
                                >
                                    {index === 0 ? 'created' : 'edited'}
                                    {' '}
                                    on
                                    {' '}
                                    {index === 0
                                        ? formatShortDate(previousVersion.createdDate)
                                        : formatShortDate(previousVersion.editedDate)}
                                    {' '}
                                    {previousVersion.author
                                    && previousVersion.author.name
                                   && `by ${previousVersion.author.name}`}
                                </div>
                                <div className="row">
                                    <TopSwitch
                                        stack={stack}
                                        top={previousVersion.top}
                                        responsible={previousVersion.responsible}
                                        noButton
                                    />
                                    {renderPreviousControls ? renderPreviousControls(previousVersion) : null}
                                </div>
                            </React.Fragment>
                        )),
                        R.dropLast(1),
                    )(previousVersions)}
                </div>
                )}
                <>
                    {!editable && (
                    <div
                        style={{ color: 'var(--corporate-color-1-light)', marginTop: 6 }}
                    >
                        {previousVersions.length > 1 ? 'edited on' : 'created on'}
                        {' '}
                        {lastVersionDate}
                        {' '}
                        {lastVersionAuthor && `by ${lastVersionAuthor}`}
                    </div>
                    )}
                    <div className="latest">
                        <TopSwitch
                            stack={stack}
                            top={lastVersion}
                            updateTop={editable ? updateTop : false}
                            updateTopType={updateTopType}
                            responsible={responsible}
                            setResponsible={setResponsible}
                            noButton={noButton}
                            lastVersion
                            scorecard={scorecard}
                        />
                        {renderLatestControls ? renderLatestControls() : null}
                    </div>
                </>
            </div>
        );
    }

    return null;
};

const TopSwitch = ({
    stack,
    top,
    updateTop,
    updateTopType,
    responsible,
    setResponsible,
    openScorecardModal,
    openProjectOppsModal,
    setAction,
    setOpp,
    noButton,
    lastVersion,
    scorecard,
}) => R.propOr(
    <TopChoice
        updateTopType={updateTopType}
    />,
    top.type,
)({
    'a': <TopA
        stack={stack}
        top={top}
        updateTop={updateTop}
        responsible={responsible}
        setResponsible={setResponsible}
        openProjectOppsModal={openProjectOppsModal}
        setAction={setAction}
        lastVersion={lastVersion}
    />,
    'o': <TopO
        stack={stack}
        top={top}
        updateTop={updateTop}
        responsible={responsible}
        setResponsible={setResponsible}
        openScorecardModal={openScorecardModal}
        setOpp={setOpp}
        noButton={noButton}
        scorecard={scorecard}
    />,
    'r': <TopR
        stack={stack}
        top={top}
        updateTop={updateTop}
        responsible={responsible}
        setResponsible={setResponsible}
    />,
    's': <TopS
        top={top}
        updateTop={updateTop}
    />,
    'i': <TopI
        top={top}
        updateTop={updateTop}
    />,
    'd': <TopD
        top={top}
        updateTop={updateTop}
    />,
    '1': <Headline
        top={top}
        updateTop={updateTop}
    />,
    '2': <Headline
        top={top}
        updateTop={updateTop}
    />,
    '3': <Headline
        top={top}
        updateTop={updateTop}
    />,
});

const TopChoice = ({ updateTopType }) => (
    <Focusable autoFocus>
        {updateTopType && (
        <input
            className="field text-field"
            style={{ flex: 1, fontWeight: 'bold' }}
            onChange={e => updateTopType(R.toLower(e.nativeEvent.data))}
            value="Choose TOP type (Hotkeys: [A]ction, [O]pportunity, [R]isk, [S]tatement, [I]nformation, [D]ecision, Headline [1], [2], [3])"
        />
        )}
    </Focusable>
);

const TopA = ({
    stack,
    top,
    updateTop,
    responsible,
    setResponsible,
    lastVersion,
}) => (
    <>
        <div
            className="top-type-badge"
            style={{ backgroundColor: 'var(--corporate-color-14)' }}
        >
            A

        </div>
        <LargeTextField
            label="Description"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(
                R.assoc('description', value, top),
            )}
            autoFocus
        />
        <ChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_ACTION_STATUS_TYPE_CHOICES}
            label="Status"
            value={R.propOr('', 'status', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={option => updateTop(
                R.assoc('status', option.value, top),
            )}
        />
        <DateField
            lastVersion={lastVersion}
            late={top.status === 'Late'}
            label="Due Date"
            value={R.propOr('', 'dueDate', top)}
            editable={RA.isTruthy(updateTop)}
            locale="en-gb"
            onChange={value => updateTop(
                R.assoc('dueDate', value, top),
            )}
        />
        <ResponsibleChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_CONTACT_CHOICES}
            label="Responsible"
            value={R.propOr('', 'id', responsible)}
            editable={RA.isTruthy(updateTop)}
            onChange={contact => setResponsible(contact.node)}
        />
    </>
);

const TopO = ({
    stack,
    top,
    updateTop,
    responsible,
    setResponsible,
}) => (
    <>
        <div
            className="top-type-badge"
            style={{ backgroundColor: 'var(--corporate-color-13)' }}
        >
            O

        </div>
        <LargeTextField
            label="Description"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(
                R.assoc('description', value, top),
            )}
            autoFocus
        />
        <ChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_OPP_CATEGORY_TYPE_CHOICES}
            label="Category"
            value={R.propOr('', 'category', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={option => updateTop(
                R.assoc('category', option.value, top),
            )}
        />
        <ChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_OPP_STATUS_TYPE_CHOICES}
            label="Status"
            value={R.propOr('', 'status', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={option => updateTop(
                R.assoc('status', option.value, top),
            )}
        />
        <CurrencyField
            stack={stack}
            label="Savings"
            value={R.propOr('', 'savings', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(
                R.assoc('savings', value, top),
            )}
        />
        <ResponsibleChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_CONTACT_CHOICES}
            label="Responsible"
            value={R.propOr('', 'id', responsible)}
            editable={RA.isTruthy(updateTop)}
            onChange={contact => setResponsible(contact.node)}
        />
    </>
);

const TopR = ({
    stack,
    top,
    updateTop,
    responsible,
    setResponsible,
}) => (
    <>
        <div
            className="top-type-badge"
            style={{ backgroundColor: 'var(--corporate-color-12)' }}
        >
            R

        </div>
        <LargeTextField
            label="Description"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(
                R.assoc('description', value, top),
            )}
            autoFocus
        />
        <TextField
            label="Category"
            value={R.propOr('', 'category', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(
                R.assoc('category', value, top),
            )}
        />
        <TextField
            label="Reference"
            value={R.propOr('', 'reference', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(
                R.assoc('reference', value, top),
            )}
        />
        <TextField
            label="Cost Increase"
            value={R.propOr('', 'costIncrease', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(
                R.assoc('costIncrease', value, top),
            )}
        />
        <ResponsibleChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_CONTACT_CHOICES}
            label="Responsible"
            value={R.propOr('', 'id', responsible)}
            editable={RA.isTruthy(updateTop)}
            onChange={choice => setResponsible(choice.node)}
        />
    </>
);

const TopS = ({
    top,
    updateTop,
}) => (
    <>
        <div
            className="top-type-badge"
            style={{ backgroundColor: 'grey' }}
        >
            S

        </div>
        <LargeTextField
            label="Description"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(
                R.assoc('description', value, top),
            )}
            autoFocus
        />
    </>
);

const TopI = ({
    top,
    updateTop,
}) => (
    <>
        <div
            className="top-type-badge"
            style={{ backgroundColor: '#C5C9D3' }}
        >
            I

        </div>
        <LargeTextField
            label="Description"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(
                R.assoc('description', value, top),
            )}
            autoFocus
        />
    </>
);

const TopD = ({
    top,
    updateTop,
}) => (
    <>
        <div
            className="top-type-badge"
            style={{ backgroundColor: 'var(--corporate-color-7)' }}
        >
            D

        </div>
        <LargeTextField
            label="Description"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(
                R.assoc('description', value, top),
            )}
            autoFocus
        />
    </>
);

const Headline = ({
    top,
    updateTop,
}) => (
    <>
        <div className="top-type-badge-headline">{top.type}</div>
        <TextField
            style={{ width: '50vw' }}
            label="Headline"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(
                R.assoc('description', value, top),
            )}
            autoFocus
        />
    </>
);
