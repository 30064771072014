/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import * as R from 'ramda';
import { BiLinkAlt } from 'react-icons/bi';
import {
    PROJECT_ID_KEY, PROJECT_OPPS_MODAL, store, TOP_ACTION_EDGE, useGraph,
} from '../../../common/hooks.config.new';
import { useModalState } from '../../../hooks/new/useModal';
import { useProjectOpps } from '../../../hooks/new/useProjectOpps';
import { Modal } from '../../Common/Modal';
import { OppTops } from '../../Elements/new/OppTops';
import { useToast } from '../../../hooks/new/useToast';
import { Toast } from '../../Elements/new/Toast';
import { createOfflinePush } from '../../../common/got-adjunct';

export const ProjectOppsModal = ({
    stack,
    noEdit,
}) => {
    const [isOpen] = useModalState('project-opps');
    return (
        <Modal
            name="project-opps"
            style={{
                height: '80vh',
                top: '10vh',
                width: '80vw',
                left: '10vw',
            }}
            topbar={(
                <>
                    <BiLinkAlt
                        size={24}
                        color="var(--corporate-color-7)"
                    />
                </>
            )}
        >
            {isOpen
                ? (
                    <ProjectOppModalContent
                        stack={stack}
                        noEdit={noEdit}
                    />
                )
                : null}
        </Modal>
    );
};

const ProjectOppModalContent = ({
    stack: parentStack, noEdit,
}) => {
    const stack = R.append(PROJECT_OPPS_MODAL, parentStack);
    const { assoc } = useGraph(...stack);
    const [selectedActionId] = useModalState('project-opps');
    const projectId = store.getVar(...stack)(PROJECT_ID_KEY);
    const opps = useProjectOpps(stack, projectId);

    const setToast = useToast('assoc-message');
    const toastConfig = {
        textOnStart: 'Saving data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const push = createOfflinePush(stack, setToast, toastConfig);
    const assocAction = top => {
        assoc(TOP_ACTION_EDGE)(top.id)({ id: selectedActionId });
        push();
    };

    const [oppSearch, setOppSearch] = useState('');

    const searchedOpps = (searchString, opportunities) => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.filter(o => (
                (o.description
                    && o.description.toUpperCase().search(string) !== -1))
                    || (o.businessId
                    && o.businessId.toUpperCase().search(string) !== -1))(opportunities);
        }
        return [];
    };

    return (
        <>
            <div className="columns">
                <div>
                    <div className="opp-headline">
                        Available opportunities
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <input
                            className="searchbar"
                            placeholder="search project opportunities..."
                            value={oppSearch}
                            onChange={e => setOppSearch(e.target.value)}
                            style={{ margin: 12, marginBottom: 0 }}
                        />
                    </div>
                    <OppTops
                        noEdit={noEdit}
                        stack={stack}
                        opps={oppSearch.length > 0
                            ? searchedOpps(oppSearch, opps)
                            : opps.slice(0, 19)}
                        assocAction={assocAction}
                    />
                </div>
            </div>
            <Toast name="assoc-message" />
        </>
    );
};
