import { newId, newIsoDate, newMsDate } from '@gothub-team/got';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import * as R from 'ramda';
import {
    MEETING_TOP_EDGE, PROTOCOL, store, SUBPROJECT_ID_KEY, SUBPROJECT_MEETING_EDGE, TOPS_TO_UPDATE_KEY, TOP_RESPONSIBLE_EDGE,
} from '../../../common/hooks.config.new';
import { Button } from '../../Common/Controls';
import { useDummyGraph } from '../../../common/got-adjunct';
import { useNavigator } from '../../../hooks/new/useNavigator';

export const NewMeetingButton = ({ stack }) => {
    const { navigateToProtocol } = useNavigator(stack);

    const createNewMeeting = () => {
        const subprojectId = store.getVar(...stack)(SUBPROJECT_ID_KEY);
        const topsToUpdate = store.getVar(...stack)(TOPS_TO_UPDATE_KEY);

        const {
            add, inheritRights, assoc, getGraph,
        // eslint-disable-next-line react-hooks/rules-of-hooks
        } = useDummyGraph();

        const newMeetingId = newId();
        const newMeeting = {
            id: newMeetingId,
            submitted: false,
        };
        add(SUBPROJECT_MEETING_EDGE)(subprojectId)(newMeeting);
        inheritRights(newMeetingId)(subprojectId);

        R.forEach(
            ({
                top, responsible,
            }) => {
                const newTopId = newId();
                const newTop = R.compose(
                    R.assoc('modifiedDate', newIsoDate()),
                    R.assoc('id', newTopId),
                )(top);
                add(MEETING_TOP_EDGE)(newMeetingId)(newTop, { order: newMsDate() });
                inheritRights(newTopId)(subprojectId);
                responsible && assoc(TOP_RESPONSIBLE_EDGE)(newTopId)(responsible);
            },
        )(R.values(topsToUpdate));

        store.mergeGraph(getGraph(), PROTOCOL);
        navigateToProtocol(newMeetingId);
    };

    return (
        <Button
            onClick={() => createNewMeeting()}
            style={{ marginLeft: 6 }}
        >
            <div className="button-icon">
                <FaPlus size={22} color="var(--corporate-color-1)" />
                <p style={{ marginLeft: '9px' }}>New protocol</p>
            </div>
        </Button>
    );
};
