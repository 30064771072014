import { useEffect, useState } from 'react';
import { AuthStore } from '../../common/hooks.config';

export const useUserEmail = () => {
    const [currentUser, setCurrentUser] = useState('');

    useEffect(async () => {
        const currUser = await AuthStore.getCurrentUserAttr('email');
        setCurrentUser(currUser);
    }, []);

    return currentUser;
};
