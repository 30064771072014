import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { FaRegEdit, FaSave } from 'react-icons/fa';
import { newId, newIsoDate, newMsDate } from '@gothub-team/got';
import {
    EDIT_TOP_MODAL, MEETING_TOP_EDGE, SUBPROJECT_ID_KEY, SUBPROJECT_MEETING_EDGE, TOP_RESPONSIBLE_EDGE, store, PROJECT_ID_KEY, useGraph, MEETING_ATTENDEE_EDGE, GROUPED_TOPS_KEY, SORTED_GROUPED_TOPS_KEY,
} from '../../../common/hooks.config.new';
import { useModalState } from '../../../hooks/new/useModal';
import { Modal } from '../../Common/Modal';
import { Toast } from '../../Elements/new/Toast';
import { ListRow } from '../../Common/ListRow';
import { ProtocolTop } from '../../Elements/new/ProtocolTop';
import { useNode, useNodeFromVar } from '../../../hooks/new/useNode';
import { createOfflinePush, useDummyGraph } from '../../../common/got-adjunct';
import { useToast } from '../../../hooks/new/useToast';
import { Button } from '../../Common/Controls';
import { useUserEmail } from '../../../hooks/new/useUserEmail';
import { useContact } from '../../../hooks/new/useContact';
import { getTopInfo } from '../../../common/topinfo.util';
import { TopButtonRow } from '../../Elements/new/TopButtonRow';

export const EditTopModal = ({
    stack: parentStack, onSave,
}) => {
    const [isOpen] = useModalState('editTop');
    const stack = R.append(EDIT_TOP_MODAL, parentStack);
    const { clear } = useGraph(...stack);
    return (
        <Modal
            name="editTop"
            style={{
                height: '80vh',
                top: '10vh',
                width: '80vw',
                left: '10vw',
            }}
            close={clear}
            topbar={(
                <>
                    <FaRegEdit size={22} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? (
                    <EditTopModalContent
                        stack={stack}
                        onSave={onSave}
                    />
                )
                : null}
        </Modal>
    );
};

const EditTopModalContent = ({
    stack, onSave,
}) => {
    const [topId] = useState(newId());
    const [meetingId] = useState(newId());

    const { useVar } = useGraph(...stack);

    const user = useUserEmail();
    const [projectId] = useVar(PROJECT_ID_KEY);
    const { contact } = useContact(stack, projectId, user);

    const author = contact && [{
        node: contact.node,
        nodeId: contact.nodeId,
        metadata: { presence: 'M', order: newMsDate() },
    }];

    const [selectedTop] = useModalState('editTop');
    const selectedTopBag = useNode(stack, selectedTop.id);
    const selectedTopBusinessId = R.path(['node', 'businessId'], selectedTopBag);
    const {
        node: project,
    } = useNodeFromVar(stack, PROJECT_ID_KEY);
    const {
        node: subproject,
    } = useNodeFromVar(stack, SUBPROJECT_ID_KEY);

    const createHistory = editedTop => {
        const topToEdit = editedTop.top;
        const subprojectId = store.getVar(...stack)(SUBPROJECT_ID_KEY);

        const {
            add, inheritRights, assoc, getGraph,
            // eslint-disable-next-line react-hooks/rules-of-hooks
        } = useDummyGraph();

        const newMeeting = {
            id: meetingId,
            isDummy: true,
            title: 'DUMMY',
            closed: true,
            date: newIsoDate(),
        };
        add(SUBPROJECT_MEETING_EDGE)(subprojectId)(newMeeting);
        inheritRights(meetingId)(subprojectId);
        assoc(MEETING_ATTENDEE_EDGE)(meetingId)(contact.node, { presence: 'M', order: newMsDate() });

        const newTop = R.compose(
            R.assoc('modifiedDate', newIsoDate()),
            R.assoc('id', topId),
        )(topToEdit.top);
        add(MEETING_TOP_EDGE)(meetingId)(newTop, { order: newMsDate() });
        inheritRights(topId)(subprojectId);
        topToEdit.responsible && assoc(TOP_RESPONSIBLE_EDGE)(topId)(topToEdit.responsible);

        store.mergeGraph(getGraph(), EDIT_TOP_MODAL);

        // update histories
        const { tops: groupedProjectTops } = store.getVar(...stack)(GROUPED_TOPS_KEY);
        const topInfo = getTopInfo({
            project,
            subproject,
            meeting: newMeeting,
            attendees: contact ? author : {},
            top: newTop,
            responsibles: { responsibleBag: { node: topToEdit.responsible } }, // simulating view edge bag
        });

        const businessId = R.prop('businessId', newTop);
        const newGroupedTops = R.assocPath([businessId, topId], topInfo, groupedProjectTops);

        const sortedGroupedTops = R.map(
            R.compose(
                R.sortBy(
                    R.compose(
                        entry => R.propOr(false, 'meetingDate', entry)
                                || R.propOr(false, 'modifiedDate', entry)
                                || R.propOr(false, 'createdDate', entry),
                    ),
                ),
                R.values(),
            ),
        )(newGroupedTops);

        store.setVar(R.last(stack))(SORTED_GROUPED_TOPS_KEY, { tops: sortedGroupedTops });
    };

    useEffect(() => {
        selectedTop.id && contact && createHistory(selectedTop);
    }, [selectedTop.id, contact]);

    const setToast = useToast('editTop');
    const toastConfig = {
        textOnStart: 'Saving data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const push = createOfflinePush(stack, setToast, toastConfig);

    const save = async () => {
        await push();
        onSave && onSave(selectedTop.id, topId);
    };

    return (
        <>
            <div className="columns">
                <div>
                    <div className="opp-headline">
                        Edit TOP:
                        {' '}
                        {selectedTopBusinessId}
                    </div>
                    <ListRow
                        key={selectedTop.id}
                        style={{ boxShadow: '0px 0px 10px 0px var(--shadow-color-light)' }}
                    >
                        <div className="top-search-row">
                            <TopButtonRow
                                top={selectedTop}
                                stack={stack}
                                noEdit
                            />
                            <ProtocolTop
                                stack={stack}
                                topId={topId}
                                editable
                                noButton
                                noId
                            />
                        </div>
                    </ListRow>
                </div>
            </div>
            <div style={{
                display: 'flex', flex: 1, justifyContent: 'center', marginTop: 18,
            }}
            >
                <Button
                    onClick={save}
                    style={{ width: 200 }}
                >
                    <FaSave size={24} color="var(--corporate-color-1)" />
                    <p style={{ fontSize: 16, marginLeft: 12, fontWeight: 'bold' }}>Save</p>
                </Button>
            </div>
            <Toast name="editTop" />
        </>
    );
};
