import React from 'react';
import * as R from 'ramda';
import { BsCardHeading } from 'react-icons/bs';
import { FaLink, FaRegEdit, FaUnlink } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { BiLinkAlt } from 'react-icons/bi';
import { TOPS_TO_UPDATE_KEY, useGraph } from '../../../common/hooks.config.new';
import { useModal } from '../../../hooks/new/useModal';
import { Button, Checkbox } from '../../Common/Controls';

export const TopButtonRow = ({
    top,
    responsible,
    previousVersions,
    canAdminProject,
    canWriteProject,
    subprojectId,
    stack,
    editMessage,
    userName,
    scorecard,
    dissocAction,
    oppId,
    actionId,
    actions,
    opp,
    assocAction,
    noEdit,
}) => {
    const { useVar } = useGraph(...stack);
    const [topsToUpdate, setTopsToUpdate] = useVar(TOPS_TO_UPDATE_KEY);
    const openScorecardModal = useModal('scoreCard');
    const openEditTopModal = useModal('editTop');
    const openEditScorecardTopModal = useModal('editScorecardTop');
    const openReminderModal = useModal('reminder');
    const openProjectOppsModal = useModal('project-opps');
    const openProjectActionsModal = useModal('project-actions');
    const topType = scorecard ? top.top.top.type : top.type;

    return (
        <div
            className="dashboard-top-button-row"
        >
            <div
                className="top-business-id"
                style={{
                    color: 'var(--corporate-color-1)',
                }}
            >
                {top.businessId || R.pathOr('', ['top', 'top', 'businessId'], top)}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex' }}>
                    {topType === 'o' && (
                        <>
                            {!scorecard && !opp && (
                            <Button
                                onClick={() => {
                                    openScorecardModal({ id: top.id });
                                }}
                                style={{ marginRight: 24 }}
                            >
                                <BsCardHeading
                                    size={22}
                                    color="var(--corporate-color-1)"
                                />
                                <p style={{ marginLeft: 9 }}>Scorecard</p>
                            </Button>
                            )}
                            {canWriteProject && !noEdit && (
                            <Button
                                onClick={() => {
                                    openProjectActionsModal(top.id);
                                }}
                                style={{ marginRight: 24 }}
                            >
                                <BiLinkAlt
                                    size={24}
                                    color="var(--corporate-color-1)"
                                />
                                <p style={{ marginLeft: 9 }}>Link actions</p>
                            </Button>
                            )}
                        </>
                    )}
                    {canAdminProject
                    && (
                    <Button
                        onClick={() => {
                            openReminderModal({ top, responsible, userName });
                        }}
                        style={{ marginRight: 24 }}
                    >
                        <FiMail
                            size={22}
                            color="var(--corporate-color-1)"
                        />
                        <p style={{ marginLeft: 9 }}>Reminder</p>
                    </Button>
                    )}
                    {canWriteProject
                    && topType === 'a'
                    && !noEdit && (
                    <Button
                        onClick={() => {
                            openProjectOppsModal(top.id);
                        }}
                        style={{ marginRight: 24 }}
                    >
                        <BiLinkAlt
                            size={24}
                            color="var(--corporate-color-1)"
                        />
                        <p style={{ marginLeft: 9 }}>Link opportunities</p>
                    </Button>
                    )}
                    {canAdminProject && !noEdit && (
                    <Button
                        style={{ marginRight: 24 }}
                        onClick={() => {
                            subprojectId
                                ? (scorecard
                                    ? openEditScorecardTopModal(top)
                                    : openEditTopModal({
                                        id: top.id,
                                        top: { top, responsible, previousVersions },
                                    }))
                                : editMessage();
                        }}
                    >
                        <FaRegEdit
                            size={22}
                            color="var(--corporate-color-1)"
                        />
                        <p style={{ marginLeft: 9 }}>Edit</p>
                    </Button>
                    )}
                    {canWriteProject && scorecard && actions && (
                        <Button
                            onClick={() => {
                                dissocAction(oppId)({ id: actionId });
                            }}
                            className="dissoc-button"
                            style={{ marginRight: 24 }}
                        >
                            <FaUnlink size={18} />
                            Remove from scorecard
                        </Button>
                    )}
                    {opp && (
                    <Button
                        onClick={() => {
                            assocAction(opp);
                        }}
                        className="assoc-button"
                    >
                        <FaLink size={18} />
                        Add to scorecard
                    </Button>
                    )}
                </div>
                {!noEdit && (
                <Checkbox
                    style={{ color: 'var(--corporate-color-1)' }}
                    label="Add to protocol"
                    onChange={value => value
                        ? setTopsToUpdate(R.assoc(
                            top.id,
                            { top, responsible, previousVersions },
                            topsToUpdate,
                        ))
                        : setTopsToUpdate(R.dissoc(top.id, topsToUpdate))}
                    value={R.propOr(false, top.id, topsToUpdate)}
                />
                )}
            </div>
        </div>
    );
};
