import React from 'react';
import * as R from 'ramda';
import { RiDashboardFill } from 'react-icons/ri';
import { FaProjectDiagram } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { Button } from '../../Common/Controls';
import { useNode } from '../../../hooks/new/useNode';
import { PROJECT_ID_KEY, SUBPROJECT_ID_KEY, useGraph } from '../../../common/hooks.config.new';

export const ProjectsOverview = ({
    stack,
    setDashboardTops,
    setDashboardState,
}) => {
    const { useVar } = useGraph(...stack);
    const [projectsUserTops] = useVar('userTops');

    return (
        <>
            <div style={{ padding: 24, paddingBottom: 0, paddingTop: 12 }}>
                <Button onClick={() => {
                    setDashboardState('dashboard');
                }}
                >
                    <RiDashboardFill size={24} />
                    <p style={{ marginLeft: 9, fontWeight: 'bold' }}>Global Dashboard</p>
                </Button>
            </div>
            <div className="project-overview">
                {R.map(({ projectId, tops }) => (
                    <Project
                        stack={stack}
                        projectId={projectId}
                        tops={tops}
                        setDashboardTops={setDashboardTops}
                        setDashboardState={setDashboardState}
                    />
                ))(R.values(projectsUserTops))}
            </div>
        </>
    );
};

const Project = ({
    stack,
    projectId,
    tops,
    setDashboardTops,
    setDashboardState,
}) => {
    const { node: project } = useNode(stack, projectId); const {
        useVar,
    } = useGraph(...stack);
    const [, setProjectId] = useVar(PROJECT_ID_KEY);
    const [, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const history = useHistory();

    return (
        <div className="project">
            <div className="header">
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flex: 1,
                }}
                >
                    {project.title}
                    <Button
                        style={{ height: 30 }}
                        onClick={() => {
                            setProjectId(projectId);
                            setSubprojectId(undefined);
                            history.push('dashboard');
                        }}
                    >
                        <FaProjectDiagram size={18} />
                        <p style={{ marginLeft: 9 }}>Project Dashboard</p>
                    </Button>
                </div>
            </div>
            <div style={{ padding: 12, paddingTop: 0 }}>
                <div className="project-container">
                    <div className="information">{project.customerName}</div>
                </div>
                <div
                    style={{ marginBottom: 24 }}
                    className="project-information-container"
                >
                    <div
                        className="column hoverwienrover"
                        style={{
                            backgroundColor: R.length(tops.opps) === 0 && 'transparent',
                            cursor: R.length(tops.opps) === 0 && 'normal',
                        }}
                        onClick={() => {
                            setDashboardTops(tops.opps);
                            setDashboardState('tops');
                        }}
                    >
                        <div
                            className="title"
                            style={{
                                color: R.length(tops.opps) === 0 && 'darkgray',
                            }}
                        >
                            Opportunities

                        </div>
                        <div
                            className="count"
                            style={{
                                color: R.length(tops.opps) === 0 && 'darkgray',
                            }}
                        >
                            {R.length(tops.opps)}

                        </div>
                    </div>
                    <div
                        className="column hoverwienrover"
                        style={{
                            backgroundColor: R.length(tops.actions) === 0 && 'transparent',
                            cursor: R.length(tops.actions) === 0 && 'normal',
                        }}
                        å
                        onClick={() => {
                            setDashboardTops(tops.actions);
                            setDashboardState('tops');
                        }}
                    >
                        <div
                            className="title"
                            style={{
                                color: R.length(tops.actions) === 0 && 'darkgray',
                            }}
                        >
                            Actions

                        </div>
                        <div
                            className="count"
                            style={{
                                color: R.length(tops.actions) === 0 && 'darkgray',
                            }}
                        >
                            {R.length(tops.actions)}

                        </div>
                    </div>
                </div>
                <div className="project-information-container">
                    <div
                        className="column hoverwienrover"
                        style={{
                            backgroundColor: R.length(tops.criticalOpps) === 0 && 'transparent',
                            cursor: R.length(tops.criticalOpps) === 0 && 'default',
                        }}
                        onClick={() => {
                            setDashboardTops(tops.criticalOpps);
                            setDashboardState('tops');
                        }}
                    >
                        <div
                            className="critical-title"
                            style={{
                                color: R.length(tops.criticalOpps) === 0 && 'darkgray',
                            }}
                        >
                            Critical Opportunities

                        </div>
                        <div
                            className="critical-count"
                            style={{
                                color: R.length(tops.criticalOpps) === 0 && 'darkgray',
                            }}
                        >
                            {R.length(tops.criticalOpps)}

                        </div>
                    </div>
                    <div
                        className="column hoverwienrover"
                        style={{
                            backgroundColor: R.length(tops.criticalActions) === 0 && 'transparent',
                            cursor: R.length(tops.criticalActions) === 0 && 'default',
                        }}
                        onClick={() => {
                            setDashboardTops(tops.criticalActions);
                            setDashboardState('tops');
                        }}
                    >
                        <div
                            className="critical-title"
                            style={{
                                color: R.length(tops.criticalActions) === 0 && 'darkgray',
                            }}
                        >
                            Critical Actions

                        </div>
                        <div
                            className="critical-count"
                            style={{
                                color: R.length(tops.criticalActions) === 0 && 'darkgray',
                            }}
                        >
                            {R.length(tops.criticalActions)}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
