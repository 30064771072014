import React, { useEffect, useMemo } from 'react';
import { BsCardHeading } from 'react-icons/bs';
import * as R from 'ramda';
import { FaSave } from 'react-icons/fa';
import {
    PROJECT_ID_KEY,
    SCORE_CARD_MODAL, SORTED_GROUPED_TOPS_KEY, SUBPROJECT_ID_KEY, TOP_ACTION_EDGE, useGraph,
} from '../../../common/hooks.config.new';
import { useModalState } from '../../../hooks/new/useModal';
import { Modal } from '../../Common/Modal';
import { OppActions } from '../../Elements/new/OppActions';
import { Toast } from '../../Elements/new/Toast';
import { ListRow } from '../../Common/ListRow';
import { ProtocolTop } from '../../Elements/new/ProtocolTop';
import { useNode } from '../../../hooks/new/useNode';
import { constructNodeView, createOfflinePush } from '../../../common/got-adjunct';
import { OppTimeline } from '../../Elements/new/OppTimeline';
import { useToast } from '../../../hooks/new/useToast';
import { Button } from '../../Common/Controls';
import { TopButtonRow } from '../../Elements/new/TopButtonRow';
import { useUserEmail } from '../../../hooks/new/useUserEmail';
import { EditScorecardTopModal } from './EditScorecardTopModal';

export const ScoreCardModal = ({
    stack,
    autoPush,
    noEdit,
}) => {
    const [isOpen] = useModalState('scoreCard');
    return (
        <Modal
            name="scoreCard"
            style={{
                height: '88vh',
                top: '6vh',
                width: '84vw',
                left: '8vw',
            }}
            topbar={(
                <>
                    <BsCardHeading size={22} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? (
                    <ScoreCardModalContent
                        stack={stack}
                        autoPush={autoPush}
                        noEdit={noEdit}
                    />
                )
                : null}
        </Modal>
    );
};

const actionPullView = ({
    edges: {
        [TOP_ACTION_EDGE]: {
            as: 'actions',
            include: {
                edges: true,
            },
        },
    },
});

const actionNodeView = ({
    edges: {
        [TOP_ACTION_EDGE]: {
            as: 'actions',
            include: {
                node: true,
                edges: true,
            },
        },
    },
});

const ScoreCardModalContent = ({
    stack: parentStack,
    autoPush,
    noEdit,
}) => {
    const stack = R.append(SCORE_CARD_MODAL, parentStack);
    const {
        useVar, useView, pull, dissoc, merge, update,
    } = useGraph(...stack);
    const [selectedOpp] = useModalState('scoreCard');
    const selectedOppId = selectedOpp.id;
    const selectedOppBag = useNode(stack, selectedOppId);
    const selectedOppBusinessId = R.path(['node', 'businessId'], selectedOppBag);

    const [{ tops: sortedGroupedTops = {} }] = useVar(SORTED_GROUPED_TOPS_KEY);

    const adjustedOpp = top => {
        const indexOfOpp = R.indexOf(top.node.businessId, R.keys(sortedGroupedTops));
        const allVersions = R.values(sortedGroupedTops)[indexOfOpp];
        const adjustedTop = {
            id: allVersions.length === 0 ? top.nodeId : R.last(allVersions).top.id,
            oppId: top.oppId || '',
            top: {
                top: allVersions.length === 0 ? top.node : R.last(allVersions).top,
                responsible: allVersions.length === 0 ? '' : R.last(allVersions).responsible,
                previousVersions: R.init(allVersions),
            },
        };
        return adjustedTop;
    };
    const adjustedActions = top => {
        const indexOfOpp = R.indexOf(top.node.businessId, R.keys(sortedGroupedTops));
        const allVersions = R.values(sortedGroupedTops)[indexOfOpp];
        const adjustedTop = {
            nodeId: top.nodeId || '',
            id: R.last(allVersions).top.id,
            oppId: top.oppId || '',
            top: {
                top: R.last(allVersions).top,
                responsible: R.last(allVersions).responsible,
                previousVersions: R.init(allVersions),
            },
        };
        return adjustedTop;
    };

    const versionIds = useMemo(() => R.compose(
        R.map(R.path(['top', 'id'])),
        R.propOr([], selectedOppBusinessId),
    )(sortedGroupedTops), [sortedGroupedTops, selectedOppBusinessId]);

    const allVersionsView = useMemo(() => R.compose(
        R.mergeAll,
        R.map(topId => ({
            [topId]: actionNodeView,
        })),
    )(versionIds), [versionIds]);

    const viewResult = useView(allVersionsView);

    useEffect(() => {
        if (versionIds && versionIds.length > 0) {
            const view = R.compose(
                R.mergeAll,
                R.map(topId => ({
                    [topId]: actionPullView,
                })),
            )(versionIds);
            pull(view);
        }
    }, [versionIds]);

    const sortedActionBags = useMemo(() => R.compose(
        R.sortBy(R.path(['node', 'dueDate'])),
        R.values,
        R.mergeAll,
        R.values,
        R.map(({ actions = {}, nodeId: oppId }) => R.map(actionBag => ({ ...actionBag, oppId }))(actions)),
    )(viewResult), [viewResult]);

    const adjustedActionBags = R.map(a => adjustedActions(a))(sortedActionBags);

    const setToast = useToast('scorecard');
    const toastConfig = {
        textOnStart: 'Saving data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const push = createOfflinePush(stack, setToast, toastConfig);

    const dissocAction = oppId => action => {
        dissoc(TOP_ACTION_EDGE)(oppId)(action);
        autoPush ? push() : merge();
    };

    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const projectView = constructNodeView(projectId, { node: true, rights: true, files: true });
    useEffect(() => projectId && pull(projectView), [projectId]);
    const { [projectId]: projectBag = {} } = useView(projectView);
    const user = useUserEmail();
    const canAdminProject = R.pathOr(false, ['rights', 'user', user, 'admin'], projectBag);
    const canWriteProject = R.pathOr(false, ['rights', 'user', user, 'write'], projectBag);

    const setEditMessageToast = useToast('edit-message');
    // TODO change subproject in edit modal to tops sub
    const editMessage = () => {
        setEditMessageToast('error', 'Select a subproject to edit this item...', 5000);
    };

    return (
        <>
            <div className="columns" style={{ alignItems: 'flex-start' }}>
                <div>
                    <div className="opp-headline">
                        Opportunity Scorecard:
                        {' '}
                        {selectedOppBag.node.businessId}
                    </div>
                    <ListRow
                        key={selectedOppId}
                        style={{ boxShadow: '0px 0px 10px 0px var(--shadow-color-light)' }}
                    >
                        <div className="top-search-row">
                            <TopButtonRow
                                top={adjustedOpp(selectedOppBag)}
                                stack={stack}
                                canWriteProject={canWriteProject}
                                canAdminProject={canAdminProject}
                                editMessage={editMessage}
                                subprojectId={subprojectId}
                                scorecard
                                noEdit={noEdit}
                            />
                            <ProtocolTop
                                stack={stack}
                                topId={adjustedOpp(selectedOppBag).id}
                                editable={false}
                                noButton
                                scorecard
                            />
                        </div>
                    </ListRow>
                    <div className="actions-headline">
                        Timeline
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <OppTimeline stack={stack} update={update} topId={selectedOppId} />
                    </div>
                    <div style={{
                        display: 'flex', flex: 1, justifyContent: 'center', marginTop: 12,
                    }}
                    >
                        <Button
                            onClick={push}
                            style={{ width: 200 }}
                        >
                            <FaSave size={24} color="var(--corporate-color-1)" />
                            <p style={{ fontSize: 16, marginLeft: 12, fontWeight: 'bold' }}>Save</p>
                        </Button>
                    </div>
                    {sortedActionBags.length > 0 && (
                        <>
                            <div className="actions-headline">
                                Linked actions
                            </div>
                            <OppActions
                                stack={stack}
                                actionBags={adjustedActionBags}
                                dissocAction={dissocAction}
                                canWriteProject={canWriteProject}
                                canAdminProject={canAdminProject}
                                editMessage={editMessage}
                                subprojectId={subprojectId}
                                noEdit={noEdit}
                            />
                        </>
                    )}
                </div>
            </div>
            <Toast name="scorecard" />
            <EditScorecardTopModal stack={stack} />
        </>
    );
};
