import React from 'react';
import { DASHBOARD, MAIN, OFFLINE } from '../../../common/hooks.config.new';
import { Screen } from '../../Common/Screen';
import { DashboardTopBar } from '../../Elements/new/DashboardTopBar';
import { ExistingMeetingsModal } from '../../Modals/new/ExistingMeetingsModal';
import { ContactsModal } from '../../Modals/new/ContactsModal';
import { OrganizationModal } from '../../Modals/new/OrganizationModal';
import { ProjectsModal } from '../../Modals/new/ProjectsModal';
import '../DashboardScreen.css';
import { TemplatesModal } from '../../Modals/new/TemplatesModal';
import { DashboardContent } from '../../Elements/new/DashboardContent';
import { DashboardTopCalculator } from '../../Elements/new/DashboardTopCalculator';
import { useInitProjectChoices } from '../../../hooks/new/useChoices';
import { ScoreCardModal } from '../../Modals/new/ScoreCardModal';
import { ProtocolPdfModal } from '../../Modals/new/ProtocolPdfModal';
import { FilterModal } from '../../Modals/new/FilterModal';
import { Toast } from '../../Elements/new/Toast';
import { Loader } from '../../Elements/new/Loader';
import { useModalState } from '../../../hooks/new/useModal';
import { ReminderModal } from '../../Modals/new/ReminderModal';
import { ProjectOppsModal } from '../../Modals/new/ProjectOppsModal';
import { ProjectActionsModal } from '../../Modals/new/ProjectActionsModal';

export const DashboardScreen = () => {
    const stack = [MAIN, OFFLINE, DASHBOARD];
    const [isLoading] = useModalState('loader');
    return (
        <>
            <Screen
                topbar={(
                    <DashboardTopBar stack={stack} />
                )}
            >
                {isLoading
                    ? <Loader />
                    : <DashboardContent stack={stack} />}
            </Screen>
            <DashboardTopCalculator stack={stack} />
            <ProjectChoicesContainer stack={stack} />
            <ExistingMeetingsModal stack={stack} />
            <TemplatesModal stack={stack} />
            <OrganizationModal stack={stack} />
            <ProjectsModal stack={stack} />
            <ContactsModal stack={stack} />
            <ScoreCardModal stack={stack} autoPush />
            <ProtocolPdfModal stack={stack} />
            <FilterModal stack={stack} />
            <ReminderModal stack={stack} />
            <ProjectOppsModal stack={stack} />
            <ProjectActionsModal stack={stack} />
            <Toast name="offlineSync" />
        </>
    );
};

const ProjectChoicesContainer = ({ stack }) => {
    useInitProjectChoices(stack);

    return null;
};
