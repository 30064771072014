import * as R from 'ramda';
import React from 'react';
import { ListRow } from '../../Common/ListRow';
import { ProtocolTop } from './ProtocolTop';
import { TopButtonRow } from './TopButtonRow';

export const OppTops = ({
    stack,
    opps,
    assocAction,
    noEdit,
}) => (
    <div>
        {R.map(opp => (
            <ListRow
                key={opp.id}
                style={{ boxShadow: '0px 0px 10px 0px var(--shadow-color-light)' }}
            >
                <div className="top-search-row">
                    <TopButtonRow
                        stack={stack}
                        assocAction={assocAction}
                        opp={opp}
                        top={opp}
                        noEdit={noEdit}
                    />
                    <ProtocolTop
                        stack={stack}
                        topId={opp.id}
                        editable={false}
                        noButton
                        scorecard
                    />
                </div>
            </ListRow>
        ))(opps)}
    </div>
);
