import React, { memo } from 'react';
import * as R from 'ramda';
import { _Top } from './Top';
import { Button } from '../../Common/Controls';
import { useModal } from '../../../hooks/new/useModal';

const _DashboardTop = ({
    stack,
    meetingId,
    topId,
    protocolId,
}) => {
    const openMeetingPdfModal = useModal('protocol-pdf');
    return (
    // eslint-disable-next-line react/jsx-pascal-case
        <_Top
            noButton
            stack={stack}
            topId={topId}
            editable={false}
            renderPreviousControls={pv => (
                <Button
                    style={{ width: 130, fontSize: 'var(--font-size-small)', justifyContent: 'end' }}
                    onClick={() => openMeetingPdfModal(pv.meetingId)}
                >
                    Protocol
                    {' '}
                    {pv.protocolId}
                </Button>
            )}
            renderLatestControls={() => (
                <Button
                    style={{ width: 130, fontSize: 'var(--font-size-small)', justifyContent: 'end' }}
                    onClick={() => openMeetingPdfModal(meetingId)}
                >
                    Protocol
                    {' '}
                    {protocolId}
                </Button>
            )}
        />
    );
};

export const DashboardTop = memo(_DashboardTop, R.equals);
