import * as R from 'ramda';
import React, { memo } from 'react';
import { BiLinkAlt } from 'react-icons/bi';
import { BsCardHeading } from 'react-icons/bs';
import { FaArrowDown, FaArrowUp, FaPlus } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { Button } from './Controls';
import './ListRow.css';

const _ListRow = ({
    children,
    onMove,
    onInsert,
    onRemove,
    buttonColor,
    opp,
    action,
    openScorecard,
    openProjectOpps,
    openProjectActions,
    topId,
    style,
}) => (
    <div className="list-row" style={style}>
        {(onMove || onInsert || onRemove) && (
        <div className="list-row-buttons">
            {opp && (
                <>
                    <Button
                        onClick={() => openScorecard({ id: topId })}
                        style={{ height: 30 }}
                    >
                        <BsCardHeading
                            size={22}
                            color="var(--corporate-color-1)"
                        />
                        <p style={{ marginLeft: 9 }}>Scorecard</p>
                    </Button>
                    <Button
                        onClick={() => openProjectActions(topId)}
                        style={{
                            marginLeft: 12,
                            marginRight: 24,
                            height: 30,
                        }}
                    >
                        <BiLinkAlt
                            size={24}
                            color="var(--corporate-color-1)"
                        />
                        <p style={{ marginLeft: 9 }}>Link actions</p>
                    </Button>
                </>
            )}
            {action && (
            <Button
                onClick={() => openProjectOpps(topId)}
                style={{ marginRight: 24, height: 30 }}
            >
                <BiLinkAlt
                    size={24}
                    color="var(--corporate-color-1)"
                />
                <p style={{ marginLeft: 9 }}>Link opportunities</p>
            </Button>
            )}
            {onMove && (
            <Button
                style={{
                    right: '120px',
                    color: buttonColor,
                    height: 30,
                }}
                onClick={() => onMove(-1)}
            >
                <FaArrowUp />
            </Button>
            )}
            {onMove && (
            <Button
                style={{
                    right: '84px',
                    color: buttonColor,
                    height: 30,
                }}
                onClick={() => onMove(1)}
            >
                <FaArrowDown />
            </Button>
            )}
            {onInsert && (
            <Button
                style={{
                    right: '48px',
                    color: buttonColor,
                    height: 30,
                }}
                onClick={onInsert}
            >
                <FaPlus />
            </Button>
            )}
            {onRemove && (
            <Button
                onClick={onRemove}
                style={{
                    right: '48px',
                    color: buttonColor,
                    height: 30,
                }}
            >
                <ImCross size={10} />
            </Button>
            )}
        </div>
        )}
        {children}
    </div>
);

export const ListRow = memo(_ListRow, R.equals);
