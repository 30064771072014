import { gotMiddleware, gotReducer, gotPersistTransform } from '@gothub-team/got';
import { gotReducer as got2Reducer } from '@gothub-team/got-react';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
// import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { modalsReducer } from './modals';
import { toastsReducer } from './toasts';

const rootReducer = combineReducers({
    got: gotReducer,
    got2: got2Reducer,
    modals: modalsReducer,
    toasts: toastsReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    // whitelist: [],
    whitelist: ['got', 'got2'],
    transforms: [gotPersistTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistedReducer,
    applyMiddleware(
        //  logger,
        gotMiddleware,
    ),
);
export const persistor = persistStore(store);
