import * as R from 'ramda';
import React, {
    useCallback, useEffect, useMemo,
} from 'react';
import { filterTops } from '../../../common/filteredtops.util';
import { constructNodeView } from '../../../common/got-adjunct';
import {
    CHART_TOPS_KEY,
    DASHBOARD_FILTER_KEY,
    DASHBOARD_STATE_KEY,
    PROJECT_ID_KEY,
    SEARCH_STRING_KEY,
    SORTED_GROUPED_TOPS_KEY,
    store,
    SUBPROJECT_ID_KEY,
    useGraph,
} from '../../../common/hooks.config.new';
import { EditTopModal } from '../../Modals/new/EditTopModal';
import { useUserEmail } from '../../../hooks/new/useUserEmail';
import { ListRow } from '../../Common/ListRow';
import { ChartsArea } from './ChartsArea';
import { DashboardTop } from './DashboardTop';
import { useContact } from '../../../hooks/new/useContact';
import { TopButtonRow } from './TopButtonRow';
import { Toast } from './Toast';
import { useToast } from '../../../hooks/new/useToast';
import { filterCriticalOpps } from '../../../common/filterCriticalOpps';

export const TopsAndChartsArea = ({
    stack,
}) => {
    const {
        useVar, useView, pull,
    } = useGraph(...stack);

    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [{ tops: sortedGroupedTops = {} } = {}] = useVar(SORTED_GROUPED_TOPS_KEY);
    const topRows = useMemo(() => R.compose(
        R.sortBy(R.prop('businessId')),
        R.values,
        R.map(entries => ({
            ...R.last(entries),
            previousVersions: R.dropLast(1, entries),
        })),
    )(sortedGroupedTops), [sortedGroupedTops]);

    const [dashboardState] = useVar(DASHBOARD_STATE_KEY);
    const [filter] = useVar(DASHBOARD_FILTER_KEY);
    const [searchString] = useVar(SEARCH_STRING_KEY);

    const [projectId] = useVar(PROJECT_ID_KEY);
    const user = useUserEmail();

    const { contact } = useContact(stack, projectId, user);
    const userName = R.pathOr(user, ['node', 'name'], contact);

    const projectView = constructNodeView(projectId, { rights: true });
    const { [projectId]: projectBag = {} } = useView(projectView);
    const canWriteProject = R.pathOr(false, ['rights', 'user', user, 'write'], projectBag);
    const canAdminProject = R.pathOr(false, ['rights', 'user', user, 'admin'], projectBag);
    useEffect(() => projectId && pull(projectView), [projectId]);

    const { [subprojectId]: subprojectBag } = useView(constructNodeView(subprojectId));
    const subprojectBusinessId = R.pathOr('', ['node', 'businessId'], subprojectBag);

    const { searchedTops, actions, opps } = filterTops({
        filter,
        searchString,
        dashboardState,
        subprojectBusinessId,
        userName,
    })(topRows);

    return (
        <TopsAndChartsContent
            stack={stack}
            searchedTops={searchedTops}
            actions={actions}
            opps={opps}
            dashboardState={dashboardState}
            projectId={projectId}
            subprojectId={subprojectId}
            canAdminProject={canAdminProject}
            canWriteProject={canWriteProject}
            userName={userName}
        />
    );
};

const TopsAndChartsContent = ({
    stack,
    searchedTops,
    actions,
    opps,
    dashboardState,
    projectId,
    canAdminProject,
    canWriteProject,
    subprojectId,
    userName,
}) => {
    const selectTopNode = useCallback(R.prop('top'));
    const { useVar, update } = useGraph(...stack);
    const [{ tops: chartTops } = {}, _setChartTops] = useVar(CHART_TOPS_KEY);
    const setChartTops = useCallback(tops => _setChartTops({ tops }));

    useEffect(() => setChartTops(undefined), [searchedTops]);

    const notDoneActions = actions
        ? actions.filter(a => selectTopNode(a).status !== 'Done')
        : [];
    const actionsToDisplay = notDoneActions.slice(0, 19);
    const msDate = Date.now();

    // TODO check for performance
    // Push after map
    useEffect(() => {
        const openActions = actions
            ? actions.filter(a => selectTopNode(a).status === 'Open')
            : [];
        projectId && openActions.map(a => {
            const msDueDate = new Date(selectTopNode(a).dueDate).getTime();
            if (msDueDate < msDate && selectTopNode(a).status === 'Open') { update(R.assoc('status', 'Late', selectTopNode(a))); }
            return null;
        });
    }, [projectId]);

    const criticalOpps = filterCriticalOpps(stack, projectId)(opps, selectTopNode);

    const topsToDisplay = chartTops || searchedTops || actionsToDisplay;
    const setEditMessageToast = useToast('edit-message');
    // TODO change subproject in edit modal to tops sub
    const editMessage = () => {
        setEditMessageToast('error', 'Select a subproject to edit this item...', 5000);
    };

    const onEditTopModalSave = useCallback((oldTopId, newTopId) => {
        const newTop = store.getNode(...stack)(newTopId);
        const topIndex = chartTops && R.findIndex(R.pathEq(['top', 'id'], oldTopId), chartTops);
        const newTopObj = { id: newTop.id, top: newTop, previousVersions: [] };
        if (chartTops && topIndex !== -1) {
            setChartTops(R.update(topIndex, newTopObj, chartTops));
        }
    }, [chartTops]);

    return (
        <>
            {projectId
                ? (
                    <>
                        {!chartTops && opps && actions
            && (
                <>
                    <ChartsArea
                        stack={stack}
                        opps={opps}
                        criticalOpps={criticalOpps}
                        actions={actions}
                        setChartOrKeywordTops={setChartTops}
                        dashboardState={dashboardState}
                        fnSelectNode={selectTopNode}
                    />
                </>
            )}
                        <div className={!chartTops && !searchedTops && 'dashboard-area'}>
                            { R.map(({
                                top, responsible, previousVersions, meetingId, protocolId,
                            }) => (
                                <ListRow
                                    key={top.id}
                                >
                                    <div className="top-search-row">
                                        <TopButtonRow
                                            top={top}
                                            responsible={responsible}
                                            previousVersions={previousVersions}
                                            canAdminProject={canAdminProject}
                                            canWriteProject={canWriteProject}
                                            subprojectId={subprojectId}
                                            stack={stack}
                                            editMessage={editMessage}
                                            userName={userName}
                                        />
                                        <DashboardTop
                                            noButton
                                            stack={stack}
                                            meetingId={meetingId}
                                            topId={R.prop('id', top)}
                                            protocolId={protocolId}
                                        />
                                    </div>
                                </ListRow>
                            ))(topsToDisplay) }
                        </div>
                    </>
                )
                : (
                    <p className="dashboard-message">
                        Please select or create an organization and a project
                    </p>
                )}
            <Toast name="edit-message" />
            <EditTopModal
                stack={stack}
                onSave={onEditTopModalSave}
            />

        </>
    );
};
