import React from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { usePreviousVersions } from '../../../hooks/new/useTopHistories';
import { useTopResponsible } from '../../../hooks/new/useTopResponsible';
import { formatShortDate } from '../../../common/util';
import { useProjectCurrency } from '../../../hooks/new/useChoices';

export const ReadingViewTop = ({
    stack, top, hierarchy, i,
}) => {
    const previousVersions = usePreviousVersions(stack, R.prop('businessId', top));
    const [responsible] = useTopResponsible(stack, top.id);
    const currency = useProjectCurrency(stack);
    const formato = val => new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency,
    }).format(val);
    const topType = top.type;

    return (
        <div style={{ marginTop: 6 }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {R.compose(RA.mapIndexed((pv, index) => (
                    <TopSwitch
                        top={pv.top}
                        responsible={pv.responsible}
                        formato={formato}
                        hierarchy={index === 0 ? hierarchy : ''}
                        topType={index === 0 ? topType : ''}
                        index={index}
                        versionDate={index === 0 ? pv.createdDate : pv.editedDate}
                        author={pv.author && pv.author.name}
                    />
                )),
                R.dropLast(1))(previousVersions)}
            </div>
            <TopSwitch
                top={top}
                hierarchy={previousVersions.length < 2 ? hierarchy : ''}
                responsible={responsible}
                formato={formato}
                topType={previousVersions.length < 2 ? topType : ''}
                i={i}
            />
        </div>
    );
};

const TopSwitch = ({
    top,
    hierarchy,
    responsible,
    formato,
    topType,
    index,
    author,
    versionDate,
    i,
}) => R.propOr(
    null,
    top.type,
)({
    'a': <TopA
        top={top}
        hierarchy={hierarchy}
        responsible={responsible}
        topType={topType}
        index={index}
        author={author}
        versionDate={versionDate}
    />,
    'o': <TopO
        top={top}
        hierarchy={hierarchy}
        responsible={responsible}
        formato={formato}
        topType={topType}
        index={index}
        author={author}
        versionDate={versionDate}
    />,
    'r': <TopR
        top={top}
        hierarchy={hierarchy}
        responsible={responsible}
        topType={topType}
        index={index}
        author={author}
        versionDate={versionDate}
    />,
    'i': <TopI
        top={top}
        hierarchy={hierarchy}
        topType={topType}
        index={index}
        author={author}
        versionDate={versionDate}
    />,
    's': <TopS
        top={top}
        hierarchy={hierarchy}
        topType={topType}
        index={index}
        author={author}
        versionDate={versionDate}
    />,
    'd': <TopD
        top={top}
        hierarchy={hierarchy}
        topType={topType}
        index={index}
        author={author}
        versionDate={versionDate}
    />,
    '1': <Headline
        top={top}
        hierarchy={hierarchy}
        i={i}
    />,
    '2': <Headline
        top={top}
        hierarchy={hierarchy}
        i={i}
    />,
    '3': <Headline
        top={top}
        hierarchy={hierarchy}
        i={i}
    />,
});

const TopA = ({
    top,
    hierarchy,
    responsible,
    topType,
    index,
    versionDate,
    author,
}) => (
    <>
        <div style={{ display: 'flex' }}>
            <div style={{ fontSize: 18, flex: 0.5 }}>{hierarchy}</div>
            <div style={{
                display: 'flex', justifyContent: 'center', fontSize: 18, flex: 0.5,
            }}
            >
                {
                    topType.toUpperCase()
                }
            </div>
            <div style={{ flex: 0.5 }} />
            <div style={{ fontSize: 18, flex: 6, whiteSpace: 'pre-line' }}>{top.description}</div>
            <div style={{
                display: 'flex', justifyContent: 'center', fontSize: 18, flex: 1,
            }}
            >
                {formatShortDate(top.dueDate)}
            </div>
            <div style={{
                display: 'flex', fontSize: 18, flex: 1.5, justifyContent: 'flex-end',
            }}
            >
                {responsible && responsible.name}
            </div>
        </div>
        <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flex: 1,
        }}
        >
            <div style={{ display: 'flex', flex: 1.5 }} />
            <div style={{ display: 'flex', flexDirection: 'column', flex: 8.5 }}>
                <div style={{ fontSize: 18, marginTop: 3 }}>
                    Status:
                    {' '}
                    {top.status}
                </div>
                {index || index === 0
                    ? (
                        <div style={{
                            fontSize: 14, color: 'darkgray', marginTop: 6, marginBottom: 6,
                        }}
                        >
                            {index === 0 ? 'created on' : 'edited on'}
                            {' '}
                            {formatShortDate(versionDate)}
                            {' '}
                            by
                            {' '}
                            {author}
                        </div>
                    )
                    : (
                        <div style={{
                            fontSize: 14, color: 'darkgray', marginTop: 6, marginBottom: 6,
                        }}
                        >
                            {top.businessId}
                        </div>
                    )}
            </div>
        </div>
    </>
);

const TopO = ({
    top,
    hierarchy,
    responsible,
    formato,
    topType,
    index,
    versionDate,
    author,
}) => (
    <>
        <div style={{ display: 'flex' }}>
            <div style={{ fontSize: 18, flex: 0.5 }}>{hierarchy}</div>
            <div style={{
                display: 'flex', justifyContent: 'center', fontSize: 18, flex: 0.5,
            }}
            >
                {
                    topType.toUpperCase()
                }
            </div>
            <div style={{ flex: 0.5 }} />
            <div style={{ fontSize: 18, flex: 6, whiteSpace: 'pre-line' }}>{top.description}</div>
            <div style={{
                display: 'flex', fontSize: 18, flex: 2.5, justifyContent: 'flex-end',
            }}
            >
                {responsible && responsible.name}
            </div>
        </div>
        <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flex: 1,
        }}
        >
            <div style={{ display: 'flex', flex: 1.5 }} />
            <div style={{ display: 'flex', flexDirection: 'column', flex: 8.5 }}>
                <div style={{ fontSize: 18, marginTop: 3 }}>
                    Status:
                    {' '}
                    {top.status}
                </div>
                <div style={{ fontSize: 18, marginTop: 3 }}>
                    Category:
                    {' '}
                    {top.category}
                </div>
                <div style={{ fontSize: 18, marginTop: 3 }}>
                    Savings:
                    {' '}
                    {top.savings ? formato(top.savings) : '0,00 €'}
                </div>
                {index || index === 0
                    ? (
                        <div style={{
                            fontSize: 14, color: 'darkgray', marginTop: 6, marginBottom: 6,
                        }}
                        >
                            {index === 0 ? 'created on' : 'edited on'}
                            {' '}
                            {formatShortDate(versionDate)}
                            {' '}
                            by
                            {' '}
                            {author}
                        </div>
                    )
                    : (
                        <div style={{
                            fontSize: 14, color: 'darkgray', marginTop: 6, marginBottom: 6,
                        }}
                        >
                            {top.businessId}
                        </div>
                    )}
            </div>
        </div>
    </>
);

const TopI = ({
    top,
    hierarchy,
    topType,
    index,
    versionDate,
    author,
}) => (
    <>
        <div style={{ display: 'flex' }}>
            <div style={{ fontSize: 18, flex: 0.5 }}>{hierarchy}</div>
            <div style={{
                display: 'flex', justifyContent: 'center', fontSize: 18, flex: 0.5,
            }}
            >
                {
                    topType.toUpperCase()
                }
            </div>
            <div style={{ flex: 0.5 }} />
            <div style={{ fontSize: 18, flex: 6, whiteSpace: 'pre-line' }}>{top.description}</div>
            <div style={{ fontSize: 18, flex: 2.5 }} />
        </div>
        <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flex: 1,
        }}
        >
            <div style={{ display: 'flex', flex: 1.5 }} />
            <div style={{ display: 'flex', flex: 8.5 }}>
                {index || index === 0
                    ? (
                        <div style={{
                            fontSize: 14, color: 'darkgray', marginTop: 6, marginBottom: 6,
                        }}
                        >
                            {index === 0 ? 'created on' : 'edited on'}
                            {' '}
                            {formatShortDate(versionDate)}
                            {' '}
                            by
                            {' '}
                            {author}
                        </div>
                    )
                    : (
                        <div style={{
                            fontSize: 14, color: 'darkgray', marginTop: 6, marginBottom: 6,
                        }}
                        >
                            {top.businessId}
                        </div>
                    )}
            </div>
        </div>
    </>
);

const TopS = ({
    top,
    hierarchy,
    topType,
    index,
    versionDate,
    author,
}) => (
    <>
        <div style={{ display: 'flex' }}>
            <div style={{ fontSize: 18, flex: 0.5 }}>{hierarchy}</div>
            <div style={{
                display: 'flex', justifyContent: 'center', fontSize: 18, flex: 0.5,
            }}
            >
                {
                    topType.toUpperCase()
                }
            </div>
            <div style={{ flex: 0.5 }} />
            <div style={{ fontSize: 18, flex: 6, whiteSpace: 'pre-line' }}>{top.description}</div>
            <div style={{ fontSize: 18, flex: 2.5 }} />
        </div>
        <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flex: 1,
        }}
        >
            <div style={{ display: 'flex', flex: 1.5 }} />
            <div style={{ display: 'flex', flex: 8.5 }}>
                {index || index === 0
                    ? (
                        <div style={{
                            fontSize: 14, color: 'darkgray', marginTop: 6, marginBottom: 6,
                        }}
                        >
                            {index === 0 ? 'created on' : 'edited on'}
                            {' '}
                            {formatShortDate(versionDate)}
                            {' '}
                            by
                            {' '}
                            {author}
                        </div>
                    )
                    : (
                        <div style={{
                            fontSize: 14, color: 'darkgray', marginTop: 6, marginBottom: 6,
                        }}
                        >
                            {top.businessId}
                        </div>
                    )}
            </div>
        </div>
    </>
);

const TopD = ({
    top,
    hierarchy,
    topType,
    index,
    versionDate,
    author,
}) => (
    <>
        <div style={{ display: 'flex' }}>
            <div style={{ fontSize: 18, flex: 0.5 }}>{hierarchy}</div>
            <div style={{
                display: 'flex', justifyContent: 'center', fontSize: 18, flex: 0.5,
            }}
            >
                {
                    topType.toUpperCase()
                }
            </div>
            <div style={{ flex: 0.5 }} />
            <div style={{ fontSize: 18, flex: 6, whiteSpace: 'pre-line' }}>{top.description}</div>
            <div style={{ fontSize: 18, flex: 2.5 }} />
        </div>
        <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flex: 1,
        }}
        >
            <div style={{ display: 'flex', flex: 1.5 }} />
            <div style={{ display: 'flex', flex: 8.5 }}>
                {index || index === 0
                    ? (
                        <div style={{
                            fontSize: 14, color: 'darkgray', marginTop: 6, marginBottom: 6,
                        }}
                        >
                            {index === 0 ? 'created on' : 'edited on'}
                            {' '}
                            {formatShortDate(versionDate)}
                            {' '}
                            by
                            {' '}
                            {author}
                        </div>
                    )
                    : (
                        <div style={{
                            fontSize: 14, color: 'darkgray', marginTop: 6, marginBottom: 6,
                        }}
                        >
                            {top.businessId}
                        </div>
                    )}
            </div>
        </div>
    </>
);

const TopR = ({
    top,
    hierarchy,
    topType,
    index,
    versionDate,
    author,
}) => (
    <>
        <div style={{ display: 'flex' }}>
            <div style={{ fontSize: 18, flex: 0.5 }}>{hierarchy}</div>
            <div style={{
                display: 'flex', justifyContent: 'center', fontSize: 18, flex: 0.5,
            }}
            >
                {
                    topType.toUpperCase()
                }
            </div>
            <div style={{ flex: 0.5 }} />
            <div style={{ fontSize: 18, flex: 6, whiteSpace: 'pre-line' }}>{top.description}</div>
            <div style={{
                display: 'flex', fontSize: 18, flex: 2.5, justifyContent: 'flex-end',
            }}
            >
                Responsible
            </div>
        </div>
        <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flex: 1,
        }}
        >
            <div style={{ display: 'flex', flex: 1.5 }} />
            <div style={{ display: 'flex', flex: 8.5 }}>
                {index || index === 0
                    ? (
                        <div style={{
                            fontSize: 14, color: 'darkgray', marginTop: 6, marginBottom: 6,
                        }}
                        >
                            {index === 0 ? 'created on' : 'edited on'}
                            {' '}
                            {formatShortDate(versionDate)}
                            {' '}
                            by
                            {' '}
                            {author}
                        </div>
                    )
                    : (
                        <div style={{
                            fontSize: 14, color: 'darkgray', marginTop: 6, marginBottom: 6,
                        }}
                        >
                            {top.businessId}
                        </div>
                    )}
            </div>
        </div>
    </>
);

const Headline = ({
    top,
    hierarchy,
    i,
}) => (
    <div style={{ display: 'flex', borderTop: top.type === '1' && i > 0 && '1px solid black' }}>
        <div style={{ fontSize: 18, flex: 0.5, fontWeight: 'bold' }}>{hierarchy}</div>
        <div style={{ fontSize: 18, flex: 1 }} />
        <div style={{ fontSize: 18, flex: 6, fontWeight: 'bold' }}>{top.description}</div>
        <div style={{ fontSize: 18, flex: 2.5 }} />
    </div>
);
