import { newId } from '@gothub-team/got';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { ImAttachment } from 'react-icons/im';
import {
    useGraph,
} from '../../../common/hooks.config.new';
import { useModalState } from '../../../hooks/new/useModal';
import { Button } from '../../Common/Controls';
import { FileField } from '../../Common/Fields';
import { Modal } from '../../Common/Modal';

const FILE_PROP_NAME = 'file';

export const AttachmentsModal = ({
    stack,
    fromId,
    edgeTypes,
    inheritFromId,
}) => {
    const [isOpen] = useModalState('attachments');

    const {
        push,
        pull,
        useView,
        inheritRights,
        add,
        remove,
        setFile,
    } = useGraph(...stack);
    const attachmentsView = {
        [fromId]: {
            edges: {
                [edgeTypes]: {
                    as: 'attachments',
                    include: {
                        node: true,
                        edges: true,
                        files: true,
                    },
                },
            },
        },
    };

    const {
        [fromId]: {
            attachments: attachmentBags,
        },
    } = useView(attachmentsView);

    useEffect(() => {
        isOpen && pull(attachmentsView);
    }, [isOpen, fromId]);

    const addAttachments = async files => {
        files.forEach(file => {
            const attachmentId = newId();
            add(edgeTypes)(fromId)({ id: attachmentId, filename: file.name });
            setFile(attachmentId)(FILE_PROP_NAME, file.name, file);
            inheritRights(attachmentId)(inheritFromId || fromId);
        });

        const { uploads } = await push();
        uploads.subscribe({
            next: () => {},
            error: action => { throw new Error(action); },
            complete: () => {
                pull(attachmentsView);
            },
        });
        uploads.start();
    };

    return (
        <Modal
            name="attachments"
            style={{
                width: '50vw',
                left: '25vw',
            }}
            topbar={(
                <>
                    <ImAttachment size={20} color="var(--corporate-color-7)" />
                </>
            )}
        >
            <div className="columns">
                <div>
                    <FileField
                        label="Click or drop your files here..."
                        onChange={addAttachments}
                    />
                    <div
                        className="attachments-container"
                        style={{ marginBottom: 0 }}
                    >
                        {
                            R.map(({ node: attachment, files }) => (
                                <div className="attachment" key={attachment.id}>
                                    <Attachment
                                        attachment={attachment}
                                        files={files}
                                    />
                                    <Button onClick={() => {
                                        remove(edgeTypes)(fromId)(attachment);
                                        push();
                                    }}
                                    >
                                        <FaRegTrashAlt />
                                    </Button>
                                </div>
                            ))(R.values(attachmentBags))
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const Attachment = ({ attachment, files }) => {
    const url = R.pathOr('', [FILE_PROP_NAME, 'url'], files);
    const filename = R.propOr('', 'filename', attachment);
    return (
        <div>
            <a href={url} target="_blank" rel="noreferrer" download={false}>
                {filename}
            </a>
        </div>
    );
};
