/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './Controls.css';

export const Button = ({ children, ...props }) => (
    <button tabIndex="-1" {...props}>
        {children}
    </button>
);

export const Checkbox = ({
    label, value, onChange, className, disabled, style, labelSize,
}) => (
    <div
        className={`checkbox${className ? ` ${className}` : ''}`}
        onClick={() => onChange && onChange(!value)}
        style={{ ...style, display: 'flex' }}
    >
        <input
            disabled={disabled}
            type="checkbox"
            tabIndex="-1"
            checked={!!value}
            onChange={() => { }}
            onClick={e => {
                e.stopPropagation();
                onChange && onChange(!value);
            }}
        />
        <div style={{ fontSize: labelSize }} className="label">{label}</div>
    </div>
);
