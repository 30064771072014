import { Util } from '@gothub-team/got-react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useEffect, useMemo } from 'react';
import {
    GROUPED_TOPS_KEY,
    MEETING_ATTENDEE_EDGE,
    MEETING_TOP_EDGE,
    PROJECT_ID_KEY,
    PROJECT_SUBPROJECT_EDGE,
    SORTED_GROUPED_TOPS_KEY,
    store,
    SUBPROJECT_MEETING_EDGE,
    TOP_RESPONSIBLE_EDGE,
    useGraph,
} from '../../../common/hooks.config.new';
import { getTopInfo } from '../../../common/topinfo.util';
import { useModalState } from '../../../hooks/new/useModal';

const constructProjectTopsView = projectId => projectId
    ? {
        [projectId]: {
            as: 'project',
            include: {
                node: true,
            },
            edges: {
                [PROJECT_SUBPROJECT_EDGE]: {
                    as: 'subprojects',
                    include: {
                        edges: true,
                        node: true,
                    },
                    edges: {
                        [SUBPROJECT_MEETING_EDGE]: {
                            as: 'meetings',
                            include: {
                                edges: true,
                                node: true,
                            },
                            edges: {
                                [MEETING_TOP_EDGE]: {
                                    as: 'tops',
                                    include: {
                                        node: true,
                                        edges: true,
                                        metadata: true,
                                    },
                                    edges: {
                                        [TOP_RESPONSIBLE_EDGE]: {
                                            as: 'responsibles',
                                            include: {
                                                node: true,
                                                edges: true,
                                            },
                                        },
                                    },
                                },
                                [MEETING_ATTENDEE_EDGE]: {
                                    as: 'attendees',
                                    include: {
                                        node: true,
                                        edges: true,
                                        metadata: true,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    }
    : {};

const groupTopsByBID = (projectBag = {}) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [getResult, setResult] = Util.useResult({});
    const { node: project } = projectBag;

    R.map(
        ({ node: subproject, meetings }) => {
            const dashboardMeetings = R.filter(m => !m.node.submitted
                ? m.node.submitted === undefined
                : m.node.submitted === true)(R.values(meetings));
            return (R.map(
                ({ node: meeting, attendees = {}, tops }) => R.map(
                    ({ node: top, responsibles }) => {
                        const topInfo = getTopInfo({
                            project,
                            subproject,
                            meeting,
                            attendees,
                            top,
                            responsibles,
                        });

                        const businessId = R.prop('businessId', top);
                        return topInfo && setResult(R.assocPath([businessId, top.id], topInfo));
                    },
                )(tops || {}),
            )(dashboardMeetings || {}));
        }, // Story: Filter Draft Meetings
    )(projectBag.subprojects || {});

    return getResult();
};

export const DashboardTopCalculator = ({ stack }) => {
    const { useVar, useView, pull } = useGraph(...stack);

    const [projectId] = useVar(PROJECT_ID_KEY);
    const [isLoading, stopLoading] = useModalState('loader');

    // get all project tops
    const projectTopsView = constructProjectTopsView(projectId);
    useEffect(() => {
        projectId && pull(projectTopsView).then(() => {
            isLoading && stopLoading();
        });
    }, [projectId]);
    const { project: projectBag } = useView(projectTopsView);

    // group all project tops
    const groupedTops = useMemo(() => groupTopsByBID(projectBag), [projectBag]);
    useEffect(() => {
        store.setVar(R.last(stack))(GROUPED_TOPS_KEY, { tops: groupedTops });
    }, [groupedTops]);

    // sort all project tops versions
    const sortedGroupedTops = useMemo(() => R.map(
        R.compose(
            R.sortBy(
                R.compose(
                    entry => R.propOr(false, 'meetingDate', entry)
                                || R.propOr(false, 'modifiedDate', entry)
                                || R.propOr(false, 'createdDate', entry),
                ),
            ),
            R.values(),
        ),
    )(groupedTops), [groupedTops]);
    useEffect(() => {
        store.setVar(R.last(stack))(SORTED_GROUPED_TOPS_KEY, { tops: sortedGroupedTops });
        if (isLoading && RA.isNotNilOrEmpty(sortedGroupedTops)) {
            stopLoading();
        }
    }, [sortedGroupedTops]);

    return null;
};
