import React, { useState } from 'react';
import * as R from 'ramda';
import { SiMarketo } from 'react-icons/si';
import { RiChatSmileFill } from 'react-icons/ri';
import { AiOutlineSafety, AiOutlineUnorderedList } from 'react-icons/ai';
import { IoMdPricetag } from 'react-icons/io';
import { Button } from '../../Common/Controls';
import { Screen } from '../../Common/Screen';
import './LandingPage.css';
import MainPage from './Pages/MainPage';
import SolutionsPage from './Pages/SolutionsPage';
import ContactPage from './Pages/ContactPage';
import ImprintPage from './Pages/ImprintPage';
import logo from '../../../logos/pclogo.svg';
import PrivacyPage from './Pages/PrivacyPage';

function LandingPage() {
    const [page, setPage] = useState('main');

    const PageSwitcher = () => R.propOr(
        <MainPage setPage={setPage} />,
        page,
    )({
        'main': <MainPage setPage={setPage} />,
        'solutions': <SolutionsPage setPage={setPage} />,
        'contact': <ContactPage setPage={setPage} />,
        'imprint': <ImprintPage setPage={setPage} />,
        'privacy': <PrivacyPage setPage={setPage} />,
    });

    const header = (
        <div className="header">
            <Button
                style={{ color: 'var(--corporate-color-7)' }}
                onClick={() => setPage('main')}
            >
                <img alt="logo" src={logo} style={{ width: 200 }} />
            </Button>
            <div style={{ display: 'flex' }}>
                <Button
                    onClick={() => setPage('main')}
                    style={{ color: 'white' }}
                >
                    <SiMarketo className="header-footer-solution-icon" />
                    <p className="header-footer-button-text">Lösungen</p>
                </Button>
                <Button
                    onClick={() => setPage('solutions')}
                    style={{ color: 'white', marginLeft: 48 }}
                >
                    <IoMdPricetag className="header-footer-solution-icon" />
                    <p className="header-footer-button-text">Preise</p>
                </Button>
                {/* <Button
                    onClick={() => setPage('contact')}
                    style={{ color: 'white', marginLeft: 48 }}
                >
                    <BiLogInCircle className="header-footer-login-icon" />
                    <p className="header-footer-button-text">Login</p>
                </Button> */}
            </div>
        </div>
    );

    const footer = (
        <div className="footer">
            <div style={{ display: 'flex', minWidth: 500 }}>
                <Button
                    onClick={() => setPage('main')}
                    style={{ color: 'white' }}
                >
                    <SiMarketo className="header-footer-solution-icon" />
                    <p className="header-footer-button-text">Lösungen</p>
                </Button>
                <Button
                    onClick={() => setPage('solutions')}
                    style={{ color: 'white', marginLeft: 48 }}
                >
                    <IoMdPricetag className="header-footer-solution-icon" />
                    <p className="header-footer-button-text">Preise</p>
                </Button>
                <Button
                    onClick={() => setPage('contact')}
                    style={{ color: 'white', marginLeft: 48 }}
                >
                    <RiChatSmileFill className="header-footer-solution-icon" />
                    <p className="header-footer-button-text">Kontakt</p>
                </Button>
                {/* <Button
                    onClick={() => {}}
                    style={{ color: 'white', marginLeft: 48 }}
                >
                    <BiLogInCircle className="header-footer-login-icon" />
                    <p className="header-footer-button-text">Login</p>
                </Button> */}
            </div>
            <div style={{ display: 'flex' }}>
                <Button
                    onClick={() => setPage('privacy')}
                    style={{ color: 'white' }}
                >
                    <AiOutlineSafety className="header-footer-login-icon" />
                    <p className="header-footer-button-text">Datenschutz</p>
                </Button>
                <Button
                    onClick={() => setPage('imprint')}
                    style={{ color: 'white', marginLeft: 48 }}
                >
                    <AiOutlineUnorderedList className="header-footer-login-icon" />
                    <p className="header-footer-button-text">Impressum</p>
                </Button>
            </div>
        </div>
    );

    return (
        <Screen topbar={header} lpheight="var(--height)">
            <>
                <PageSwitcher />
                {footer}
            </>
        </Screen>
    );
}

export default LandingPage;
