export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || '';
export const API_DOMAIN_2 = process.env.REACT_APP_API_DOMAIN_2 || '';
export const MEDIA_DOMAIN = process.env.REACT_APP_MEDIA_DOMAIN || '';
export const BACKEND_API_DOMAIN = process.env.REACT_APP_BACKEND_API_DOMAIN || '';
export const APPLICATION_ROOT_NODE = process.env.REACT_APP_APPLICATION_ROOT_NODE || '';
export const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID || '';
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '';

export const defaultEmailBody = (name, date, keeper) => `Dear all,
 
Thank you for your participation in the meeting ${name} on ${date}.
Please find attached the corresponding minutes.
 
In case of questions or feedback please feel free to get back to me.
 
Best regards,

${keeper}
`;

export const EDIT_VIEW = 'edit';
export const EDIT_OFFLINE_VIEW = 'edit-offline';
