import * as R from 'ramda';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { useNode } from '../../hooks/new/useNode';
import './Fields.css';

const defaultDropdownStyles = {
    control: base => ({
        ...base,
        '&:hover': {
            borderColor: 'tranparent',
        },
        'borderColor': 'transparent',
        'cursor': 'pointer',
        'borderRadius': 'var(--space-small)',
        'minHeight': '34px',
        'height': '34px',
    }),
    valueContainer: provided => ({
        ...provided,
        'height': '34px',
        'padding': '0',
        'paddingLeft': '6px',
    }),
    indicatorsContainer: provided => ({
        ...provided,
        height: '34px',
    }),
    input: provided => ({
        ...provided,
        margin: '0px',
    }),
    singleValue: (provided, state) => (
        {
            ...provided,
            color: state.data.value ? 'black' : '#757575',
        }),
};

export const ResponsibleDropdownField = ({
    stack,
    label,
    value,
    editable = true,
    options,
    onChange,
    className = '',
    customStyles,
    noBottomLabel,
}) => {
    const { node } = useNode(stack, value);

    if (editable && onChange) {
        const selectedOption = R.compose(
            R.defaultTo({ label: editable && onChange ? `${label}...` : '', value: undefined }),
            R.find(R.propEq('value', value)),
        )(options);

        return (
            <Dropdown
                label={label}
                selectedOption={selectedOption}
                options={options}
                onChange={onChange}
                className={className}
                customStyles={customStyles}
                noBottomLabel={noBottomLabel}
            />
        );
    }

    return (
        <ValueDisplay
            label={label}
            value={node.name}
            className={className}
            noBottomLabel={noBottomLabel}
        />
    );
};

export const DropdownField = ({
    label,
    value,
    editable = true,
    options,
    onChange,
    className = '',
    customStyles,
    noBottomLabel,
}) => {
    if (editable && onChange) {
        const selectedOption = R.compose(
            R.defaultTo({ label: editable && onChange ? `${label}...` : '', value: undefined }),
            R.find(R.propEq('value', value)),
        )(options);

        return (
            <Dropdown
                label={label}
                selectedOption={selectedOption}
                options={options}
                onChange={onChange}
                className={className}
                customStyles={customStyles}
                noBottomLabel={noBottomLabel}
            />
        );
    }

    return (
        <ValueDisplay
            label={label}
            value={value}
            className={className}
            noBottomLabel={noBottomLabel}
        />
    );
};

const Dropdown = ({
    label,
    selectedOption,
    options,
    onChange,
    className = '',
    customStyles = defaultDropdownStyles,
    noBottomLabel,
}) => {
    const customStyle = {
        ...customStyles,
        menuPortal: base => ({
            ...base,
            zIndex: 9,
            border: 0,
        }),
    };
    return (
        <div className={`field dropdown-field ${className} ${selectedOption.value ? 'selected' : ''}`}>
            <Select
                styles={customStyle}
                menuPortalTarget={document.body}
                value={selectedOption}
                options={options}
                onChange={onChange}
                components={{
                    IndicatorSeparator: () => null,
                }}
            />
            {!noBottomLabel && <div className="label">{label}</div>}
        </div>
    );
};

const ValueDisplay = ({
    label = '',
    value,
    className = '',
    noBottomLabel,
}) => (
    <div className={`field dropdown-field ${className} ${value ? 'selected' : ''}`}>
        <div style={{ cursor: 'default' }}>{value}</div>
        {!noBottomLabel && <div className="label">{label}</div>}
    </div>
);
