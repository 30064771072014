import axios from 'axios';
import { useState } from 'react';
import { BACKEND_API_DOMAIN } from '../common/config';

export const useSendContactForm = () => {
    const [contactForm, setContactForm] = useState({
        sender: {
            name: '',
            email: '',
            phone: '',
        },
        body: '',
        privacyAccepted: false,
    });
    const send = async () => {
        await axios.post(
            `https://${BACKEND_API_DOMAIN}/send-contact`,
            contactForm,
        );
    };
    return [
        contactForm,
        setContactForm,
        send,
    ];
};
