import React, { useEffect } from 'react';
import { BsCloudDownload } from 'react-icons/bs';
import { AiOutlineFileExcel } from 'react-icons/ai';
import {
    GiCombinationLock, GiBiceps, GiIceberg, GiLightBulb, GiSherlockHolmes, GiCoffeeCup,
} from 'react-icons/gi';
import { Button } from '../../../Common/Controls';
import '../LandingPage.css';
import logo from '../../../../logos/pclogo.png';
import welcomeMain from '../Imgs/main.png';
import createProject from '../Imgs/01_Projektanlage.jpg';
import protocolScreen from '../Imgs/02_Protokollmaske.jpg';
import exportTops from '../Imgs/03_Export.jpg';
import dashboard from '../Imgs/04_Dashboard.jpg';
import filterTops from '../Imgs/05_Filteransicht.jpg';
import dashboardOverdue from '../Imgs/06_Dashboard Overdue.jpg';

const MainPage = ({ setPage }) => {
    useEffect(() => [window.scrollTo(0, 0)], []);
    const mainImage = (
        <div className="hero-section">
            <img
                className="hero_section_img"
                style={{ width: '100%' }}
                alt="welcomeMain"
                src={welcomeMain}
            />
            <div>
                <p className="image-text">
                    Projekte professionell ins Ziel bringen mit
                </p>
            </div>
            <img
                alt="logo"
                src={logo}
                className="image-logo"
            />
        </div>
    );

    const infoOverview = (
        <div className="content-box">
            <p className="content-box-headline">
                Schon wieder ein neues Projektmanagementtool?
            </p>
            <p style={{ marginTop: 72, width: '90%' }} className="p-lp">
                Ganz gleich welche Projektmanagement-Methodik gerade en-vogue ist – ob Scrum,
                klassischer Wasserfall, usw. – letztlich entscheiden effiziente Meetings über den Projekterfolg
                und deren Dokumentation sowie die Sicherung von Projektwissen sind wesentliche Säulen eines
                professionellen Projektmanagements. Erstaunlicherweise werden Projekttermine aber nur sehr selten
                einheitlich und transparent verfolgt. In den meisten Projektmanagementtools fehlt gerade
                diese Funktionalität sogar. procubion schließt diese Lücke durch eine unkomplizierte Projekt-
                und Terminverwaltung mit einfacher Protokollierung und verbindlichem Aufgabentracking.
            </p>
            <div className="box-row">
                <div className="row-column" style={{ width: '30%', minWidth: 'var(--row-element-min-width-1)', marginTop: 72 }}>
                    <GiCoffeeCup size={80} color="var(--corporate-color-4)" />
                    <p className="content-box-column-headline" style={{ marginBottom: 36 }}>
                        unkompliziert
                    </p>
                    <p className="p-lp">
                        procubion ermöglicht eine einfache Anlage und Pflege
                        von Projekten und Terminen. Zu jedem Termin wird über
                        eine intuitive Protokollmaske der Inhalt erfasst
                        (Information, Feststellung, Entscheidung, Opportunity, Action),
                        in einheitlichem pdf-Format an die Beteiligten versendet
                        und auswertbar in einer Datenbank gespeichert.
                    </p>
                </div>
                <div className="row-column" style={{ width: '30%', minWidth: 'var(--row-element-min-width-1)', marginTop: 72 }}>
                    <GiIceberg size={80} color="var(--corporate-color-4)" />
                    <p className="content-box-column-headline" style={{ marginBottom: 36 }}>
                        transparent
                    </p>
                    <p className="p-lp">
                        Protokolle enthalten alle Informationen über Projektfortschritt
                        und Weichenstellungen. Alle Projektbeteiligten können überall
                        und jederzeit auf ihre Projekte zugreifen, die Termininhalte
                        nachvollziehen und offene Aufgaben verwalten. Gleichzeitig ist
                        auch in einem Streitfall problemlos belegbar, welche Information
                        zu welchem Zeitpunkt mit wem geteilt wurde und welche Entscheidung
                        wann getroffen wurde.
                    </p>
                </div>
                <div className="row-column" style={{ width: '30%', minWidth: 'var(--row-element-min-width-1)', marginTop: 72 }}>
                    <GiBiceps size={80} color="var(--corporate-color-4)" />
                    <p className="content-box-column-headline" style={{ marginBottom: 36 }}>
                        konsequent
                    </p>
                    <p className="p-lp">
                        Jeder Protokolleintrag hat eine eindeutige ID und ist im Projekt
                        abgelegt. Eine Manipulation (z.B. Löschen oder Umdatieren)
                        ist ausgeschlossen. Jede Veränderung wird revisionssicher dokumentiert.
                        Offene und überfällige Aufgaben sind sofort ersichtlich und können
                        konsequent nachverfolgt werden.
                    </p>
                </div>
            </div>
            <Button
                onClick={() => setPage('contact')}
                className="page-button"
            >
                Kontakt
            </Button>
        </div>
    );
    const infoUnkompliziert = (
        <div className="content-box" style={{ backgroundColor: 'var(--corporate-color-6)', boxShadow: '0px 0px 30px 0px var(--shadow-color-normal)' }}>
            <GiCoffeeCup size={100} color="var(--corporate-color-4)" />
            <p className="content-box-headline" style={{ marginTop: 12 }}>
                unkompliziert
            </p>
            <p className="content-box-subheadline">
                Einfache Anlage und Verwaltung aller Projekte
            </p>
            <p className="content-box-text">
                Ihr Projekt ist nicht 08/15? Dann sind Sie bei procubion
                richtig! Definieren Sie Ihre Projektstruktur mit frei
                konfigurierbaren Teilprojekten und Meetingarten (z.B.
                Steering Committees, Workshops, Arbeitssitzungen, etc.)
                in übersichtlichen Eingabemasken.
            </p>
            <img
                alt="projektanlage"
                src={createProject}
                style={{
                    borderRadius: '15px',
                    marginTop: 50,
                    width: '80%',
                }}
            />
            <p className="content-box-subheadline">
                Schlanke Protokollerstellung
            </p>
            <p className="content-box-text">
                Protokolle sind im Projektalltag schnell lästig.
                Daher muss die Protokollierung möglichst unkompliziert
                und einfach sein, ohne viel Overhead. In procubion erzeugen
                Sie wiederkehrende Protokolle im Handumdrehen aus früheren Versionen.
                Auch einzelne Protokollpunkte und Aufgaben aus vorangegangenen Terminen
                können per Klick in das neue Protokoll aufgenommen und im Status
                fortgesetzt werden. Neue Protokollpunkte werden sukzessive per Hotkeys
                eingefügt und das neue Protokoll aufgebaut. Mit einem Klick entsteht
                ein sauberes und einheitliches pdf-Dokument für den Versand und alle
                Inhalte sind eindeutig und revisionssicher in procubion abgelegt.
            </p>
            <img
                alt="protocolScreen"
                src={protocolScreen}
                style={{
                    borderRadius: '15px',
                    marginTop: 50,
                    width: '80%',
                }}
            />
            <p className="content-box-subheadline">
                Exportfunktion
            </p>
            <p className="content-box-text">
                Alle Protokoll- und Termininhalte, sowie beliebig gefilterte
                Aufgabenlisten lassen sich flexibel in CSV-Dateien exportieren
                und so in anderen Anwendungen weiterverwenden.
            </p>
            <img
                alt="exportTops"
                src={exportTops}
                style={{
                    borderRadius: '15px',
                    marginTop: 50,
                    width: '80%',
                }}
            />
            <Button
                onClick={() => setPage('contact')}
                className="page-button"
            >
                Kontakt
            </Button>
        </div>
    );
    const infoTransparent = (
        <div className="content-box">
            <GiIceberg size={100} color="var(--corporate-color-4)" />
            <p className="content-box-headline" style={{ marginTop: 12 }}>
                transparent
            </p>
            <p className="content-box-subheadline">
                Funktionales Dashboard
            </p>
            <p className="content-box-text">
                Auf einen Blick projektübergreifend die anstehenden Aufgaben
                und Deadlines sehen und priorisiert abarbeiten. procubion
                bietet übersichtliche Dashboards für einen strukturierten
                Start in den Tag – für Projektleiter sogar mit aggregierter
                Projektsicht. So sehen Sie frühzeitig was gerade ansteht und
                wo Termine gefährdet sind.
            </p>
            <img
                alt="dashboard"
                src={dashboard}
                style={{
                    borderRadius: '15px',
                    marginTop: 50,
                    width: '80%',
                }}
            />
            <p className="content-box-subheadline">
                Kontaktverwaltung
            </p>
            <p className="content-box-text">
                Pflegen Sie Ihre Projektkontakte online und sorgen Sie dafür,
                dass alle berechtigten Projektmitglieder überall und jederzeit
                Zugriff auf die aktuellen Kontaktdaten haben. Per Klick werden
                Personen zu Terminen hinzugefügt und erhalten die Protokolle
                per E-Mail. Einfacher geht es nicht.
            </p>
            <Button
                onClick={() => setPage('contact')}
                className="page-button"
            >
                Kontakt
            </Button>
        </div>
    );
    const infoKonsequent = (
        <div className="content-box" style={{ backgroundColor: 'var(--corporate-color-6)', boxShadow: '0px 0px 30px 0px var(--shadow-color-normal)' }}>
            <GiBiceps size={100} color="var(--corporate-color-4)" />
            <p className="content-box-headline" style={{ marginTop: 12 }}>
                konsequent
            </p>
            <p className="content-box-subheadline">
                Klare Aufgabenverwaltung
            </p>
            <p className="content-box-text">
                Aus den Augen, aus dem Sinn: Mit procubion unmöglich.
                Einmal im Termin vergebene Aufgaben sind mit der
                Protokollierung eindeutig und revisionssicher abgelegt.
                Kein Versand von Aufgaben- oder Offene-Punkte-Listen mehr,
                keine versehentlich geschlossenen Aufgaben. Projektleiter
                haben stets im Blick welche Aufgaben im (Teil-)Projekt
                offen oder überfällig sind. Auch Status-, Verantwortungs-
                und Deadline-Änderungen können zu jeder Zeit nachvollzogen werden.
            </p>
            <img
                alt="filterTops"
                src={filterTops}
                style={{
                    borderRadius: '15px',
                    marginTop: 50,
                    width: '80%',
                }}
            />
            <p className="content-box-subheadline">
                Verbindliche Deadline-Verfolgung
            </p>
            <p className="content-box-text">
                Durch die Änderungshistorie der vergebenen Aufgaben, ist
                jederzeit transparent, wie lange Aufgaben überfällig sind,
                aber auch wann und wie häufig sie bereits in Deadline oder
                Verantwortlichkeit geschoben wurden. So entsteht ein
                natürlicher Druck zur Bearbeitung und langes Aufschieben
                wird erschwert. Sollte das nicht reichen, hat der Projektleiter
                die ideale Informationsbasis um nachzuhaken und an den
                richtigen Stellen einzugreifen.
            </p>
            <img
                alt="dashboardOverdue"
                src={dashboardOverdue}
                style={{
                    borderRadius: '15px',
                    marginTop: 50,
                    width: '80%',
                }}
            />
            <Button
                onClick={() => setPage('contact')}
                className="page-button"
            >
                Kontakt
            </Button>
        </div>
    );
    const furtherInfo = (
        <div className="content-box" style={{ paddingTop: 28 }}>
            <div className="box-row">
                <div className="row-column" style={{ width: '15%', minWidth: 'var(--row-element-min-width-2)', marginTop: 72 }}>
                    <BsCloudDownload size={80} style={{ color: 'var(--corporate-color-4)' }} />
                    <p
                        className="content-box-column-headline"
                        style={{ marginTop: 12, marginBottom: 12, height: 100 }}
                    >
                        Echtes SaaS
                        (Software as a Service)
                    </p>
                    <p className="mobile-margin-top p-lp">
                        procubion ist plattformunabhängig, läuft ohne Installation
                        und Eingriffe in Ihre IT-Landschaft, ist immer auf dem
                        aktuellsten stand und ist überall – auch offline – verfügbar.
                    </p>
                </div>
                <div className="row-column" style={{ width: '15%', minWidth: 'var(--row-element-min-width-2)', marginTop: 72 }}>
                    <GiSherlockHolmes size={80} color="var(--corporate-color-4)" />
                    <p
                        className="content-box-column-headline"
                        style={{ marginTop: 12, marginBottom: 12, height: 100 }}
                    >
                        Revisionssicher
                    </p>
                    <p className="p-lp">
                        procubion speichert alle Projektinformationen mit einer
                        eindeutigen ID und Änderungshistorie (Datum und Nutzer).
                        So geht nichts verloren und Sie sind für jedes Audit gewappnet.
                    </p>
                </div>
                <div className="row-column" style={{ width: '15%', minWidth: 'var(--row-element-min-width-2)', marginTop: 72 }}>
                    <GiLightBulb size={80} color="var(--corporate-color-4)" />
                    <p
                        className="content-box-column-headline"
                        style={{ marginTop: 12, marginBottom: 12, height: 100 }}
                    >
                        Einfach, einheitlich, effizient
                    </p>
                    <p className="p-lp">
                        procubion erleichtert das Projektmanagement ohne viel Spielerei –
                        so geht effiziente Zusammenarbeit ganz unabhängig von Ihrer
                        Arbeitsmethodik. Sie haben den Status Ihrer Termine immer im
                        Blick und Informationen jederzeit zugänglich.
                    </p>
                </div>
                <div className="row-column" style={{ width: '15%', minWidth: 'var(--row-element-min-width-2)', marginTop: 72 }}>
                    <AiOutlineFileExcel size={80} color="var(--corporate-color-4)" />
                    <p
                        className="content-box-column-headline"
                        style={{ marginTop: 12, marginBottom: 12, height: 100 }}
                    >
                        Exportfähig
                    </p>
                    <p className="p-lp">
                        Sie benötigen die Protokollfunktion für die revisionssichere
                        Dokumentation, verfolgen Ihre Aufgaben aber lieber außerhalb
                        von procubion in Ihrem Lieblingstool? Oder Sie müssen Inhalte
                        extern zur Verfügung stellen? Kein Problem – procubion
                        exportiert Ihre Inhalte mit beliebigen Filtern in universelle csv-Dateien.
                    </p>
                </div>
                <div className="row-column" style={{ width: '15%', minWidth: 'var(--row-element-min-width-2)', marginTop: 72 }}>
                    <GiCombinationLock size={80} color="var(--corporate-color-4)" />
                    <p
                        className="content-box-column-headline"
                        style={{ marginTop: 12, marginBottom: 12, height: 100 }}
                    >
                        Zugriffskontrolle
                    </p>
                    <p className="p-lp">
                        procubion bietet eine einfache und klare Nutzerverwaltung in
                        der Sie Zugriffsrechte auf einzelne Termine, Teilprojekte
                        oder ganze Projekte erlauben können. Mit Lese- oder Schreibrechten,
                        ganz nach Ihrem Bedarf.
                    </p>
                </div>
            </div>
            <Button
                onClick={() => setPage('contact')}
                className="page-button"
            >
                Kontakt
            </Button>
        </div>
    );
    const summary = (
        <div className="content-box" style={{ backgroundColor: 'var(--corporate-color-6)', boxShadow: '0px 0px 30px 0px var(--shadow-color-normal)' }}>
            <p className="content-box-headline">
                Stehen diese Regeln auch in Ihren Meetingräumen,
                aber die Realität bleibt dahinter zurück?
            </p>
            <div className="side-shuffled-text" style={{ marginTop: 72 }}>
                <p className="emphasized-text">
                    Kein Termin ohne Agenda und Protokoll.
                </p>
            </div>
            <div className="side-shuffled-text" style={{ justifyContent: 'flex-end', marginTop: 36 }}>
                <p className="emphasized-text">
                    Ein Meeting ohne Protokoll hat nicht stattgefunden.
                </p>
            </div>
            <div className="side-shuffled-text" style={{ marginTop: 36, marginBottom: 72 }}>
                <p className="emphasized-text">
                    Keine Aufgabe ohne Verantwortlichen und Termin.
                </p>
            </div>
            <p className="content-box-text">
                Starten Sie jetzt mit procubion und bringen Sie gut
                gemeinte Vorsätze in die Realität. Gutes Projektmanagement
                braucht keine fancy Tools und keine Modebegriffe.
                Eine stringente Verfolgung Ihrer Meetings und Absprachen
                mit den daraus entstehenden Aufgaben und Opportunities
                bringt auch umfangreiche Projekte im Konzernumfeld sicher ins Ziel.
                procubion entstammt aus langjähriger Projekterfahrung in der Industrie: vielfach erprobt,
                jahrelang bewährt.
            </p>
            <Button
                onClick={() => setPage('contact')}
                className="page-button"
            >
                Kontakt
            </Button>
        </div>
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {mainImage}
            {infoOverview}
            {infoUnkompliziert}
            {infoTransparent}
            {infoKonsequent}
            {furtherInfo}
            {summary}
        </div>
    );
};

export default MainPage;
