import React from 'react';
import { RiDashboardFill, RiHomeSmileFill } from 'react-icons/ri';
import {
    FaCopy, FaFileCsv, FaFilter, FaProjectDiagram,
} from 'react-icons/fa';
import { BsPeopleFill } from 'react-icons/bs';
import { IoMdLogOut, IoMdOpen, IoMdSync } from 'react-icons/io';
import * as R from 'ramda';
import { useHistory } from 'react-router';
import { ProjectSelector } from './ProjectSelector';
import { Button } from '../../Common/Controls';
import { useModal } from '../../../hooks/new/useModal';
import { useAuth } from '../../../common/auth';
import {
    OFFLINE,
    PROTOCOL,
    SUBPROJECT_ID_KEY,
    useGraph,
} from '../../../common/hooks.config.new';
import { useNode, usePullNode } from '../../../hooks/new/useNode';
import { useUserEmail } from '../../../hooks/new/useUserEmail';
import { NewMeetingButton } from './NewMeetingButton';
import { SearchBar } from './SearchBar';
import { downloadCsv, generateCsv } from '../../../common/csv-export.util';
import { OfflineSyncer } from './OfflineSyncer';
import { useToast } from '../../../hooks/new/useToast';

export const DashboardTopBar = ({ stack }) => {
    const [, , logout] = useAuth();
    const openProjectsModal = useModal('projects');
    const openTemplatesModal = useModal('templates');
    const openOrganizationsModal = useModal('organizations');
    const openContactsModal = useModal('contacts');
    const openExistingMeetingsModal = useModal('existingMeetings');
    const openFilterModal = useModal('filter');

    // selected subproject rights
    const user = useUserEmail();

    const { useVar } = useGraph(...stack);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    usePullNode(stack, subprojectId, { rights: true });
    const subprojectBag = useNode(stack, subprojectId, { rights: true });
    const canWriteSubproject = R.pathOr(false, ['rights', 'user', user, 'write'], subprojectBag);

    const setOfflineSyncToast = useToast('offlineSync');
    const history = useHistory();

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="dashboard-screen-upper-topbar">
                <div style={{ display: 'flex', flex: '1' }}>
                    {' '}
                    <Button
                        className="dashboard-toggle"
                        onClick={() => history.push('global-dashboard')}
                    >
                        <RiDashboardFill size={20} />
                        <p style={{ marginLeft: 9 }}>Global Dashboard</p>
                    </Button>
                </div>
                <div style={{ display: 'flex', flex: '1', justifyContent: 'center' }}>
                    <ProjectSelector stack={stack} />
                </div>
                <div style={{ display: 'flex', flex: '1', justifyContent: 'flex-end' }}>
                    <OfflineSyncer
                        graphNames={[OFFLINE, PROTOCOL]}
                        onProgress={() => setOfflineSyncToast('spinner', 'Syncing offline data...')}
                        onSuccess={() => setOfflineSyncToast('success', 'Successfully saved.', 5000)}
                        onError={error => {
                            if (error.status) {
                                setOfflineSyncToast('error', error.message ? error.message : error.toString());
                            } else {
                                setOfflineSyncToast('error', 'You are offline. Please sync when back online.', 5000);
                            }
                        }}
                    >
                        <IoMdSync size={30} color="var(--color-error)" />
                    </OfflineSyncer>
                    <Button
                        onClick={logout}
                    >
                        <IoMdLogOut size={27} color="white" />
                    </Button>
                </div>
            </div>
            <div className="dashboard-screen-lower-topbar">
                <div style={{ display: 'flex', flex: '1' }}>
                    <Button
                        onClick={openOrganizationsModal}
                    >
                        <div className="button-icon">
                            <RiHomeSmileFill size={26} color="var(--corporate-color-1)" />
                            <p style={{ marginLeft: '9px' }}>Organizations</p>
                        </div>
                    </Button>
                    <Button
                        onClick={openProjectsModal}
                        style={{ marginLeft: 6 }}
                    >
                        <div className="button-icon">
                            <FaProjectDiagram size={24} color="var(--corporate-color-1)" />
                            <p style={{ marginLeft: '9px' }}>Projects</p>
                        </div>
                    </Button>
                    <Button
                        onClick={openContactsModal}
                        style={{ marginLeft: 6 }}
                    >
                        <div className="button-icon">
                            <BsPeopleFill size={25} color="var(--corporate-color-1)" />
                            <p style={{ marginLeft: '9px' }}>Contacts</p>
                        </div>
                    </Button>
                </div>
                <div style={{
                    display: 'flex',
                    flex: '1',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                >
                    <Button
                        onClick={openFilterModal}
                        style={{ marginRight: 12 }}
                    >
                        <div className="button-icon">
                            <FaFilter size={23} color="var(--corporate-color-1)" />
                            <p style={{ marginLeft: '9px' }}>Filter</p>
                        </div>
                    </Button>

                    <SearchBar
                        stack={stack}
                    />
                    <Button
                        onClick={async () => downloadCsv(await generateCsv(stack))}
                        style={{ marginLeft: 12 }}
                    >
                        <div className="button-icon">
                            <FaFileCsv size={23} color="var(--corporate-color-1)" />
                            <p style={{ marginLeft: '9px' }}>Export</p>
                        </div>
                    </Button>
                </div>
                {canWriteSubproject
                    ? (
                        <div style={{
                            display: 'flex',
                            flex: '1',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                        >
                            <Button
                                onClick={openExistingMeetingsModal}
                                style={{ marginLeft: 6 }}
                            >
                                <div className="button-icon">
                                    <IoMdOpen size={27} color="var(--corporate-color-1)" />
                                    <p style={{ marginLeft: '9px' }}>Meetings</p>
                                </div>
                            </Button>
                            <Button
                                onClick={openTemplatesModal}
                                style={{ marginLeft: 6 }}
                            >
                                <div className="button-icon">
                                    <FaCopy size={21} color="var(--corporate-color-1)" />
                                    <p style={{ marginLeft: '9px' }}>New from template</p>
                                </div>
                            </Button>
                            <NewMeetingButton stack={stack} />
                        </div>
                    )
                    : <div style={{ display: 'flex', flex: '1', justifyContent: 'flex-end' }} />}
            </div>
        </div>
    );
};
