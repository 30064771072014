/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import * as R from 'ramda';
import { BiLinkAlt } from 'react-icons/bi';
import {
    PROJECT_ACTIONS_MODAL,
    PROJECT_ID_KEY, store, TOP_ACTION_EDGE, useGraph,
} from '../../../common/hooks.config.new';
import { useModalState } from '../../../hooks/new/useModal';
import { Modal } from '../../Common/Modal';
import { OppTops } from '../../Elements/new/OppTops';
import { useToast } from '../../../hooks/new/useToast';
import { Toast } from '../../Elements/new/Toast';
import { useProjectActions } from '../../../hooks/new/useProjectActions';
import { createOfflinePush } from '../../../common/got-adjunct';

export const ProjectActionsModal = ({
    stack, noEdit,
}) => {
    const [isOpen] = useModalState('project-actions');
    return (
        <Modal
            name="project-actions"
            style={{
                height: '80vh',
                top: '10vh',
                width: '80vw',
                left: '10vw',
            }}
            topbar={(
                <>
                    <BiLinkAlt
                        size={24}
                        color="var(--corporate-color-7)"
                    />
                </>
            )}
        >
            {isOpen
                ? (
                    <ProjectActionsModalContent
                        stack={stack}
                        noEdit={noEdit}
                    />
                )
                : null}
        </Modal>
    );
};

const ProjectActionsModalContent = ({
    stack: parentStack, noEdit,
}) => {
    const stack = R.append(PROJECT_ACTIONS_MODAL, parentStack);
    const { assoc } = useGraph(...stack);
    const [selectedOppId] = useModalState('project-actions');
    const projectId = store.getVar(...stack)(PROJECT_ID_KEY);
    const projectActions = useProjectActions(stack, projectId);

    const setToast = useToast('assoc-message');
    const toastConfig = {
        textOnStart: 'Saving data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const push = createOfflinePush(stack, setToast, toastConfig);
    const assocAction = top => {
        assoc(TOP_ACTION_EDGE)(selectedOppId)({ id: top.id });
        push();
    };

    const [actionSearch, setActionSearch] = useState('');

    const searchedActions = (searchString, actions) => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.filter(a => (
                (a.description
                    && a.description.toUpperCase().search(string) !== -1))
                    || (a.businessId
                    && a.businessId.toUpperCase().search(string) !== -1))(actions);
        }
        return [];
    };

    return (
        <>
            <div className="columns">
                <div>
                    <div className="opp-headline">
                        Available actions
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <input
                            className="searchbar"
                            placeholder="search project actions..."
                            value={actionSearch}
                            onChange={e => setActionSearch(e.target.value)}
                            style={{ margin: 12, marginBottom: 0 }}
                        />
                    </div>
                    <OppTops
                        stack={stack}
                        opps={actionSearch.length > 0
                            ? searchedActions(actionSearch, projectActions)
                            : projectActions.slice(0, 19)}
                        assocAction={assocAction}
                        noEdit={noEdit}
                    />
                </div>
            </div>
            <Toast name="assoc-message" />
        </>
    );
};
