import * as R from 'ramda';
import { CONTACT_DIRECTORY_CONTACT_EDGE, useGraph } from '../../common/hooks.config.new';
import { Id } from '../../common/util';

const constructContactsView = projectId => projectId
    ? {
        [Id.contactDirectory(projectId)]: {
            include: {
                rights: true,
            },
            as: 'contactDirectory',
            edges: {
                [CONTACT_DIRECTORY_CONTACT_EDGE]: {
                    as: 'contacts',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

export const useContact = (stack, projectId, email) => {
    const { useView, pull } = useGraph(...stack);

    const contactsView = constructContactsView(projectId);
    const { contactDirectory } = useView(contactsView);

    const contact = R.compose(
        R.head,
        R.filter(R.pathEq(['node', 'email'], email)),
        R.values,
        R.propOr({}, 'contacts'),
    )(contactDirectory);

    const refresh = async () => projectId && pull(contactsView);

    return { contact, refresh };
};
