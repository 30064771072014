import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { newId, newIsoDate, newMsDate } from '@gothub-team/got';
import React, { useCallback, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { getOrderAfter, setOrder } from '../../common/got-adjunct';
import { MEETING_TOP_EDGE, useGraph } from '../../common/hooks.config.new';
import { ListRow } from '../Common/ListRow';
import { topHierarchy } from '../../common/util';
import { useTopBusinessIds } from '../../hooks/new/useTopBusinessIds';
import { useMeetingTops } from '../../hooks/new/useMeetingTops';
import { ProtocolTop } from './new/ProtocolTop';
import { useModal } from '../../hooks/new/useModal';

export const MeetingTops = ({ stack, meetingId, inheritFromId }) => {
    const sortedTopBags = useMeetingTops(stack, meetingId);

    const {
        add,
        remove,
        inheritRights,
        mergeGraph,
    } = useGraph(...stack);
    useTopBusinessIds(stack, sortedTopBags);
    const hierarchy = useMemo(() => R.compose(
        topHierarchy,
        R.map(R.prop('node')),
    )(sortedTopBags), [sortedTopBags]);

    const removeTop = useCallback(remove(MEETING_TOP_EDGE)(meetingId), [meetingId]);
    const createTop = order => {
        const newTopId = newId();
        add(MEETING_TOP_EDGE)(meetingId)({
            id: newTopId,
            createdDate: newIsoDate(),
        }, { order });
        inheritRights(newTopId)(inheritFromId || meetingId);
    };

    useHotkeys('shift+enter', () => {
        createTop(newMsDate());
    }, { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] });

    const topMargin = length => {
        if (length === 3) { return 40; }
        if (length === 4) { return 40; }
        if (length === 5) { return 80; }
        if (length === 6) { return 80; }
        if (length === 7) { return 120; }
        if (length === 8) { return 120; }
        return null;
    };

    const openScorecardModal = useModal('scoreCard');
    const openProjectActionsModal = useModal('project-actions');
    const openProjectOppsModal = useModal('project-opps');
    return (
        <>
            {sortedTopBags.length === 0
                ? (
                    <div
                        className="protocol-notice"
                    >
                        Press Shift + Enter to add meeting content
                    </div>
                )
                : RA.mapIndexed(({ nodeId: topId, node }, index) => (
                    <div
                        key={topId}
                        style={{ marginLeft: topMargin(hierarchy[topId].length) }}
                    >
                        <ListRow
                            buttonColor={R.includes(node.type, ['1', '2', '3']) && 'white'}
                            key={topId}
                            onRemove={() => removeTop({ id: topId })}
                            onMove={s => R.compose(
                                mergeGraph,
                                setOrder(MEETING_TOP_EDGE)(meetingId),
                                R.map(R.prop('nodeId')),
                                R.move(index, index + s),
                            )(sortedTopBags)}
                            onInsert={() => R.compose(
                                createTop,
                                getOrderAfter(index),
                            )(sortedTopBags)}
                            opp={node.type === 'o'}
                            action={node.type === 'a'}
                            openScorecard={openScorecardModal}
                            openProjectOpps={openProjectOppsModal}
                            openProjectActions={openProjectActionsModal}
                            topId={topId}
                        >
                            <div className="top-row">
                                <div className={R.includes(node.type, ['1', '2', '3'])
                                    ? 'top-hierarchy-headline'
                                    : 'top-hierarchy'}
                                >
                                    {hierarchy[topId]}
                                </div>
                                <ProtocolTop
                                    stack={stack}
                                    topId={topId}
                                />
                            </div>
                        </ListRow>
                    </div>
                ))(sortedTopBags)}
        </>
    );
};
