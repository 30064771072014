import React from 'react';
import { useModalState } from '../../../hooks/new/useModal';

export const Loader = () => {
    const [isLoading] = useModalState('loader');

    return (
        isLoading
            ? (
                <div className="loader-container">
                    <div className="loader" />
                </div>
            )
            : null
    );
};
