import React, { useEffect, useState } from 'react';
import { FaFilePdf, FaRegTrashAlt } from 'react-icons/fa';
import * as R from 'ramda';
import { useFile } from '../../../common/hooks.config';
import { MEETING_ATTACHMENT_EDGE, PROTOCOL_PDF_MODAL, useGraph } from '../../../common/hooks.config.new';
import { useModalState } from '../../../hooks/new/useModal';
import { Button } from '../../Common/Controls';
import { Modal } from '../../Common/Modal';
import '../PrintModal.css';
import { Attachment } from './AttachmentsModal';

export const ProtocolPdfModal = ({
    stack,
}) => {
    const [isOpen] = useModalState('protocol-pdf');
    return (
        <Modal
            name="protocol-pdf"
            style={{
                width: '75vw',
                left: '12.5vw',
                height: '80vh',
                top: '10vh',
            }}
            topbar={(
                <>
                    <FaFilePdf size={23} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? <ModalContent stack={stack} meetingId={isOpen} />
                : null}
        </Modal>
    );
};

const ModalContent = ({
    stack: parentStack,
    meetingId,
}) => {
    const stack = R.append(PROTOCOL_PDF_MODAL, parentStack);
    const {
        push,
        pull,
        useView,
        remove,
    } = useGraph(...stack);
    const attachmentsView = {
        [meetingId]: {
            edges: {
                [MEETING_ATTACHMENT_EDGE]: {
                    as: 'attachments',
                    include: {
                        node: true,
                        edges: true,
                        files: true,
                    },
                },
            },
        },
    };

    useEffect(() => {
        meetingId && pull(attachmentsView);
    }, [meetingId]);

    const {
        [meetingId]: {
            attachments: attachmentBags = {},
        },
    } = useView(attachmentsView);

    // TODO move pdfs to got2
    const [protocolUrl, fetchProtocol] = useFile(meetingId, 'protocol');
    const [pdfLoaded, setPdfLoaded] = useState(false);
    useEffect(async () => {
        if (meetingId) {
            setPdfLoaded(false);
            await fetchProtocol();
            setPdfLoaded(true);
        }
    }, [meetingId]);

    return (
        <div style={{ height: '100%' }}>
            {R.values(attachmentBags).length > 0
                ? (
                    <div className="attachments-container">
                        {R.map(({ node: attachment, files }) => (
                            <div className="attachment">
                                <Attachment
                                    attachment={attachment}
                                    files={files}
                                />
                                <Button onClick={() => {
                                    remove(MEETING_ATTACHMENT_EDGE)(meetingId)(attachment);
                                    push();
                                }}
                                >
                                    <FaRegTrashAlt />
                                </Button>
                            </div>
                        ))(R.values(attachmentBags))}
                    </div>
                )
                : <p style={{ marginBottom: 14 }}>No meeting attachments found.</p>}
            <div className="pdf-viewer">
                {protocolUrl
                    ? (
                        <object
                            style={{ width: '100%', height: '100%' }}
                            data={`${protocolUrl}&toolbar=0&navpanes=0&scrollbar=0`}
                            type="application/pdf"
                        >
                            <embed src={`${protocolUrl}&toolbar=0&navpanes=0&scrollbar=0`} type="application/pdf" />
                        </object>
                    )
                    : (pdfLoaded ? 'No PDF found.' : 'Loading PDF...')}
            </div>
        </div>
    );
};
