import React, { useState } from 'react';
import { AuthStore } from './hooks.config';

export const AuthScreen = ({ onLoggedIn }) => {
    const [task, setTask] = useState({
        name: 'login',
        email: '',
        password: '',
    });

    const login = (email, password) => AuthStore.login(email, password)
        .then(() => onLoggedIn(true))
        .catch(err => err.code === 'UserNotConfirmedException'
      && setTask({ name: 'activate', email }));
    // const register = (email, password) => AuthStore.register(email, password);
    const activate = code => AuthStore.activate(task.email, code);
    const resendActivationCode = () => AuthStore.resendActivationCode(task.email);
    const forgotPassword = email => AuthStore.forgotPassword(email);
    const confirmPassword = (code, password) => AuthStore.confirmPassword(task.email, code, password);

    return (
        <div
            className="centerContainer"
        >
            <div className="centerBox">
                <div style={{
                    marginLeft: '15%',
                    marginRight: '15%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
                >
                    {task.name === 'login'
            && (
            <Login
                setTask={setTask}
                login={login}
                forgotPassword={forgotPassword}
            />
            )}
                    {/* {task.name === 'register'
            && (
            <Register
                setTask={setTask}
                register={register}
            />
            )} */}
                    {task.name === 'forgotPassword'
            && (
            <ForgotPassword
                setTask={setTask}
                forgotPassword={forgotPassword}
                confirmPassword={confirmPassword}
            />
            )}
                    {task.name === 'activate'
            && (
            <Activate
                setTask={setTask}
                resendCode={resendActivationCode}
                activate={activate}
            />
            )}
                </div>
            </div>
        </div>
    );
};

const Login = ({ login, forgotPassword, setTask }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    return (
        <>
            <h1 style={{
                fontFamily: 'sans-serif', marginBottom: '36px', fontSize: '24px', color: 'var(--corporate-color-2)',
            }}
            >
                Anmeldung
            </h1>
            <input
                placeholder="E-Mail"
                name="username"
                value={email}
                onChange={e => setEmail(e.target.value)}
                style={{
                    width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '3px', borderRadius: '20px',
                }}
            />
            <input
                type="password"
                name="password"
                placeholder="Passwort"
                value={password}
                onChange={e => setPassword(e.target.value)}
                style={{
                    width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '24px', borderRadius: '20px',
                }}
            />
            <button
                onClick={() => login(email, password)}
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-blue"
            >
                Anmelden
            </button>
            <button
                onClick={() => email
          && forgotPassword(email)
          && setTask({ name: 'forgotPassword', email })}
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-quiet"
            >
                Passwort vergessen
            </button>
            {/* <button
                onClick={() => setTask({ name: 'register', email, password })}
                style={{ width: '70%', marginLeft: '0' }}
                className="button-quiet"
            >
                Registrieren
            </button> */}
        </>
    );
};

// const Register = ({ setTask, register }) => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [passwordConfirm, setPasswordConfirm] = useState('');
//     return (
//         <>
//             <h1 style={{
//                 fontFamily: 'sans-serif', marginBottom: '36px', fontSize: '24px', color: 'var(--corporate-color-2)',
//             }}
//             >
//                 Registrierung
//             </h1>
//             <input
//                 placeholder="E-Mail"
//                 name="username"
//                 value={email}
//                 onChange={e => setEmail(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '3px', borderRadius: '20px',
//                 }}
//             />
//             <input
//                 type="password"
//                 name="password"
//                 placeholder="Passwort"
//                 value={password}
//                 onChange={e => setPassword(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '3px', borderRadius: '20px',
//                 }}
//             />
//             <input
//                 type="password"
//                 name="password-confirm"
//                 placeholder="Passwort wiederholen"
//                 value={passwordConfirm}
//                 onChange={e => setPasswordConfirm(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '24px', borderRadius: '20px',
//                 }}
//             />
//             <button
//                 onClick={() => password === passwordConfirm
//           && register(email, password)
//           && setTask({ name: 'activate', email })}
//                 style={{ width: '70%', marginLeft: '0', borderRadius: '20px', }}
//                 className="button-blue"
//             >
//                 Registrieren
//             </button>
//             <button
//                 onClick={() => setTask({ name: 'login', email })}
//                 style={{ width: '70%', marginLeft: '0', borderRadius: '20px', }}
//                 className="button-quiet"
//             >
//                 zurück zum Login
//             </button>
//         </>
//     );
// };

const ForgotPassword = ({ setTask, confirmPassword }) => {
    const [validationCode, setValidationCode] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    return (
        <>
            <h1 style={{
                fontFamily: 'sans-serif', marginBottom: '36px', fontSize: '24px', color: 'var(--corporate-color-2)',
            }}
            >
                Passwort vergessen
            </h1>
            <input
                placeholder="Bestätigungscode"
                name="validation-code"
                value={validationCode}
                onChange={e => setValidationCode(e.target.value)}
                style={{
                    width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '3px', borderRadius: '20px',
                }}
            />
            <input
                type="password"
                name="password"
                placeholder="Passwort"
                value={password}
                onChange={e => setPassword(e.target.value)}
                style={{
                    width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '3px', borderRadius: '20px',
                }}
            />
            <input
                type="password"
                name="password-confirm"
                placeholder="Passwort wiederholen"
                value={passwordConfirm}
                onChange={e => setPasswordConfirm(e.target.value)}
                style={{
                    width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '24px', borderRadius: '20px',
                }}
            />
            <button
                onClick={() => password === passwordConfirm
          && confirmPassword(validationCode, password)
          && setTask({ name: 'login' })}
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-blue"
            >
                Passwort setzen
            </button>
            <button
                onClick={() => setTask({ name: 'login' })}
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-quiet"
            >
                zurück zum Login
            </button>
        </>
    );
};

const Activate = ({ setTask, activate, resendCode }) => {
    const [activationCode, setActivationCode] = useState('');
    return (
        <>
            <h1 style={{
                fontFamily: 'sans-serif', marginBottom: '36px', fontSize: '24px', color: 'var(--corporate-color-2)',
            }}
            >
                Aktivierung
            </h1>
            <input
                placeholder="Aktivierungscode"
                name="activation-code"
                value={activationCode}
                onChange={e => setActivationCode(e.target.value)}
                style={{
                    width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '24px', borderRadius: '20px',
                }}
            />
            <button
                onClick={() => activationCode
          && activate(activationCode)
          && setTask({ name: 'login' })}
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-blue"
            >
                Aktivieren
            </button>
            <button
                onClick={() => resendCode()}
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-quiet"
            >
                Code erneut senden
            </button>
            <button
                onClick={() => setTask({ name: 'login' })}
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-quiet"
            >
                zurück zum Login
            </button>
        </>
    );
};
