import React, { useState } from 'react';
import * as R from 'ramda';
import { FaPlus, FaSave } from 'react-icons/fa';
import { SiOpenaccess } from 'react-icons/si';
import { ImCross } from 'react-icons/im';
import { Modal } from '../../Common/Modal';
import {
    ListField, TextField,
} from '../../Common/Fields';
import { Button, Checkbox } from '../../Common/Controls';
import { useModalState } from '../../../hooks/new/useModal';
import { constructNodeView } from '../../../common/got-adjunct';
import {
    useGraph,
} from '../../../common/hooks.config.new';
import { useUserEmail } from '../../../hooks/new/useUserEmail';

export const OrganizationRightsModal = ({
    stack, save,
}) => {
    const [isOpen] = useModalState('organization-rights');
    return (
        <Modal
            name="organization-rights"
            style={{
                width: '50vw',
                left: '25vw',
            }}
            topbar={(
                <>
                    <SiOpenaccess size={23} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? <ModalContent stack={stack} save={save} />
                : null}
        </Modal>
    );
};

export const ModalContent = ({
    stack,
    save,
}) => {
    const {
        useView, setRights,
    } = useGraph(...stack);
    const [organizationId] = useModalState('organization-rights');
    const organizationView = constructNodeView(organizationId, { rights: true });
    const { [organizationId]: { rights } = {} } = useView(organizationView);

    const user = useUserEmail();
    const userRights = R.compose(
        R.sortBy(R.ifElse(
            R.propOr(false, 'isCurrentUser'),
            R.always('0000'),
            R.prop('user'),
        )),
        R.filter(R.anyPass([
            R.propOr(false, 'read'),
            R.propOr(false, 'write'),
            R.propOr(false, 'admin'),
        ])),
        R.values,
        R.mapObjIndexed(
            (val, key) => ({ ...val, user: key, isCurrentUser: key === user }),
        ),
        R.prop('user'),
    )(rights);

    const [input, setInput] = useState();

    return (
        <div className="columns">
            <div>
                <div className="field-column">
                    <TextField
                        value={input}
                        onChange={value => setInput(value)}
                        label="E-Mail"
                    />
                    <Button
                        onClick={() => {
                            if (input) {
                                setRights(organizationId)(input, {
                                    read: true,
                                    write: true,
                                    admin: true,
                                });
                                setInput();
                            }
                        }}
                    >
                        <FaPlus />
                        <p style={{ marginLeft: 5 }}>Add Organization Admin</p>
                    </Button>
                </div>
                <ListField
                    className
                    style={{ flex: 1 }}
                    label="Authorized contacts"
                >
                    { R.map(
                        userRight => (
                            <div
                                key={userRight.user}
                                className="row"
                            >
                                <div>{userRight.user}</div>
                                <div className="buttons">
                                    <Checkbox
                                        label="Read"
                                        value={userRight.read}
                                        disabled
                                    />
                                    <Checkbox
                                        label="Write"
                                        value={userRight.write}
                                        disabled
                                    />
                                    <Checkbox
                                        label="Admin"
                                        value={userRight.admin}
                                        disabled
                                    />
                                    <Button
                                        onClick={() => {
                                            setRights(organizationId)(userRight.user, {
                                                read: false,
                                                write: false,
                                                admin: false,
                                            });
                                        }}
                                        disabled={userRight.isCurrentUser}
                                    >
                                        <ImCross size={10} />
                                    </Button>
                                </div>
                            </div>
                        ),
                    )(userRights)}
                </ListField>
                <div style={{
                    display: 'flex', flex: 1, justifyContent: 'center',
                }}
                >
                    <Button
                        onClick={save}
                        style={{ width: 200 }}
                    >
                        <FaSave size={24} color="var(--corporate-color-1)" />
                        <p style={{ fontSize: 16, marginLeft: 12, fontWeight: 'bold' }}>Save</p>
                    </Button>
                </div>
            </div>
        </div>
    );
};
