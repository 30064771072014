import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

export const useModal = name => {
    const dispatch = useDispatch();
    return (payload = true) => dispatch({ type: 'OPEN_MODAL', name, payload });
};
export const useCloseModal = name => {
    const dispatch = useDispatch();
    return () => dispatch({ type: 'CLOSE_MODAL', name });
};

export const useModalState = name => {
    const dispatch = useDispatch();
    const isOpen = useSelector(R.pathOr(false, ['modals', name]));

    return [
        isOpen,
        () => dispatch({ type: 'CLOSE_MODAL', name }),
    ];
};
