import { Util } from '@gothub-team/got-react';
import * as R from 'ramda';
import { useEffect } from 'react';
import {
    GROUPED_TOPS_KEY,
    MEETING_ATTENDEE_EDGE,
    MEETING_ID_KEY,
    MEETING_TOP_EDGE,
    PROJECT_ID_KEY,
    SORTED_GROUPED_TOPS_KEY,
    store,
    SUBPROJECT_ID_KEY,
    TOP_RESPONSIBLE_EDGE,
    useGraph,
} from '../../../common/hooks.config.new';
import { getTopInfo } from '../../../common/topinfo.util';
import { useNodeFromVar } from '../../../hooks/new/useNode';

const constructMeetingTopsView = meetingId => meetingId
    ? {
        [meetingId]: {
            as: 'meeting',
            include: {
                node: true,
            },
            edges: {
                [MEETING_TOP_EDGE]: {
                    as: 'tops',
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                    edges: {
                        [TOP_RESPONSIBLE_EDGE]: {
                            as: 'responsibles',
                            include: {
                                node: true,
                                edges: true,
                            },
                        },
                    },
                },
                [MEETING_ATTENDEE_EDGE]: {
                    as: 'attendees',
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                },
            },
        },
    }
    : {};

const groupTopsByBID = (project, subproject, meetingBag = {}, groupedProjectTops) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [getResult, setResult] = Util.useResult(groupedProjectTops);
    const { node: meeting, attendees = {}, tops } = meetingBag;
    R.map(
        ({ node: top, responsibles }) => {
            const topInfo = getTopInfo({
                project,
                subproject,
                meeting,
                attendees,
                top,
                responsibles,
            });

            const businessId = R.prop('businessId', top);
            return topInfo && setResult(R.assocPath([businessId, top.id], topInfo));
        },
    )(tops || {});

    return getResult();
};

export const ProtocolTopCalculator = ({ stack }) => {
    const { useVar } = useGraph(...stack);

    const {
        node: project,
    } = useNodeFromVar(stack, PROJECT_ID_KEY);
    const {
        node: subproject,
    } = useNodeFromVar(stack, SUBPROJECT_ID_KEY);

    const [meetingId] = useVar(MEETING_ID_KEY);

    useEffect(() => {
        const meetingTopsView = constructMeetingTopsView(meetingId);
        const { meeting: meetingBag } = store.getView(...stack)(meetingTopsView);
        const { tops: groupedProjectTops } = store.getVar(...stack)(GROUPED_TOPS_KEY);
        const groupedTops = groupTopsByBID(project, subproject, meetingBag, groupedProjectTops);

        const msMeetingDate = new Date(meetingBag.node.date).getTime();

        const sortedGroupedTops = R.map(
            R.compose(
                R.sortBy(
                    R.compose(
                        entry => R.propOr(false, 'meetingDate', entry)
                        || R.propOr(false, 'modifiedDate', entry)
                        || R.propOr(false, 'createdDate', entry),
                    ),
                ),
                // filter top histories created after meeting date / provisorisch bis draft - submitted läuft
                R.filter(t => new Date(t.createdDate).getTime() - 720000 < msMeetingDate),
                R.values(),
            ),
        )(groupedTops);

        store.setVar(R.last(stack))(SORTED_GROUPED_TOPS_KEY, { tops: sortedGroupedTops });
    }, []);

    return null;
};
