import React, { useEffect } from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { BsArrowRight } from 'react-icons/bs';
import { DateField } from '../../Common/Fields';
import { useNode } from '../../../hooks/new/useNode';
import { PROJECT_OPP_STATUS_TYPE_CHOICES, useProjectChoices } from '../../../hooks/new/useChoices';

export const OppTimeline = ({
    stack, topId, update,
}) => {
    const { choices: oppStatusTypeChoices } = useProjectChoices(stack, PROJECT_OPP_STATUS_TYPE_CHOICES);
    const top = useNode(stack, topId);
    const statusses = R.map(ost => ost.label)(oppStatusTypeChoices);
    const statusIndex = R.indexOf(top.node.status, statusses);
    const statusDueDate = `${'dueDate_'}${statusses[statusIndex + 1]}`;
    const msDueDate = new Date(top.node[statusDueDate]).getTime();
    const msDate = Date.now();

    useEffect(() => {
        msDate > msDueDate
            && update(
                R.assoc('critical', true, top.node),
            );
        msDate < msDueDate
            && update(
                R.assoc('critical', false, top.node),
            );
    },
    [topId, msDueDate]);

    return (
        <div className="opp-timeline">
            {RA.mapIndexed((ost, i) => (
                <React.Fragment key={i}>
                    <TimelineElement
                        status={ost.label}
                        statusIndex={statusIndex}
                        top={top.node}
                        update={update}
                        i={i}
                        msDate={msDate}
                        msDueDate={msDueDate}
                    />
                    {i !== oppStatusTypeChoices.length - 1 && (
                    <Arrow
                        status={ost.label}
                        statusIndex={statusIndex}
                        top={top.node}
                        i={i}
                    />
                    )}
                </React.Fragment>
            ))(oppStatusTypeChoices)}
        </div>
    );
};

export const TimelineElement = ({
    top, status, statusIndex, update, i, msDate, msDueDate,
}) => (
    <div style={{
        boxShadow: '0px 0px 10px 0px var(--shadow-color-light)',
        backgroundColor: 'var(--color-3)',
        display: 'flex',
        alignItems: 'center',
        borderStyle: 'solid',
        borderColor: top.status !== status
        && i < statusIndex
            ? 'var(--corporate-color-11)'
            : top.status === status
                ? 'var(--corporate-color-1)'
                : msDate > msDueDate && i === statusIndex + 1
                    ? 'var(--color-error)'
                    : 'var(--corporate-color-1)',
        borderWidth:
         ((msDate > msDueDate && i === statusIndex + 1) || (i < statusIndex + 1))
             ? 2
             : 1,
        borderRadius: 12,
        padding: 6,
        paddingLeft: 9,
    }}
    >
        <div style={{ flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{
                display: 'flex',
                flex: 1,
                marginBottom: 12,
                fontWeight: top.status === status
                    ? 'bold'
                    : 'normal',
                fontSize: 16,
                color: top.status !== status
                && i < statusIndex
                    ? 'var(--corporate-color-11)'
                    : top.status === status
                        ? 'var(--corporate-color-1)'
                        : msDate > msDueDate && i === statusIndex + 1
                            ? 'var(--color-error)'
                            : 'var(--corporate-color-1)',
            }}
            >
                {status}
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
                <DateField
                    label="Due Date"
                    value={R.propOr('', `dueDate_${status}`, top)}
                    locale="en-gb"
                    onChange={value => update(
                        R.assoc(`dueDate_${status}`, value, top),
                    )}
                    editable
                />
            </div>
        </div>
    </div>
);

export const Arrow = ({
    top, status, statusIndex, i,
}) => (
    <div
        style={{ marginRight: 6 }}
    >
        <BsArrowRight
            size={25}
            color={top.status !== status
        && i < statusIndex
                ? 'var(--corporate-color-11)'
                : 'var(--corporate-color-1)'}
        />
    </div>
);
