import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { FaCopy } from 'react-icons/fa';
import { newId } from '@gothub-team/got';
import { useModal, useModalState } from '../../../hooks/new/useModal';
import { Modal } from '../../Common/Modal';
import { Button } from '../../Common/Controls';
import { ListField } from '../../Common/Fields';
import {
    PROJECT_ID_KEY,
    PROTOCOL,
    store,
    SUBPROJECT_ID_KEY,
    useGraph,
} from '../../../common/hooks.config.new';
import { useNode, usePullNode } from '../../../hooks/new/useNode';
import { useSubprojectMeetings } from '../../../hooks/new/useSubprojectMeetings';
import { cloneAsTemplate } from '../../../common/cloneAsTemplate.util';
import { useNavigator } from '../../../hooks/new/useNavigator';

export const TemplatesModal = ({
    stack,
}) => {
    const [isOpen, close] = useModalState('templates');
    return (
        <Modal
            name="templates"
            style={{
                height: '87vh',
                top: '6.5vh',
                width: '60vw',
                left: '20vw',
            }}
            topbar={(
                <>
                    <FaCopy size={22} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? (
                    <TemplatesModalContent
                        stack={stack}
                        close={close}
                    />
                )
                : null}
        </Modal>
    );
};

const TemplatesModalContent = ({
    stack,
    close,
}) => {
    const { useVar } = useGraph(...stack);

    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);

    const { node: project } = useNode(stack, projectId);
    const { node: subproject } = useNode(stack, subprojectId);
    const { pull, meetingBags } = useSubprojectMeetings(stack, subprojectId);
    const sortedMeetingBags = R.compose(
        R.filter(R.pathEq(['node', 'isDummy'], undefined)),
        R.reverse,
        R.sortBy(R.path(['node', 'date'])),
        R.values,
    )(meetingBags);

    usePullNode(stack, projectId);
    useEffect(() => subprojectId && pull(), [subprojectId]); // pulls subproject ndoe and its meetings

    const openProcolPdf = useModal('protocol-pdf');

    const meetingSearch = (searchString, meetings) => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.filter(m => (m.node.title
                    && m.node.title.toUpperCase().search(string) !== -1))(meetings);
        }
        return [];
    };

    const [templatesSearch, setTemplatesSearch] = useState('');

    return (
        <div className="columns">
            <div>
                <div className="opp-headline">
                    Available Templates
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <input
                        className="searchbar"
                        placeholder="search available templates..."
                        value={templatesSearch}
                        onChange={e => setTemplatesSearch(e.target.value)}
                        style={{ margin: 12 }}
                    />
                </div>
                <ListField
                    className
                    style={{ height: '67.5vh' }}
                    label="Templates"
                >
                    {R.map(
                        ({ node: meeting }) => (
                            <ProtocolItem
                                key={meeting.id}
                                stack={stack}
                                openProcolPdf={openProcolPdf}
                                meeting={meeting}
                                subprojectId={subprojectId}
                                projectBusinessId={R.propOr('', 'businessId', project)}
                                subprojectBusinessId={R.propOr('', 'businessId', subproject)}
                                close={close}
                            />
                        ),
                    )(templatesSearch.length > 0
                        ? meetingSearch(templatesSearch, sortedMeetingBags)
                        : sortedMeetingBags)}
                </ListField>
            </div>
        </div>
    );
};

const ProtocolItem = ({
    stack, meeting, openProcolPdf, subprojectId, projectBusinessId, subprojectBusinessId, close,
}) => {
    const { navigateToProtocol } = useNavigator(stack);
    // const { mergeGraph } = useGraph(...stack);

    return (
        <div
            className="row"
            style={{ cursor: 'default' }}
        >
            <div style={{ display: 'flex', flex: 2 }}>{meeting.title}</div>
            <div style={{ color: 'var(--color-2)', display: 'flex', flex: 1 }}>{meeting.businessId}</div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                <Button
                    onClick={() => openProcolPdf && openProcolPdf(meeting.id)}
                    style={{ color: 'var(--corporate-color-2)' }}
                >
                    Open PDF
                </Button>
                <Button
                    onClick={async () => {
                        const newMeetingId = newId();
                        const templateGraph = await cloneAsTemplate({
                            stack,
                            meeting,
                            newMeetingId,
                            projectBusinessId,
                            subprojectId,
                            subprojectBusinessId,
                        });
                        store.mergeGraph(templateGraph, PROTOCOL);
                        navigateToProtocol(newMeetingId);
                        close();
                    }}
                    style={{ color: 'var(--corporate-color-2)' }}
                >
                    Use as template
                </Button>
            </div>
        </div>
    );
};
