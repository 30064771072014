import * as R from 'ramda';
import React from 'react';
import { useProjectCurrency } from '../../../hooks/new/useChoices';
import { useLocalValue } from '../../../hooks/useLocalValue';
import { Focusable } from '../../Common/Fields';

export const CurrencyField = ({
    stack,
    label,
    style,
    value,
    editable = true,
    onChange,
    autoFocus,
    className = '',
    noBottomLabel,
}) => {
    const [localValue, setLocalValue] = useLocalValue(value);

    const currency = useProjectCurrency(stack);
    const format = val => new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: R.defaultTo('EUR', currency),
    }).format(val);

    return (
        <div className={`field currency-field ${className} ${localValue ? 'selected' : ''}`}>
            {editable && onChange
                ? (
                    <Focusable autoFocus={autoFocus}>
                        <input
                            style={style}
                            className="input"
                            value={localValue || ''}
                            placeholder={label}
                            onChange={e => {
                                e.target.value.replace(/[^0-9]/g, '').startsWith(0)
                                    ? setLocalValue(R.tail(e.target.value.replace(/[^0-9]/g, '')))
                                    : setLocalValue(e.target.value.replace(/[^0-9]/g, ''));
                            }}
                            onBlur={e => e.target.value !== value && onChange(e.target.value)}
                        />
                    </Focusable>
                )
                : <div style={{ width: 150 }}>{format ? format(value) : value}</div>}
            {!noBottomLabel && (
            <div className="label">
                {label}
                {' '}
                {currency}
            </div>
            )}
        </div>
    );
};
