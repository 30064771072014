import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { FaPlus } from 'react-icons/fa';
import { newId } from '@gothub-team/got';
import { BsPeopleFill } from 'react-icons/bs';
import { SiOpenaccess } from 'react-icons/si';
import { useModal, useModalState } from '../../../hooks/new/useModal';
import { Modal } from '../../Common/Modal';
import { Button } from '../../Common/Controls';
import { ListField } from '../../Common/Fields';
import {
    CONTACTS_MODAL,
    CONTACT_DIRECTORY_CONTACT_EDGE,
    PROJECT_ID_KEY,
    useGraph,
} from '../../../common/hooks.config.new';
import { Id } from '../../../common/util';
import { useUserEmail } from '../../../hooks/new/useUserEmail';
import { useToast } from '../../../hooks/new/useToast';
import { createOfflinePush } from '../../../common/got-adjunct';
import { Toast } from '../../Elements/new/Toast';
import { CDRightsModal } from './CDRightsModal';
import { NewContactModal } from './NewContactModal';

const constructContactsView = projectId => projectId
    ? {
        [Id.contactDirectory(projectId)]: {
            include: {
                rights: true,
            },
            as: 'contactDirectory',
            edges: {
                [CONTACT_DIRECTORY_CONTACT_EDGE]: {
                    as: 'contacts',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

export const ContactsModal = ({
    stack,
}) => {
    const [isOpen] = useModalState('contacts');
    return (
        <Modal
            name="contacts"
            style={{
                height: '87vh',
                top: '6.5vh',
                width: '60vw',
                left: '20vw',
            }}
            topbar={(
                <>
                    <BsPeopleFill size={23} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? (
                    <ModalContent
                        stack={stack}
                    />
                )
                : null}
        </Modal>
    );
};

const ModalContent = ({
    stack: parentStack,
}) => {
    const [contact, setContact] = useState({});
    const stack = R.append(CONTACTS_MODAL, parentStack);
    const {
        useView, pull, useVar, add, update, inheritRights, dissoc,
    } = useGraph(...stack);

    const [projectId] = useVar(PROJECT_ID_KEY);
    const contactsView = constructContactsView(projectId);
    useEffect(() => projectId && pull(contactsView), [projectId]);
    const { contactDirectory } = useView(contactsView);

    const user = useUserEmail();
    const canAdminCD = R.pathOr(false, ['rights', 'user', user, 'admin']);
    const projectContacts = R.compose(
        R.values,
        R.map(R.prop('node')),
        R.propOr({}, 'contacts'),
    )(contactDirectory);

    const setToast = useToast('contacts');
    const toastConfig = {
        textOnStart: 'Saving contact data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const _push = createOfflinePush(stack, setToast, toastConfig);
    const push = async () => {
        contact.id && update(contact);
        return _push();
    };

    const openContactDirectoryRightsModal = useModal('cd-rights');
    const openNewContactModal = useModal('new-contact');

    const contactsSearch = (searchString, contacts) => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.filter(c => (c.name
                    && c.name.toUpperCase().search(string) !== -1))(contacts);
        }
        return [];
    };

    const [contactSearchString, setContactSearchString] = useState('');

    return (
        <>
            <div className="columns">
                <div>
                    <div className="opp-headline">
                        Project Contacts
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
                            {canAdminCD && (
                            <Button
                                onClick={() => {
                                    const newContactId = newId();
                                    const newContact = {
                                        id: newContactId,
                                    };
                                    add(CONTACT_DIRECTORY_CONTACT_EDGE)(Id.contactDirectory(projectId))(newContact);
                                    inheritRights(newContactId)(Id.contactDirectory(projectId));
                                    setContact(newContact);
                                    openNewContactModal();
                                }}
                            >
                                <FaPlus />
                                <p style={{ marginLeft: 5 }}>Create Contact</p>
                            </Button>
                            )}

                        </div>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            <input
                                className="searchbar"
                                placeholder="search project contacts..."
                                value={contactSearchString}
                                onChange={e => setContactSearchString(e.target.value)}
                                style={{ margin: 12 }}
                            />
                        </div>

                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            {canAdminCD && (
                            <Button
                                onClick={openContactDirectoryRightsModal}
                            >
                                <SiOpenaccess />
                                <p style={{ marginLeft: 5 }}>Manage Contact Directory Rights</p>
                            </Button>
                            )}
                        </div>
                    </div>
                    <ListField
                        className
                        style={{ height: '67.5vh' }}
                        label="Project Contacts"
                    >
                        {R.map(
                            c => (
                                <div
                                    key={c.id}
                                    className="row"
                                    style={{ cursor: 'default', paddingLeft: 12, paddingRight: 6 }}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1 }}>
                                        <div>{c.name}</div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                                        <div style={{ color: 'var(--color-2)' }}>{c.email}</div>
                                    </div>
                                    {canAdminCD
                                    && (
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                                        <Button
                                            onClick={() => {
                                                setContact(c);
                                                openNewContactModal();
                                            }}
                                            style={{ marginRight: 24, color: 'var(--corporate-color-2)' }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            style={{ marginRight: 24, color: 'var(--corporate-color-2)' }}
                                            onClick={() => {
                                                dissoc(CONTACT_DIRECTORY_CONTACT_EDGE)(Id.contactDirectory(projectId))(contact);
                                                push();
                                            }}
                                        >
                                            Delete
                                        </Button>
                                        <Button
                                            style={{ color: 'var(--corporate-color-2)' }}
                                            onClick={() => {}}
                                        >
                                            Invite
                                        </Button>
                                    </div>
                                    ) }
                                </div>
                            ),
                        )(contactSearchString.length > 0
                            ? contactsSearch(contactSearchString, projectContacts)
                            : projectContacts)}
                    </ListField>
                </div>
            </div>
            <Toast name="contacts" />
            <CDRightsModal
                stack={stack}
                push={push}
            />
            <NewContactModal
                push={push}
                contact={contact}
                setContact={setContact}
            />
        </>
    );
};
