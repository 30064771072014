/* eslint-disable react/jsx-props-no-spreading */
import * as R from 'ramda';
import React, { useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDropzone } from 'react-dropzone';
import { Portal } from 'react-overlays';
import { formatShortDate } from '../../common/util';
import { useLocalValue } from '../../hooks/useLocalValue';
import './Fields.css';

export const TextField = ({
    label,
    style,
    value,
    editable = true,
    onChange,
    autoFocus,
    className = '',
    noBottomLabel,
    realtime = false,
    disabled,
}) => {
    const [localValue, setLocalValue] = useLocalValue(value);

    return (
        <div className={`field text-field ${className} ${localValue ? 'selected' : ''}`}>
            {editable && onChange
                ? (
                    <Focusable autoFocus={autoFocus}>
                        <input
                            disabled={disabled}
                            style={style}
                            className="input"
                            value={localValue || ''}
                            placeholder={label}
                            onChange={e => {
                                setLocalValue(e.target.value);
                                realtime && onChange(e.target.value);
                            }}
                            onBlur={e => e.target.value !== value && onChange(e.target.value)}
                        />
                    </Focusable>
                )
                : <div style={{ cursor: 'default' }}>{value}</div>}
            {!noBottomLabel && <div className="label">{label}</div>}
        </div>
    );
};

export const LargeTextField = ({
    label,
    value,
    editable = true,
    onChange,
    rows,
    autoFocus,
    className = '',
    style,
    noBottomLabel,
}) => {
    const [localValue, setLocalValue] = useLocalValue(value);
    const lineBreaks = (localValue && localValue.match(/\n/g)) ? localValue.match(/\n/g).length : 0;

    return (
        <div className={`field large-text-field ${className} ${localValue ? 'selected' : ''}`}>
            {editable && onChange
                ? (
                    <Focusable autoFocus={autoFocus}>
                        <textarea
                            className="input"
                            style={{ ...style, height: rows ? null : (15 * lineBreaks) + 46 }}
                            rows={rows}
                            value={localValue || ''}
                            placeholder={label}
                            onChange={e => setLocalValue(e.target.value)}
                            onBlur={e => e.target.value !== value && onChange(e.target.value)}
                        />
                    </Focusable>
                )
                : <div style={{ cursor: 'default' }}>{value}</div>}
            {!noBottomLabel && <div className="label">{label}</div>}
        </div>
    );
};

export const DateField = ({
    label,
    value,
    editable = true,
    onChange,
    className = '',
    late,
    lastVersion,
}) => {
    function CalendarContainer({ children }) {
        const el = document.getElementById('calendar-portal');
        return (
            <Portal container={el}>{children}</Portal>
        );
    }

    return (
        <div style={{ marginRight: 4 }} className={`field date-field ${className} ${value ? 'selected' : ''}`}>
            {editable && onChange
                ? (
                    <DatePicker
                        className="datepicker"
                        isClearable
                        placeholderText={label}
                        dateFormat="dd/MM/yyyy"
                        value={formatShortDate(value)}
                        onChange={e => {
                            e.setHours(12);
                            onChange(e.toISOString());
                        }}
                        popperContainer={CalendarContainer}
                    />
                )
                : <div style={{ cursor: 'default', color: late && lastVersion ? 'var(--color-error)' : late ? 'var(--color-error-light)' : null }}>{formatShortDate(value)}</div>}
            <div className="label">{label}</div>
        </div>
    );
};

export const CurrencyField = ({
    label,
    style,
    value,
    editable = true,
    onChange,
    autoFocus,
    className = '',
    noBottomLabel,
    formato,
}) => {
    const [localValue, setLocalValue] = useLocalValue(value);

    return (
        <div className={`field currency-field ${className} ${localValue ? 'selected' : ''}`}>
            {editable && onChange
                ? (
                    <Focusable autoFocus={autoFocus}>
                        <input
                            style={style}
                            className="input"
                            value={localValue || ''}
                            placeholder={label}
                            onChange={e => {
                                e.target.value.replace(/[^0-9]/g, '').startsWith(0)
                                    ? setLocalValue(R.tail(e.target.value.replace(/[^0-9]/g, '')))
                                    : setLocalValue(e.target.value.replace(/[^0-9]/g, ''));
                            }}
                            onBlur={e => e.target.value !== value && onChange(e.target.value)}
                        />
                    </Focusable>
                )
                : <div style={{ width: 150 }}>{formato ? formato(value) : value}</div>}
            {!noBottomLabel && <div className="label">{label}</div>}
        </div>
    );
};

export const ImageField = ({
    label,
    value,
    editable = true,
    onChange,
    className = '',
}) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: acceptedFiles => {
            onChange && onChange(acceptedFiles[0]);
        },
    });

    return (
        <div className={`field image-field ${className} ${value ? 'selected' : ''}`} {...getRootProps()}>
            {editable && <input {...getInputProps()} />}
            <div
                className="input"
                style={{
                    backgroundImage: `url(${value})` || 'none',
                }}
            >
                {isDragActive && <p>Drop file here ...</p>}
                {!isDragActive && !value && <p>{label}</p>}
            </div>
            <div className="label">{label}</div>
        </div>
    );
};
export const FileField = ({
    label,
    value,
    // editable = true,
    onChange,
    className = '',
}) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: acceptedFiles => {
            onChange && onChange(acceptedFiles);
        },
    });

    return (
        <div className={`field file-field ${className} ${value ? 'selected' : ''}`} {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="input">
                {isDragActive && <p>Drop file here ...</p>}
                {!isDragActive && !value && <p>{label}</p>}
            </div>
            <div className="label">{label}</div>
        </div>
    );
};

export const ListField = ({
    label,
    children,
    className = '',
    style,
}) => (
    <div style={style} className={`field list-field ${className} ${children ? 'selected' : ''}`}>
        <div className="input">
            {children}
        </div>
        <div className="label">{label}</div>
    </div>
);
//
export const Focusable = ({ children, autoFocus }) => {
    const childRef = useRef(null);
    useEffect(() => {
        autoFocus && childRef.current && childRef.current.focus && childRef.current.focus();
    }, []);
    const newChildren = React.Children.map(children, element => React.cloneElement(element, { ref: childRef }));
    return (
        <>
            {newChildren}
        </>
    );
};
