import * as R from 'ramda';
import React, { useEffect } from 'react';
import { RiChatSmileFill } from 'react-icons/ri';
import { Checkbox, Button } from '../../../Common/Controls';
import { LargeTextField, TextField } from '../../../Common/Fields';
import { useSendContactForm } from '../../../../hooks/useSendContactForm';
import '../LandingPage.css';
import logo from '../../../../logos/pclogo.png';
import welcomeContact from '../Imgs/contact.png';

const ContactPage = () => {
    useEffect(() => [window.scrollTo(0, 0)], []);
    const [contactForm, setContactForm, send] = useSendContactForm();
    const contactImage = (
        <div>
            <img
                style={{ width: '100%' }}
                alt="welcomeContact"
                src={welcomeContact}
            />
            <div>
                <p className="image-text" style={{ color: 'var(--corporate-color-7)' }}>
                    Kontakt
                </p>
            </div>
            <img
                alt="logo"
                src={logo}
                className="image-logo"
            />
        </div>
    );
    const contactFields = (
        <div className="content-box">
            <RiChatSmileFill size={100} color="var(--corporate-color-4)" />
            <p className="content-box-headline" style={{ marginTop: 24 }}>
                Haben Sie eine Frage?
                Wir freuen uns, von Ihnen zu hören.
            </p>
            <div className="box-row" style={{ justifyContent: 'space-around' }}>
                <div
                    className="row-column-solution"
                    style={{
                        height: 'var(--contact-box-height)',
                        justifyContent: 'space-around',
                        boxShadow: '0px 0px 25px 0px var(--shadow-color-normal)',
                    }}
                >
                    <p className="contact-form-label p-lp">Name</p>
                    <TextField
                        noBottomLabel
                        label="Name"
                        onChange={value => setContactForm(R.assocPath(['sender', 'name'], value))}
                        value={contactForm.sender.name || ''}
                        className="contact-textfield"
                        realtime
                    />
                    <p className="contact-form-label p-lp">E-Mail-Adresse</p>
                    <TextField
                        noBottomLabel
                        label="E-Mail-Adresse"
                        onChange={value => setContactForm(R.assocPath(['sender', 'email'], value))}
                        value={contactForm.sender.email || ''}
                        className="contact-textfield"
                        realtime
                    />
                    <p className="contact-form-label p-lp">Telefon</p>
                    <TextField
                        noBottomLabel
                        label="Telefon"
                        onChange={value => setContactForm(R.assocPath(['sender', 'phone'], value))}
                        value={contactForm.sender.phone || ''}
                        className="contact-textfield"
                        realtime
                    />
                    <p className="contact-form-label p-lp">Ihre Nachricht</p>
                    <LargeTextField
                        noBottomLabel
                        label="Ihre Nachricht"
                        onChange={value => setContactForm(R.assoc('body', value))}
                        value={contactForm.body || ''}
                        className="contact-large-textfield"
                    />
                    <Checkbox
                        className="contact-checkbox"
                        label="Wir verarbeiten ihre Daten in Übereinstimmung mit unserer Datenschutzerklärung"
                        onChange={() => setContactForm(R.assoc('privacyAccepted', !contactForm.privacyAccepted))}
                        value={contactForm.privacyAccepted || ''}
                    />
                    <Button
                        onClick={() => {
                            send();
                            setContactForm({
                                sender: {
                                    name: '',
                                    email: '',
                                    phone: '',
                                },
                                body: '',
                                privacyAccepted: false,
                            });
                        }}
                        className="page-button"
                        style={{ marginTop: 36 }}
                    >
                        Absenden
                    </Button>
                </div>
            </div>
        </div>
    );
    return (
        <div>
            {contactImage}
            {contactFields}
        </div>
    );
};

export default ContactPage;
