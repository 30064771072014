import { SUBPROJECT_MEETING_EDGE, useGraph } from '../../common/hooks.config.new';

export const useSubprojectMeetings = (stack, subprojectId) => {
    const view = {
        [subprojectId]: {
            include: {
                node: true,
            },
            edges: {
                [SUBPROJECT_MEETING_EDGE]: {
                    as: 'meetings',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    };

    const {
        useView,
        pull: _pull,
    } = useGraph(...stack);
    const {
        [subprojectId]: {
            meetings: meetingBags,
        },
    } = useView(view);

    const pull = () => subprojectId && _pull(view);

    return { pull, meetingBags };
};
