import React from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import { FaChartBar, FaProjectDiagram, FaSquareFull } from 'react-icons/fa';
import {
    CHART_TOPS_KEY, DASHBOARD_STATE_KEY, SEARCH_STRING_KEY, useGraph,
} from '../../../common/hooks.config.new';
import { Button } from '../../Common/Controls';
import logo from '../../../logos/pclogo.png';

export const DashboardStateSelector = ({ stack }) => {
    const { useVar } = useGraph(...stack);
    const [dashboardState, setDashboardState] = useVar(DASHBOARD_STATE_KEY);
    const [searchString, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [{ tops: chartTops } = {}, setChartTops] = useVar(CHART_TOPS_KEY);

    return (
        <div style={{
            display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', marginTop: 18,
        }}
        >
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
                {(chartTops || searchString) && (
                <Button
                    onClick={() => {
                        setSearchString('');
                        setChartTops({});
                    }}
                    style={{ marginLeft: 24 }}
                >
                    <div className="button-icon">
                        <FaChartBar size={25} />
                        <p style={{ marginLeft: '9px' }}>Back to Charts</p>
                    </div>
                </Button>
                )}
            </div>
            <div style={{
                display: 'flex', flex: 1, justifyContent: 'center',
            }}
            >
                <img alt="logo" src={logo} style={{ width: 150 }} />
            </div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                <Button
                    onClick={() => {
                        setDashboardState('user');
                        setSearchString('');
                    }}
                    style={{ backgroundColor: (!dashboardState || dashboardState === 'user') && 'var(--corporate-color-6-light)' }}
                >
                    <div
                        className="button-icon"
                    >
                        <BsFillPersonFill size={27} />
                        <p style={{ marginLeft: '9px' }}>User</p>
                    </div>
                </Button>
                <Button
                    onClick={() => {
                        setDashboardState('project');
                        setSearchString('');
                    }}
                    style={{ marginLeft: 12, backgroundColor: dashboardState === 'project' && 'var(--corporate-color-6-light)' }}
                >
                    <div className="button-icon">
                        <FaProjectDiagram size={25} />
                        <p style={{ marginLeft: '9px' }}>Project</p>
                    </div>
                </Button>
                <Button
                    onClick={() => {
                        setDashboardState('subproject');
                        setSearchString('');
                    }}
                    style={{ marginLeft: 12, marginRight: 24, backgroundColor: dashboardState === 'subproject' && 'var(--corporate-color-6-light)' }}
                >
                    <div className="button-icon">
                        <FaSquareFull size={9} />
                        <p style={{ marginLeft: '9px' }}>Subproject</p>
                    </div>
                </Button>
            </div>
        </div>
    );
};
