import { useHistory } from 'react-router';
import * as R from 'ramda';
import {
    DASHBOARD,
    MAIN,
    MEETING_ID_KEY, OFFLINE, PROTOCOL_VARS, SEARCH_STRING_KEY, store, TOPS_TO_UPDATE_KEY, useGraph,
} from '../../common/hooks.config.new';

export const useNavigator = stack => {
    const history = useHistory();
    const { useVar } = useGraph(...[MAIN, OFFLINE, DASHBOARD]);
    const [, setTopsToUpdate] = useVar(TOPS_TO_UPDATE_KEY);

    const navigateToProtocol = meetingId => {
        store.setVar(R.last(stack))(MEETING_ID_KEY, meetingId);
        store.setVar(R.last(stack))(SEARCH_STRING_KEY, '');
        store.clear(PROTOCOL_VARS);
        history.push('/protocol');
    };

    const navigateToDashboard = () => {
        setTopsToUpdate({});
        history.push('/dashboard');
    };

    return {
        navigateToProtocol,
        navigateToDashboard,
    };
};
