import * as R from 'ramda';
import React, { useEffect } from 'react';
import { ImAttachment } from 'react-icons/im';
import { useGraph } from '../../../common/hooks.config.new';
import { useMeetingTypesChoices } from '../../../hooks/new/useChoices';
import { useMeetingId } from '../../../hooks/new/useMeetingId';
import { Button } from '../../Common/Controls';
import { DateField, TextField } from '../../Common/Fields';
import { DropdownField } from '../../Common/Dropdown';

export const Meeting = ({
    stack, meeting, openAttachmentsModal, subprojectId, projectId,
}) => {
    const { update: updateMeeting } = useGraph(...stack);
    const meetingTypesChoices = useMeetingTypesChoices(stack);

    useEffect(() => {
        if (meeting && meeting.id && !meeting.date) { // meeting loaded and has no date
            updateMeeting(
                R.assoc('date', new Date().toISOString(), meeting),
            );
        }
    }, [meeting]);
    useMeetingId(stack, projectId, subprojectId, meeting, updateMeeting);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div style={{
                display: 'flex', justifyContent: 'center', marginBottom: 6, color: 'var(--corporate-color-1-light2)',
            }}
            >
                Meeting Header
            </div>
            <div style={{
                display: 'flex', justifyContent: 'space-between',
            }}
            >
                <DropdownField
                    label="Type"
                    value={R.propOr('', 'type', meeting)}
                    options={meetingTypesChoices}
                    onChange={option => updateMeeting(
                        R.assoc('type', option.value, meeting),
                    )}
                />
                <div style={{ display: 'flex' }}>
                    <TextField
                        disabled
                        label="Meeting ID"
                        value={R.propOr('', 'businessId', meeting)}
                        onChange={() => {}}
                    />
                </div>
                <TextField
                    label="Title"
                    value={R.propOr('', 'title', meeting)}
                    onChange={value => updateMeeting(
                        R.assoc('title', value, meeting),
                    )}
                />
                <TextField
                    label="Location"
                    value={R.propOr('', 'location', meeting)}
                    onChange={value => updateMeeting(
                        R.assoc('location', value, meeting),
                    )}
                />
                <DateField
                    label="Date"
                    value={R.prop('date', meeting)}
                    onChange={value => updateMeeting(
                        R.assoc('date', value, meeting),
                    )}
                />
                <Button onClick={() => openAttachmentsModal()} style={{ marginBottom: 12 }}>
                    <div className="button-icon">
                        <ImAttachment size={20} style={{ marginRight: 9 }} />
                        Meeting files
                    </div>
                </Button>
            </div>
        </div>
    );
};
