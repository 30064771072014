import { useMemo } from 'react';
import { sortBagsByOrder } from '../../common/got-adjunct';
import { MEETING_ATTENDEE_EDGE, useGraph } from '../../common/hooks.config.new';

export const useMeetingAttendees = (stack, meetingId) => {
    const {
        useView,
    } = useGraph(...stack);
    const {
        [meetingId]: {
            attendees: attendeeBags,
        },
    } = useView({
        [meetingId]: {
            edges: {
                [MEETING_ATTENDEE_EDGE]: {
                    as: 'attendees',
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                },
            },
        },
    });
    return useMemo(() => sortBagsByOrder(attendeeBags), [attendeeBags]);
};
