import React from 'react';
import './Modal.css';
import { ImCross } from 'react-icons/im';
import { Button } from './Controls';
import { useModalState } from '../../hooks/new/useModal';

export const Modal = ({
    name, topbar, children, isOpen, close, style,
}) => {
    const [_isOpen, _close] = useModalState(name);
    const doClose = () => {
        name && _close();
        close && close();
    };
    return (
        <div
            className={`modal-backdrop${_isOpen || isOpen ? ' open' : ''}`}
            onClick={() => doClose()}
        >
            <div
                style={style}
                className="modal-container"
                onClick={e => e.stopPropagation()}
            >
                <div className="topbar-container">
                    <div className="topbar-icons">
                        {topbar}
                    </div>
                    <Button onClick={() => doClose()}>
                        <ImCross size={13} color="white" />
                    </Button>
                </div>
                <div className="content-container">
                    {children}
                </div>
            </div>
        </div>
    );
};
