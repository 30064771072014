import * as R from 'ramda';
import React, { useEffect } from 'react';
import { RiHomeSmileFill } from 'react-icons/ri';
import { FaPlus, FaSave } from 'react-icons/fa';
import { SiOpenaccess } from 'react-icons/si';
import { newId, newMsDate } from '@gothub-team/got';
import { Modal } from '../../Common/Modal';
import {
    TextField, ImageField,
} from '../../Common/Fields';
import { DropdownField } from '../../Common/Dropdown';

import { Button } from '../../Common/Controls';
import '../OrganizationModal.css';
import { useModal, useModalState } from '../../../hooks/new/useModal';
import {
    ORGANIZATIONS_MODAL,
    ORGANIZATION_ID_KEY, PROJECT_ID_KEY, ROOT_ORGANIZATION_EDGE, SUBPROJECT_ID_KEY, useGraph,
} from '../../../common/hooks.config.new';
import { APPLICATION_ROOT_NODE } from '../../../common/config';
import { useUserEmail } from '../../../hooks/new/useUserEmail';
import { constructNodeView, createOfflinePush } from '../../../common/got-adjunct';
import { OrganizationRightsModal } from './OrganizationRightsModal';
import { Toast } from '../../Elements/new/Toast';
import { useToast } from '../../../hooks/new/useToast';
import { NewOrgModal } from './NewOrgModal';

const constructOrgsView = rootNodeId => rootNodeId
    ? {
        [rootNodeId]: {
            as: 'root',
            include: {
                node: true,
            },
            edges: {
                [ROOT_ORGANIZATION_EDGE]: {
                    as: 'organizations',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

export const OrganizationModal = ({ stack }) => {
    const [isOpen] = useModalState('organizations');

    return (
        <Modal
            name="organizations"
            style={{
                height: '62vh',
                width: '40vw',
                top: '19vh',
                left: '30vw',
            }}
            topbar={(
                <>
                    <RiHomeSmileFill size={25} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? <OrganizationModalContent stack={stack} isOpen={isOpen} />
                : null}
        </Modal>
    );
};

const OrganizationModalContent = ({ stack: parentStack }) => {
    const user = useUserEmail();

    // organazation selection
    const {
        useVar,
    } = useGraph(...parentStack);
    const [organizationId, setOrganizationId] = useVar(ORGANIZATION_ID_KEY);

    // our graph stack
    const stack = R.append(ORGANIZATIONS_MODAL, parentStack);
    const {
        useView, pull, add, update, setFile, setRights,
    } = useGraph(...stack);

    // user rights on app
    const appView = constructNodeView(APPLICATION_ROOT_NODE, { rights: true });
    useEffect(() => APPLICATION_ROOT_NODE && pull(appView), [APPLICATION_ROOT_NODE]);
    const { [APPLICATION_ROOT_NODE]: appBag = {} } = useView(appView);
    const canWriteApp = R.pathOr(false, ['rights', 'user', user, 'write'], appBag);

    // organization choices
    const orgsView = constructOrgsView(APPLICATION_ROOT_NODE);
    const { root: { organizations = {} } = {} } = useView(orgsView);
    const organizationChoices = R.map(organizationBag => ({
        value: R.path(['node', 'id'], organizationBag),
        label: R.path(['node', 'name'], organizationBag),
        body: R.path(['node'], organizationBag),
    }))(R.values(organizations));

    // selected organization
    const organizationView = constructNodeView(organizationId, { node: true, rights: true, files: true });
    useEffect(() => organizationId && pull(organizationView), [organizationId]);
    const { [organizationId]: organizationBag = {} } = useView(organizationView);
    const isOrganizationAdmin = R.pathOr(false, ['rights', 'user', user, 'admin'], organizationBag);
    const organizationLogoUrl = R.path(['files', 'organizationLogo', 'url'], organizationBag);
    const organization = R.propOr({}, 'node', organizationBag);

    const [, setProjectId] = useVar(PROJECT_ID_KEY);
    const [, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);

    const save = async () => {
        setOrganizationToast('spinner', 'Saving organization data...');
        const toastConfig = {
            textOnStart: 'Saving organization data...',
        };
        const push = createOfflinePush(stack, setOrganizationToast, toastConfig);
        return push() // TODO maybe include uploads in offlinePush
            .then(({ uploads }) => {
                uploads.subscribe({
                    complete: () => {
                        setOrganizationToast('success', 'Successfully saved.', 5000);
                        pull(organizationView);
                    },
                });
                uploads.start();
            })
            .catch(error => {
                if (error.status) {
                    setOrganizationToast('error', error.message ? error.message : error.toString());
                } else {
                    setOrganizationToast('error', 'You are offline. Please sync when back online.', 5000);
                }
            });
    };

    const openRightsModal = useModal('organization-rights');
    const openNewOrgModal = useModal('new-org');
    const setOrganizationToast = useToast('organization');

    return (
        <>
            <div className="columns">
                <div>
                    <div className="field-column">
                        <DropdownField
                            options={organizationChoices}
                            onChange={option => {
                                setOrganizationId(option.value);
                                setProjectId(undefined);
                                setSubprojectId(undefined);
                            }}
                            value={organizationId}
                            label="Current organization"
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        {/* <Button
                            onClick={() => {
                                dissoc(ROOT_ORGANIZATION_EDGE)(APPLICATION_ROOT_NODE)(organization);
                                setOrganizationId(undefined);
                            }}
                        >
                            Delete organization
                        </Button> */}
                        {canWriteApp && (
                        <Button
                            onClick={() => {
                                const newOrganizationId = newId();
                                const newOrganization = {
                                    id: newOrganizationId,
                                };
                                add(ROOT_ORGANIZATION_EDGE)(APPLICATION_ROOT_NODE)(newOrganization, { order: newMsDate() });
                                setOrganizationId(newOrganizationId);
                                setRights(newOrganizationId)(user, {
                                    read: true,
                                    write: true,
                                    admin: true,
                                });
                                setProjectId(undefined);
                                setSubprojectId(undefined);
                                openNewOrgModal();
                            }}
                        >
                            <FaPlus />
                            <p style={{ marginLeft: 5 }}>Create Organization</p>
                        </Button>
                        )}
                        {isOrganizationAdmin && (
                        <Button
                            onClick={() => openRightsModal(organizationId)}
                        >
                            <SiOpenaccess />
                            <p style={{ marginLeft: 3 }}>Add Organization Admins</p>
                        </Button>
                        )}
                    </div>
                    {organizationId
                        ? (
                            <>
                                <TextField
                                    label="Organization name"
                                    value={organization.name || ''}
                                    onChange={value => update(R.assoc('name', value, organization))}
                                />
                                <ImageField
                                    label="Organization logo"
                                    value={organizationLogoUrl || ''}
                                    onChange={file => {
                                        setFile(organizationId)('organizationLogo', file.name, file);
                                        save();
                                    }}
                                    className="org-img-field"
                                />
                                <div style={{
                                    display: 'flex', flex: 1, justifyContent: 'center',
                                }}
                                >
                                    <Button
                                        onClick={save}
                                        style={{ width: 200 }}
                                    >
                                        <FaSave size={24} color="var(--corporate-color-1)" />
                                        <p style={{ fontSize: 16, marginLeft: 12, fontWeight: 'bold' }}>Save</p>
                                    </Button>
                                </div>
                            </>
                        )
                        : (
                            <p
                                className="dashboard-message"
                                style={{ marginTop: 100 }}
                            >
                                select or create an organization
                            </p>
                        )}
                    <p style={{ position: 'absolute', bottom: 10, color: 'var(--corporate-color-1)' }}>Version: 1.1.8</p>
                </div>
            </div>
            <OrganizationRightsModal
                stack={stack}
                save={save}
            />
            <NewOrgModal
                organization={organization}
                organizationId={organizationId}
                organizationLogoUrl={organizationLogoUrl}
                save={save}
                update={update}
                setFile={setFile}
            />
            <Toast name="organization" />
        </>
    );
};
