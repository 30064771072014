import React from 'react';
import {
    Route, Switch, useLocation, Redirect, useHistory,
} from 'react-router-dom';
import { useAuth } from './common/auth';
import { AuthScreen } from './common/AuthScreen';
import { DashboardScreen } from './Components/Screens/new/DashboardScreen';
import { GlobalDashboardScreen } from './Components/Screens/new/GlobalDashboardScreen';
import { ProtocolScreen } from './Components/Screens/new/ProtocolScreen';
import './AppNew.css';
// import { useNetwork } from './hooks/useNetwork';
import LandingPage from './Components/Elements/LandingPage/LandingPage';
import logo from './logos/pclogo.png';

function App() {
    const [loggedIn, login] = useAuth();
    // const isOnline = useNetwork();
    const location = useLocation();
    const history = useHistory();
    return (
        <>
            {!loggedIn && location.pathname === '/'
                ? (
                    <>
                        <Switch>
                            <Route exact path="/">
                                <LandingPage />
                            </Route>
                        </Switch>
                    </>
                )
                : !loggedIn && location.pathname === '/dashboard'
                    ? (
                        <div>
                            <img alt="logo" src={logo} style={{ width: 400 }} />
                            <AuthScreen onLoggedIn={() => login()} />
                        </div>
                    )
                    : !loggedIn && location.pathname === '/protocol'
                        ? (
                            <div>
                                <img alt="logo" src={logo} style={{ width: 400 }} />
                                <AuthScreen onLoggedIn={() => login()} />
                            </div>
                        )
                        : !loggedIn && location.pathname === '/global-dashboard'
                            ? (
                                <div>
                                    <img alt="logo" src={logo} style={{ width: 400 }} />
                                    <AuthScreen onLoggedIn={() => login()} />
                                </div>
                            )
                            : loggedIn && location.pathname === ('/protocol')
                                ? (
                                    <>
                                        <Switch>
                                            <Route exact path="/protocol">
                                                <ProtocolScreen />
                                            </Route>
                                        </Switch>
                                    </>
                                )
                                : loggedIn && location.pathname === ('/global-dashboard')
                                    ? (
                                        <>
                                            <Switch>
                                                <Route exact path="/global-dashboard">
                                                    <GlobalDashboardScreen />
                                                </Route>
                                            </Switch>
                                        </>
                                    )
                                    : loggedIn && location.pathname === ('/dashboard')
                                        ? (
                                            <>
                                                <Switch>
                                                    <Route exact path="/dashboard">
                                                        <DashboardScreen />
                                                    </Route>
                                                </Switch>
                                            </>
                                        )
                                        : loggedIn && location.pathname === ('/')
                                            ? (
                                                <>
                                                    <Switch>
                                                        <Redirect from="/" to="/dashboard" />
                                                        <Route exact path="/dashboard">
                                                            <DashboardScreen />
                                                        </Route>
                                                    </Switch>
                                                </>
                                            )
                                            : (
                                                <div
                                                    onClick={() => history.push('/')}
                                                    style={{
                                                        display: 'flex',
                                                        flex: '1',
                                                        height: '90vh',
                                                        cursor: 'pointer',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <img
                                                        className="hoverwienrover"
                                                        alt="logo"
                                                        src={logo}
                                                        style={{ width: 700, borderRadius: 70, padding: 20 }}
                                                    />
                                                </div>
                                            )}
        </>
    );
}

export default App;
