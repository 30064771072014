import * as R from 'ramda';
import { applyFilter, filterTops, searchAllTops } from './filteredtops.util';
import { constructNodeView } from './got-adjunct';
import { AuthStore } from './hooks.config';
import {
    CHART_TOPS_KEY,
    CONTACT_DIRECTORY_CONTACT_EDGE, DASHBOARD_FILTER_KEY, DASHBOARD_STATE_KEY, PROJECT_ID_KEY, SEARCH_STRING_KEY, SORTED_GROUPED_TOPS_KEY, store, SUBPROJECT_ID_KEY,
} from './hooks.config.new';
import { Id } from './util';

const createCsvCell = str => `"${R.compose(
    R.replace(/"/g, '\''),
)(str)}"`;

const getTopTable = R.compose(
    R.join('\n'),
    R.map(R.join(',')),
    R.prepend([
        'ID',
        'Type',
        'Description',
        'Responsible',
        'Status',
        'Due Date',
        'Category',
        'Reference',
        'Savings',
        'Cost Increase',
        'Protocol ID',
        'Meeting Date',
    ]),
    R.map(({
        top, responsible, protocolId, meetingDate,
    }) => [
        createCsvCell(R.propOr('', 'businessId', top)),
        createCsvCell(R.compose(
            R.toUpper,
            R.propOr('', 'type'),
        )(top)),
        createCsvCell(R.propOr('', 'description', top)),
        createCsvCell(R.propOr('', 'name', responsible)),
        createCsvCell(R.propOr('', 'status', top)),
        createCsvCell(R.compose(
            R.slice(0, 10),
            R.propOr('', 'dueDate'),
        )(top)),
        createCsvCell(R.propOr('', 'category', top)),
        createCsvCell(R.propOr('', 'reference', top)),
        createCsvCell(R.propOr('', 'savings', top)),
        createCsvCell(R.propOr('', 'costIncrease', top)),
        createCsvCell(R.defaultTo('', protocolId)),
        createCsvCell(R.compose(
            R.slice(0, 10),
            R.defaultTo(''),
        )(meetingDate)),
    ]),
);

const constructContactsView = projectId => projectId
    ? {
        [Id.contactDirectory(projectId)]: {
            include: {
                rights: true,
            },
            as: 'contactDirectory',
            edges: {
                [CONTACT_DIRECTORY_CONTACT_EDGE]: {
                    as: 'contacts',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

export const generateCsv = async stack => {
    const { tops: chartTops } = store.getVar(...stack)(CHART_TOPS_KEY) || {};

    if (chartTops) {
        return getTopTable(chartTops || []);
    }

    const { tops: sortedGroupedTops = {} } = store.getVar(...stack)(SORTED_GROUPED_TOPS_KEY);
    const topRows = R.compose(
        R.sortBy(R.prop('businessId')),
        R.values,
        R.map(R.last),
    )(sortedGroupedTops);

    const dashboardState = store.getVar(...stack)(DASHBOARD_STATE_KEY);
    const filter = store.getVar(...stack)(DASHBOARD_FILTER_KEY);
    const searchString = store.getVar(...stack)(SEARCH_STRING_KEY);

    const subprojectId = store.getVar(...stack)(SUBPROJECT_ID_KEY);
    const { [subprojectId]: subprojectBag } = store.getView(...stack)(constructNodeView(subprojectId));
    const subprojectBusinessId = R.pathOr('', ['node', 'businessId'], subprojectBag);

    const projectId = store.getVar(...stack)(PROJECT_ID_KEY);
    const contactsView = constructContactsView(projectId);
    const { contactDirectory } = store.getView(...stack)(contactsView);
    const user = await AuthStore.getCurrentUserAttr('email');
    const userName = R.compose(
        R.pathOr(user, ['node', 'name']),
        R.head,
        R.filter(R.pathEq(['node', 'email'], user)),
        R.values,
        R.propOr({}, 'contacts'),
    )(contactDirectory);

    const { searchedTops } = filterTops({
        filter,
        searchString,
        dashboardState,
        subprojectBusinessId,
        userName,
    })(topRows);

    const allDashboardTops = dashboardState === 'subproject'
        ? applyFilter(filter)(searchAllTops(`#${subprojectBusinessId}`, topRows))
        : dashboardState === 'user'
            ? applyFilter(filter)(searchAllTops(`@${userName}`, topRows))
            : topRows;

    return getTopTable(searchedTops || allDashboardTops);
};
export const downloadCsv = content => {
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const dataUri = R.compose(
            R.replace(/#/g, '%23'), // replace all # with %23 so that it doesnt kill the data URI when it appears
            encodeURI,
        )(content);
        link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${dataUri}`);
        link.setAttribute('download', 'tops.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};
