import * as R from 'ramda';
import { store } from './hooks.config.new';

export const filterCriticalOpps = (stack, projectId) => (opps = [], fnSelectNode = R.prop('node')) => {
    const msDate = Date.now();
    const statusses = projectId && R.compose(
        R.propOr([], 'oppStatusTypes'),
        store.getNode(...stack),
    )(projectId);

    return projectId && R.filter(opp => {
        const statusIndex = R.indexOf(fnSelectNode(opp).status, statusses);
        const statusDueDate = `${'dueDate_'}${statusses[statusIndex + 1]}`;
        const msDueDate = new Date(fnSelectNode(opp)[statusDueDate]).getTime();
        return msDueDate < msDate && opp;
    })(opps);
};
