import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useEffect, useMemo } from 'react';
import { setNodes } from '../../common/got-adjunct';
import { SORTED_GROUPED_TOPS_KEY, useGraph } from '../../common/hooks.config.new';
import { useNode } from './useNode';

export const useTopBusinessIds = (stack, sortedTopBags) => {
    const { useVar, mergeGraph } = useGraph(...stack);

    const [projectId] = useVar('projectId');
    const [subprojectId] = useVar('subprojectId');
    const [meetingId] = useVar('meetingId');

    const { node: { businessId: projectBusinessId } = {} } = useNode(stack, projectId);
    const { node: { businessId: subprojectBusinessId } = {} } = useNode(stack, subprojectId);
    const { node: { businessId: meetingBusinessId } = {} } = useNode(stack, meetingId);

    const [{ tops: topVersions }] = useVar(SORTED_GROUPED_TOPS_KEY);

    const createBusinessId = (type, index) => ['1', '2', '3'].includes(type)
        ? undefined
        : [
            projectBusinessId,
            subprojectBusinessId,
            meetingBusinessId,
            R.toUpper(type),
            R.drop(2, (index / 1000).toString()),
        ].join('-');

    const topBusinessIds = useMemo(() => R.map(
        R.path(['node', 'businessId']),
    )(sortedTopBags), [sortedTopBags]);

    const topTypes = useMemo(() => R.map(
        R.path(['node', 'type']),
    )(sortedTopBags), [sortedTopBags]);

    useEffect(() => projectBusinessId
     && subprojectBusinessId
     && meetingBusinessId
     && R.compose(
         mergeGraph,
         setNodes,
         RA.mapIndexed(({ node: top }, i) => {
             const businessId = createBusinessId(top.type || '', i + 1);
             return top.businessId !== businessId
                 ? { id: top.id, businessId }
                 : undefined;
         }),
         filterTopsToUpdate(topVersions),
     )(sortedTopBags), [
        projectBusinessId,
        subprojectBusinessId,
        meetingBusinessId,
        topBusinessIds,
        topTypes,
    ]);
};

const filterTopsToUpdate = topVersions => R.filter(({ node: top }) => {
    if (top && top.type) {
        if (!top.businessId) {
            return true;
        }

        if (topVersions) {
            const historyLength = R.compose(
                R.length,
                R.filter(topInfo => topInfo.top.id !== top.id),
                R.propOr([], top.businessId),
            )(topVersions);
            return historyLength === 0;
        }
    }

    return false;
});
