import { useDispatch } from 'react-redux';

export const useToast = name => {
    const dispatch = useDispatch();
    return async (type, message, duration = false) => {
        dispatch({ type: 'SHOW_TOAST', payload: { name, type, message } });
        duration && setTimeout(
            () => dispatch({ type: 'HIDE_TOAST', payload: { name } }),
            duration,
        );
    };
};
