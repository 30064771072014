import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useEffect, useMemo } from 'react';
import { APPLICATION_ROOT_NODE } from '../../../common/config';
import { filterCriticalOpps } from '../../../common/filterCriticalOpps';
import {
    CONTACT_DIRECTORY_CONTACT_EDGE,
    ORGANIZATION_PROJECT_EDGE,
    ROOT_ORGANIZATION_EDGE,
    store,
    TOP_RESPONSIBLE_EDGE,
    useGraph,
    USER_TOPS,
} from '../../../common/hooks.config.new';
import { Id } from '../../../common/util';
import { useUserEmail } from '../../../hooks/new/useUserEmail';

const constructContactTopView = contactId => contactId
    ? {
        [contactId]: {
            edges: {
                [TOP_RESPONSIBLE_EDGE]: {
                    reverse: true,
                    as: 'tops',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

const constructProjectContactsView = projectId => projectId
    ? {
        [Id.contactDirectory(projectId)]: {
            as: projectId,
            edges: {
                [CONTACT_DIRECTORY_CONTACT_EDGE]: {
                    as: 'contacts',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

const constructAllProjectsView = rootNodeId => rootNodeId
    ? {
        [rootNodeId]: {
            as: 'root',
            include: {
                node: true,
            },
            edges: {
                [ROOT_ORGANIZATION_EDGE]: {
                    as: 'organizations',
                    include: {
                        node: true,
                        edges: true,
                    },
                    edges: {
                        [ORGANIZATION_PROJECT_EDGE]: {
                            as: 'projects',
                            include: {
                                node: true,
                                edges: true,
                            },
                        },
                    },
                },
            },
        },
    }
    : {};

export const GloablDashboardCalculator = ({ stack }) => {
    const { useView, pull } = useGraph(...stack);
    const allProjectsView = constructAllProjectsView(APPLICATION_ROOT_NODE);
    useEffect(() => APPLICATION_ROOT_NODE && pull(allProjectsView), [APPLICATION_ROOT_NODE]);
    const allProjects = useView(allProjectsView);
    const projectIDs = R.compose(
        R.keys,
        R.mergeAll,
        R.map(R.pathOr({}, ['projects'])),
        R.values,
        R.pathOr({}, ['root', 'organizations']),
    )(allProjects);

    const projectsContactsView = R.compose(
        R.mergeAll,
        R.map(constructProjectContactsView),
    )(projectIDs);

    useEffect(() => RA.isNotNilOrEmpty(projectsContactsView) && pull(projectsContactsView), [allProjects]);
    const cdsContactsViewRes = useView(projectsContactsView);

    const user = useUserEmail();
    const projectsUserContacts = R.map(R.compose(
        R.values,
        R.filter(R.pathEq(['node', 'email'], user)),
        R.pathOr({}, ['contacts']),
    ))(cdsContactsViewRes);

    const userContactTopsView = R.compose(
        R.mergeAll,
        R.map(({ nodeId: contactId }) => constructContactTopView(contactId)),
        R.flatten,
        R.values,
    )(projectsUserContacts);
    useEffect(() => pull(userContactTopsView), [cdsContactsViewRes]);
    const contactTopsViewResult = useView(userContactTopsView);

    const projectsUserTops = useMemo(() => R.compose(
        R.mergeAll,
        R.map(projectId => {
            const userContacts = R.propOr([], projectId, projectsUserContacts);
            const projectUserTops = R.compose(
                R.values,
                R.mergeAll,
                R.map(({ nodeId: contactId }) => R.pathOr({}, [contactId, 'tops'], contactTopsViewResult)),
            )(userContacts);

            const actions = R.filter(R.pathEq(['node', 'type'], 'a'))(projectUserTops);
            const criticalActions = R.filter(R.pathEq(['node', 'status'], 'Late'))(actions);
            const opps = R.filter(R.pathEq(['node', 'type'], 'o'))(projectUserTops);
            const criticalOpps = filterCriticalOpps(stack, projectId)(opps);

            return {
                [projectId]: {
                    projectId,
                    tops: {
                        all: projectUserTops,
                        actions,
                        criticalActions,
                        opps,
                        criticalOpps,
                    },
                },
            };
        }),
    )(projectIDs), [contactTopsViewResult]);

    useEffect(() => {
        store.setVar(R.last(stack))(USER_TOPS, projectsUserTops);
    }, [projectsUserTops]);

    return null;
};
