import React, { useEffect } from 'react';
import * as R from 'ramda';
import { newId, newMsDate } from '@gothub-team/got';
import { FaPlus, FaProjectDiagram, FaSave } from 'react-icons/fa';
import { SiOpenaccess } from 'react-icons/si';
import { Modal } from '../../Common/Modal';
import {
    ImageField, LargeTextField, ListField, TextField,
} from '../../Common/Fields';
import { DropdownField } from '../../Common/Dropdown';
import { Button } from '../../Common/Controls';
import { useModal, useModalState } from '../../../hooks/new/useModal';
import { useUserEmail } from '../../../hooks/new/useUserEmail';
import {
    CONTACT_DIRECTORY_CONTACT_EDGE,
    ORGANIZATION_ID_KEY,
    ORGANIZATION_PROJECT_EDGE, PROJECTS_MODAL, PROJECT_CONTACT_DIRECTORY_EDGE, PROJECT_ID_KEY, PROJECT_SUBPROJECT_EDGE, store, SUBPROJECT_ID_KEY, SUBPROJECT_MEETING_EDGE, TOPS_TO_UPDATE_KEY, useGraph,
} from '../../../common/hooks.config.new';
import { constructNodeView, createOfflinePush } from '../../../common/got-adjunct';
import { ProjectRightsModal } from './ProjectRightsModal';
import { useToast } from '../../../hooks/new/useToast';
import { SubprojectRightsModal } from './SubprojectRightsModal';
import { Toast } from '../../Elements/new/Toast';
import { Id } from '../../../common/util';
import { NewProjectModal } from './NewProjectModal';
import { NewSubprojectModal } from './NewSubprojectModal';

const constructProjectsView = organizationId => organizationId
    ? {
        [organizationId]: {
            as: 'organization',
            include: {
                node: true,
            },
            edges: {
                [ORGANIZATION_PROJECT_EDGE]: {
                    as: 'projects',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

const constructSubprojectsView = projectId => projectId
    ? {
        [projectId]: {
            as: 'project',
            include: {
                node: true,
            },
            edges: {
                [PROJECT_SUBPROJECT_EDGE]: {
                    as: 'subprojects',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

const constructMeetingsView = subprojectId => subprojectId
    ? {
        [subprojectId]: {
            as: 'subproject',
            include: {
                node: true,
                rights: true,
                files: true,
            },
            edges: {
                [SUBPROJECT_MEETING_EDGE]: {
                    as: 'meetings',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    }
    : {};

export function ProjectsModal({
    stack,
}) {
    const [isOpen] = useModalState('projects');

    return (
        <Modal
            name="projects"
            style={{
                height: '91vh',
                top: '4.5vh',
                width: '80vw',
                left: '10vw',
            }}
            topbar={(
                <>
                    <FaProjectDiagram size={23} color="var(--corporate-color-7)" />
                </>
            )}
        >
            {isOpen
                ? (
                    <ProjectsModalContent
                        stack={stack}
                    />
                )
                : null}
        </Modal>
    );
}

const ProjectsModalContent = ({
    stack: parentStack,
}) => {
    const user = useUserEmail();

    // organazation selection
    const {
        useVar,
    } = useGraph(...parentStack);
    const [organizationId] = useVar(ORGANIZATION_ID_KEY);
    const [projectId, setProjectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);

    // our graph stack
    const stack = R.append(PROJECTS_MODAL, parentStack);
    const {
        useView, pull, add, update, setFile, inheritRights, setRights,
    } = useGraph(...stack);

    // user rights on organization
    const organizationView = constructNodeView(organizationId, { rights: true });
    useEffect(() => organizationId && pull(organizationView), [organizationId]);
    const { [organizationId]: organizationBag = {} } = useView(organizationView);
    const canAdminOrganization = R.pathOr(false, ['rights', 'user', user, 'admin'], organizationBag);

    // project choices
    const projectsView = constructProjectsView(organizationId);
    const { organization: { projects = {} } = {} } = useView(projectsView);
    const projectChoices = R.map(projectBag => ({
        value: R.path(['node', 'id'], projectBag),
        label: R.path(['node', 'title'], projectBag),
        body: R.path(['node'], projectBag),
    }))(R.values(projects));

    // selected project
    const projectView = constructNodeView(projectId, { node: true, rights: true, files: true });
    useEffect(() => projectId && pull(projectView), [projectId]);
    const { [projectId]: projectBag = {} } = useView(projectView);
    const canAdminProject = R.pathOr(false, ['rights', 'user', user, 'admin'], projectBag);
    const canWriteProject = R.pathOr(false, ['rights', 'user', user, 'write'], projectBag);
    const customerLogo = R.path(['files', 'customerLogo', 'url'], projectBag);
    const project = R.propOr({}, 'node', projectBag);

    // subproject choices
    const subprojectsView = constructSubprojectsView(projectId);
    const { project: { subprojects = {} } = {} } = useView(subprojectsView);
    const subprojectChoices = R.map(subprojectBag => ({
        value: R.path(['node', 'id'], subprojectBag),
        label: R.path(['node', 'title'], subprojectBag),
        body: R.path(['node'], subprojectBag),
    }))(R.values(subprojects));

    // selected subproject
    const subprojectView = constructMeetingsView(subprojectId);
    useEffect(() => subprojectId && pull(subprojectView), [subprojectId]);
    const { subproject: subprojectBag = {} } = useView(subprojectView);
    const canAdminSubproject = R.pathOr(false, ['rights', 'user', user, 'admin'], subprojectBag);
    const canWriteSubproject = R.pathOr(false, ['rights', 'user', user, 'write'], subprojectBag);
    const subproject = R.propOr({}, 'node', subprojectBag);

    // meeting choices
    const meetingBags = R.propOr({}, 'meetings', subprojectBag);

    const meetingChoices = R.compose(
        R.map(meetingBag => ({
            value: R.path(['node', 'id'], meetingBag),
            label: R.path(['node', 'title'], meetingBag),
            body: R.pathOr({}, ['node'], meetingBag),
        })),
        R.reverse,
        R.filter(R.pathEq(['node', 'isDummy'], undefined)),
        R.sortBy(R.path(['node', 'date'])),
        R.values,
    )(meetingBags);

    const setProjectToast = useToast('project');
    const save = async () => {
        setProjectToast('spinner', 'Saving project data...');
        const toastConfig = {
            textOnStart: 'Saving project data...',
        };
        const push = createOfflinePush(stack, setProjectToast, toastConfig);
        return push()
            .then(({ uploads }) => {
                uploads.subscribe({
                    complete: () => {
                        setProjectToast('success', 'Successfully saved.', 5000);
                        pull(projectView);
                    },
                });
                uploads.start();
            })
            .catch(error => {
                if (error.status) {
                    setProjectToast('error', error.message ? error.message : error.toString());
                } else {
                    setProjectToast('error', 'You are offline. Please sync when back online.', 5000);
                }
            });
    };

    const openProjectRightsModal = useModal('project-rights');
    const openSubprojectRightsModal = useModal('subproject-rights');
    const openProcolPdf = useModal('protocol-pdf');
    const openNewProjectModal = useModal('new-project');
    const openNewSubprojectModal = useModal('new-subproject');

    const userMail = useUserEmail();

    return (
        <>
            <div className="columns">
                <div style={{ paddingRight: 25 }}>
                    <div className="opp-headline" style={{ marginBottom: 12 }}>
                        Project
                    </div>
                    <div className="field-column">
                        <DropdownField
                            options={projectChoices}
                            onChange={option => {
                                setProjectId(option.value);
                                store.setVar(R.last(stack))(TOPS_TO_UPDATE_KEY, {});
                                setSubprojectId(undefined);
                            }}
                            value={projectId}
                            label="Current project"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {/* <Button
                            onClick={() => {
                                dissoc(ORGANIZATION_PROJECT_EDGE)(organizationId)(project);
                                setProjectId(undefined);
                            }}
                        >
                            Delete project
                        </Button> */}
                        {canAdminOrganization && (
                        <Button
                            onClick={async () => {
                                const newProjectId = newId();
                                const defaultActionStatusTypes = 'Open\nLate\nDone';
                                const newProject = {
                                    id: newProjectId,
                                };
                                const newContactDirectoryId = Id.contactDirectory(newProjectId);
                                const newContactDirectory = {
                                    id: newContactDirectoryId,
                                };
                                add(ORGANIZATION_PROJECT_EDGE)(organizationId)(newProject, { order: newMsDate() });
                                add(PROJECT_CONTACT_DIRECTORY_EDGE)(newProjectId)(newContactDirectory);
                                update(R.assoc('actionStatusTypes', R.split('\n', defaultActionStatusTypes), newProject));
                                setRights(newProjectId)(user, {
                                    read: true,
                                    write: true,
                                    admin: true,
                                });
                                setProjectId(newProjectId);
                                setSubprojectId(undefined);
                                const newContactId = newId();
                                const newContact = {
                                    id: newContactId,
                                    name: `User - ${userMail}`,
                                    email: userMail,
                                };
                                add(CONTACT_DIRECTORY_CONTACT_EDGE)(newContactDirectoryId)(newContact);
                                openNewProjectModal();
                            }}
                        >
                            <FaPlus />
                            <p style={{ marginLeft: 5 }}>Create Project</p>
                        </Button>
                        )}
                        {canAdminProject && (
                        <Button
                            onClick={() => {
                                openProjectRightsModal(projectId);
                            }}
                        >
                            <SiOpenaccess />
                            <p style={{ marginLeft: 3 }}>Manage Project Rights</p>
                        </Button>
                        )}
                    </div>
                    {projectId
                        ? (
                            <>
                                <TextField
                                    label="Project name"
                                    value={project.title || ''}
                                    onChange={value => update(R.assoc('title', value, project))}
                                    editable={canAdminProject}
                                />
                                <TextField
                                    label="Project ID"
                                    value={project.businessId || ''}
                                    onChange={value => update(R.assoc('businessId', value, project))}
                                    editable={canAdminProject}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ flex: 3 }}>
                                        <TextField
                                            label="Customer name"
                                            value={project.customerName || ''}
                                            onChange={value => update(R.assoc('customerName', value, project))}
                                            editable={canAdminProject}
                                        />
                                    </div>
                                    <div style={{ flex: 1, marginLeft: 20 }}>
                                        <DropdownField
                                            options={[
                                                { value: 'EUR', label: 'Euro' },
                                                { value: 'USD', label: 'US-Dollar' },
                                                { value: 'CHF', label: 'Swiss Franc' },
                                            ]}
                                            onChange={value => {
                                                update(R.assoc('currency', value.value, project));
                                            }}
                                            value={project.currency || ''}
                                            label="Currency"
                                            editable={canAdminProject}
                                        />
                                    </div>
                                </div>
                                <ImageField
                                    label="Customer logo"
                                    value={customerLogo || ''}
                                    onChange={file => {
                                        setFile(projectId)('customerLogo', file.name, file);
                                        save();
                                    }}
                                    editable={canAdminProject}
                                />
                                <div className="types-container">
                                    <div style={{ width: '47.5%' }}>
                                        <LargeTextField
                                            style={{ resize: 'none' }}
                                            label="Meeting Types"
                                            rows="6"
                                            value={R.join('\n', project.meetingTypes || [])}
                                            onChange={value => update(R.assoc('meetingTypes', R.split('\n', value), project))}
                                            editable={canAdminProject}
                                        />
                                    </div>
                                    <div style={{ width: '47.5%' }}>
                                        <LargeTextField
                                            style={{ resize: 'none' }}
                                            label="Action Status Types"
                                            rows="6"
                                            value={R.join('\n', project.actionStatusTypes || [])}
                                            onChange={value => update(R.assoc('actionStatusTypes', R.split('\n', value), project))}
                                            editable={canAdminProject}
                                        />
                                    </div>
                                </div>
                                <div className="types-container">
                                    <div style={{ width: '47.5%' }}>
                                        <LargeTextField
                                            style={{ resize: 'none' }}
                                            label="Opportunity Status Types (ascending order)"
                                            rows="6"
                                            value={R.join('\n', project.oppStatusTypes || [])}
                                            onChange={value => update(R.assoc('oppStatusTypes', R.split('\n', value), project))}
                                            editable={canAdminProject}
                                        />
                                    </div>
                                    <div style={{ width: '47.5%' }}>
                                        <LargeTextField
                                            style={{ resize: 'none' }}
                                            label="Opportunity Category Types"
                                            rows="6"
                                            value={R.join('\n', project.oppCategoryTypes || [])}
                                            onChange={value => update(R.assoc('oppCategoryTypes', R.split('\n', value), project))}
                                            editable={canAdminProject}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                        : (
                            <p
                                className="dashboard-message"
                            >
                                select or create a project
                            </p>
                        )}
                </div>
                <div style={{ paddingLeft: 25 }}>
                    <div className="opp-headline" style={{ marginBottom: 12 }}>
                        Subproject
                    </div>
                    <div className="field-column">
                        <DropdownField
                            options={subprojectChoices}
                            onChange={option => setSubprojectId(option.value)}
                            value={subprojectId}
                            label="Current subproject"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {/* <Button
                            onClick={() => {
                                dissoc(PROJECT_SUBPROJECT_EDGE)(projectId)(subproject);
                                setSubprojectId(undefined);
                            }}
                        >
                            Delete subproject
                        </Button> */}
                        {canWriteProject && (
                        <Button
                            onClick={() => {
                                const newSubprojectId = newId();
                                const newSubproject = {
                                    id: newSubprojectId,
                                };
                                add(PROJECT_SUBPROJECT_EDGE)(projectId)(newSubproject, { order: newMsDate() });
                                setRights(newSubprojectId)(user, {
                                    read: true,
                                    write: true,
                                });
                                inheritRights(newSubprojectId)(projectId);
                                setSubprojectId(newSubprojectId);
                                openNewSubprojectModal();
                            }}
                        >
                            <FaPlus />
                            <p style={{ marginLeft: 5 }}>Create Subproject</p>
                        </Button>
                        )}
                        {canAdminSubproject && (
                        <Button
                            onClick={() => {
                                openSubprojectRightsModal(subprojectId);
                            }}
                        >
                            <SiOpenaccess />
                            <p style={{ marginLeft: 3 }}>Manage Subproject Rights</p>
                        </Button>
                        )}
                    </div>
                    {subprojectId
                        ? (
                            <>
                                <TextField
                                    label="Subproject name"
                                    value={subproject.title || ''}
                                    onChange={value => update(R.assoc('title', value, subproject))}
                                    editable={canAdminProject}
                                />
                                <TextField
                                    label="Subproject ID"
                                    value={subproject.businessId || ''}
                                    onChange={value => update(R.assoc('businessId', value, subproject))}
                                    editable={canAdminProject}
                                />
                                <ListField
                                    className
                                    label="Protocols"
                                    style={{ height: 416 }}
                                >
                                    {R.map(
                                        m => (
                                            <div
                                                key={m.value}
                                                onClick={() => openProcolPdf(m.value)}
                                                className="row"
                                            >
                                                <div>{m.label}</div>
                                                <div style={{ color: 'var(--color-2)' }}>{m.body.businessId}</div>
                                            </div>
                                        ),
                                    )(meetingChoices)}
                                </ListField>
                            </>
                        )
                        : (
                            projectId
                                ? (
                                    <p
                                        className="dashboard-message"
                                    >
                                        select or create a subproject
                                    </p>
                                )
                                : null
                        )}
                </div>
            </div>
            {(projectId || subprojectId) && (
                <div style={{
                    display: 'flex', flex: 1, justifyContent: 'center', marginTop: 12,
                }}
                >
                    <Button
                        onClick={save}
                        style={{ width: 200 }}
                    >
                        <FaSave size={24} color="var(--corporate-color-1)" />
                        <p style={{ fontSize: 16, marginLeft: 12, fontWeight: 'bold' }}>Save</p>
                    </Button>
                </div>
            )}
            <ProjectRightsModal stack={stack} save={save} />
            <SubprojectRightsModal stack={stack} save={save} />
            <NewProjectModal
                save={save}
                update={update}
                setFile={setFile}
                project={project}
                canWriteProject={canWriteProject}
                customerLogo={customerLogo}
                projectId={projectId}
            />
            <NewSubprojectModal
                save={save}
                update={update}
                subproject={subproject}
                canWriteSubproject={canWriteSubproject}
            />
            <Toast name="project" />
        </>
    );
};
