import * as R from 'ramda';
import {
    createAuth, createApi, createStore, createHooks,
} from '@gothub-team/got-react';
import { store as reduxStore } from '../redux/store';
import { API_DOMAIN_2, CLIENT_ID, USER_POOL_ID } from './config';

export const BASE_STATE = 'got2';

const auth = createAuth({ clientId: CLIENT_ID, userpoolId: USER_POOL_ID });
const api = createApi({
    getAuthToken: auth.getAuthToken,
    baseUrl: `https://${API_DOMAIN_2}`,
    // baseUrl: 'http://localhost:4000/dev',
});
export const store = createStore({
    api,
    dispatch: reduxStore.dispatch,
    select: selector => selector(R.propOr({}, BASE_STATE, reduxStore.getState())),
    onError: err => { console.warn(err); throw err; },
});
export const { useGraph } = createHooks({ store, baseState: R.prop(BASE_STATE) });

// graph names
export const MAIN = 'main';
export const OFFLINE = 'offline';
export const CONTACTS = 'contacts';
export const PROTOCOL_MAIN = 'main-protocol';
export const DASHBOARD = 'dashboard';
export const PROTOCOL_VARS = 'protocol-vars';
export const PROTOCOL = 'protocol';
export const ATTACHMENT_MODAL_MAIN = 'attachment-modal-main';
export const ATTACHMENT_MODAL = 'attachment-modal';
export const ORGANIZATIONS_MODAL = 'organizations-modal';
export const PROJECTS_MODAL = 'projects-modal';
export const CONTACTS_MODAL = 'contacts-modal';
export const SCORE_CARD_MODAL = 'score-card-modal';
export const PROTOCOL_PDF_MODAL = 'protocol-pdf-modal';
export const EDIT_TOP_MODAL = 'edit-top-modal';
export const EXISTING_MEETINGS_MODAL = 'existing-meetings-modal';
export const PROJECT_ACTIONS_MODAL = 'project-actions-modal';
export const PROJECT_OPPS_MODAL = 'project-opps-modal';

// edges
export const ROOT_ORGANIZATION_EDGE = 'root/organization';
export const ORGANIZATION_PROJECT_EDGE = 'organization/project';
export const PROJECT_SUBPROJECT_EDGE = 'project/subproject';
export const SUBPROJECT_MEETING_EDGE = 'subproject/meeting';
export const PROJECT_CONTACT_DIRECTORY_EDGE = 'project/contact-directory';
export const CONTACT_DIRECTORY_CONTACT_EDGE = 'contact-directory/contact';
export const MEETING_ATTENDEE_EDGE = 'meeting/attendee';
export const MEETING_TOP_EDGE = 'meeting/top';
export const MEETING_ATTACHMENT_EDGE = 'meeting/attachment';
export const TOP_RESPONSIBLE_EDGE = 'top/responsible';
export const TOP_ACTION_EDGE = 'top/action';

// var keys
export const ORGANIZATION_ID_KEY = 'organizationId';
export const PROJECT_ID_KEY = 'projectId';
export const SUBPROJECT_ID_KEY = 'subprojectId';
export const MEETING_ID_KEY = 'meetingId';
export const GROUPED_TOPS_KEY = 'groupedTops';
export const SORTED_GROUPED_TOPS_KEY = 'sortedGroupedTops';
export const TOP_ROWS_KEY = 'topRows';
export const TOP_HISTORIES_KEY = 'topHistories';
export const DASHBOARD_STATE_KEY = 'dashboardState';
export const DASHBOARD_FILTER_KEY = 'dashboardFilter';
export const CHART_TOPS_KEY = 'chartTops';
export const TOPS_TO_UPDATE_KEY = 'topsToUpdate';
export const SEARCH_STRING_KEY = 'searchString';
export const USER_TOPS = 'userTops';
