import * as RA from 'ramda-adjunct';
import * as R from 'ramda';
import axios from 'axios';
import { useMemo } from 'react';
import { useNode } from './useNode';
import {
    MEETING_ATTACHMENT_EDGE, useGraph,
} from '../../common/hooks.config.new';
import { useMeetingAttendees } from './useMeetingAttendees';
import { useMeetingTopsWithResponsibles } from './useMeetingTops';
import { businessNodeString, formatShortDate, topHierarchy } from '../../common/util';
import { useAttachments } from './useAttachments';
import { AuthStore, useFile } from '../../common/hooks.config';
import { BACKEND_API_DOMAIN } from '../../common/config';
import { useProjectCurrency } from './useChoices';
import { useGetPreviousVersions } from './useTopHistories';

export const usePrintProtocol = stack => {
    const { useVar } = useGraph(...stack);

    const [organizationId] = useVar('organizationId');
    const [projectId] = useVar('projectId');
    const [subprojectId] = useVar('subprojectId');
    const [meetingId] = useVar('meetingId');

    // const { node: organization } = useNode(stack, organizationId);
    const { node: project } = useNode(stack, projectId);
    const { node: subproject } = useNode(stack, subprojectId);
    const { node: meeting } = useNode(stack, meetingId);

    const attendees = useMeetingAttendees(stack, meetingId);
    const sortedTopsBags = useMeetingTopsWithResponsibles(stack, meetingId);
    const hierarchy = useMemo(() => R.compose(
        topHierarchy,
        R.map(R.prop('node')),
    )(sortedTopsBags), [sortedTopsBags]);

    const currency = useProjectCurrency(stack);
    const format = val => new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: R.defaultTo('EUR', currency),
    }).format(val);
    const getPreviousVersions = useGetPreviousVersions(stack);

    const { attachmentBags } = useAttachments(stack, meetingId, MEETING_ATTACHMENT_EDGE);

    // const protocolUrl = R.pathOr('', ['protocol', 'url'], meetingFiles);
    const [protocolUrl, fetchProtocol] = useFile(meetingId, 'protocol');

    // const [, setToast] = useToast('protocol', 'print');

    const lineMaker = (s, label) => (
        `${s ? `\n${label}: ${s}` : ''}`);
    const formatDescription = ({
        description,
        status,
        category,
        savings,
    }) => `${description}${lineMaker(status, 'Status')}${lineMaker(category, 'Category')}${lineMaker(savings && format(savings), 'Savings')}`;

    // TODO refactor this into smaller components, render a la react?
    const getPdfData = () => ({
        'organizationId': organizationId,
        'projectId': projectId,
        'meetingId': meetingId,
        'headline': 'Minutes of Meeting',
        'headerFields': [
            [
                'Project',
                businessNodeString(project) || '',
            ],
            [
                'Subproject',
                businessNodeString(subproject) || '',
            ],
            [
                'Meeting',
                businessNodeString(meeting) || '',
            ],
            [
                'Meeting Type',
                meeting.type || '',
            ],
            [
                'Date/Time',
                formatShortDate(meeting.date) || '',
            ],
            [
                'Location',
                meeting.location || '',
            ],
            [
                'Protocol ID',
                R.join('-', [
                    project.businessId,
                    subproject.businessId,
                    meeting.businessId,
                ]),
            ],
        ],
        'attendeeTable': [
            [
                'Attendees',
                'Status',
            ],
            ...R.map(
                ({
                    node: {
                        name = '',
                    } = {},
                    metadata: {
                        presence = '',
                    } = {},
                }) => [
                    name,
                    presence,
                ],
            )(attendees),
        ],
        'attendeeLegend': 'A… Attended, P… Partly Attended, N… Not Attended, M… Minutes Keeper',
        'contentTable': [
            'Meeting Content',
            [
                'No.',
                'T',
                'Description',
                'Due Date',
                'Resp.',
            ],
            ...R.compose(
                R.unnest,
                R.map(
                    ({
                        node: {
                            businessId = '',
                            type = '',
                            description = '',
                            status = '',
                            savings = '',
                            category = '',
                            dueDate = '',
                            id = '',
                        } = {},
                        responsibles = {},
                    }) => [
                        ...R.compose(
                            RA.mapIndexed(({
                                top: {
                                    description: prevDescription = '',
                                    status: prevStatus = '',
                                    savings: prevSavings = '',
                                    category: prevCategory = '',
                                    dueDate: prevDueDate = '',
                                } = {},
                                responsible: {
                                    name: prevResponsible = '',
                                } = {},
                                editedDate = '',
                                author: {
                                    name: authorName,
                                } = {},
                            } = {}, i) => [
                                i === 0 ? (hierarchy[id] || '') : '',
                                i === 0
                                    ? R.ifElse(
                                        Number,
                                        RA.stubString,
                                        R.toUpper,
                                    )(type)
                                    : '',
                                [
                                    formatDescription({
                                        description: prevDescription,
                                        status: prevStatus,
                                        savings: prevSavings,
                                        category: prevCategory,
                                    }),
                                    editedDate
                                        ? `${i === 0 ? 'created' : 'changed'} on ${formatShortDate(editedDate)}${authorName ? ` by ${authorName}` : ''}`
                                        : '',
                                ],
                                formatShortDate(prevDueDate) || '',
                                prevResponsible,
                            ]),
                            getPreviousVersions,
                        )(businessId),
                        [
                            R.ifElse(
                                R.anyPass([
                                    Number,
                                    () => getPreviousVersions(businessId).length === 0,
                                ]),
                                R.always(hierarchy[id] || ''),
                                RA.stubString,
                            )(type),
                            R.ifElse(
                                R.anyPass([
                                    Number,
                                    () => getPreviousVersions(businessId).length === 0,
                                ]),
                                R.toUpper,
                                RA.stubString,
                            )(type),
                            [
                                formatDescription({
                                    description,
                                    status,
                                    category,
                                    savings,
                                }),
                                businessId,
                            ],
                            formatShortDate(dueDate) || '',
                            R.compose(
                                R.pathOr('', ['node', 'name']),
                                R.head,
                                R.values,
                            )(responsibles),
                        ],
                    ],
                ),
            )(sortedTopsBags),
        ],
        'contentLegend': 'T… Type, A… Action, O… Opportunity, R… Risk, D… Decision, S… Statement, I… Information',
        'actionsTable': [
            'Actions',
            [
                'No.',
                'Description',
                'Status',
                'Due Date',
                'Resp.',
            ],
            ...R.compose(
                R.unnest,
                RA.mapIndexed(
                    ({
                        node: {
                            businessId = '',
                            description = '',
                            status = '',
                            dueDate = '',
                        } = {},
                        responsibles = {},
                    }, i) => [
                        ...R.compose(
                            RA.mapIndexed(({
                                top: {
                                    description: prevDescription = '',
                                    status: prevStatus = '',
                                    dueDate: prevDueDate = '',
                                } = {},
                                responsible: {
                                    name: prevResponsible = '',
                                } = {},
                                editedDate = '',
                                author: {
                                    name: authorName,
                                } = {},
                            } = {}, j) => [
                                j === 0 ? `${i + 1}` : '',
                                [
                                    prevDescription,
                                    editedDate
                                        ? `${i === 0 ? 'created' : 'changed'} on ${formatShortDate(editedDate)}${authorName ? ` by ${authorName}` : ''}`
                                        : '',
                                ],
                                prevStatus,
                                prevDueDate,
                                prevResponsible,
                            ]),
                            getPreviousVersions,
                        )(businessId),
                        [
                            getPreviousVersions(businessId).length === 0 ? `${i + 1}` : '',
                            [
                                description,
                                businessId,
                            ],
                            status,
                            formatShortDate(dueDate) || '',
                            R.compose(
                                R.pathOr('', ['node', 'name']),
                                R.head,
                                R.values,
                            )(responsibles),
                        ],
                    ],
                ),
                R.filter(t => t.node.type === 'a'),
            )(sortedTopsBags),
        ],
        'opportunitiesTable': [
            'Opportunities',
            [
                'No.',
                'Description',
                'Status',
                'Category',
                'Savings',
                'Resp.',
            ],
            ...R.compose(
                R.unnest,
                RA.mapIndexed(
                    ({
                        node: {
                            businessId = '',
                            description = '',
                            status = '',
                            category = '',
                            savings = '',
                        } = {},
                        responsibles = {},
                    }, i) => [
                        ...R.compose(
                            RA.mapIndexed(({
                                top: {
                                    description: prevDescription = '',
                                    status: prevStatus = '',
                                    category: prevCategory = '',
                                    savings: prevSavings = '',
                                } = {},
                                responsible: {
                                    name: prevResponsible = '',
                                } = {},
                                editedDate = '',
                                author: {
                                    name: authorName,
                                } = {},
                            } = {}, j) => [
                                j === 0 ? `${i + 1}` : '',
                                [
                                    prevDescription,
                                    editedDate
                                        ? `${i === 0 ? 'created' : 'changed'} on ${formatShortDate(editedDate)}${authorName ? ` by ${authorName}` : ''}`
                                        : '',
                                ],
                                prevStatus,
                                prevCategory,
                                prevSavings && format(prevSavings),
                                prevResponsible,
                            ]),
                            getPreviousVersions,
                        )(businessId),
                        [
                            getPreviousVersions(businessId).length === 0 ? `${i + 1}` : '',
                            [
                                description,
                                businessId,
                            ],
                            status,
                            category,
                            savings && format(savings),
                            R.compose(
                                R.pathOr('', ['node', 'name']),
                                R.head,
                                R.values,
                            )(responsibles),
                        ],
                    ],
                ),
                R.filter(t => t.node.type === 'o'),
            )(sortedTopsBags),
        ],
        'attachments': R.compose(
            R.values,
            R.map(
                R.path(['node', 'filename']),
            ),
        )(attachmentBags),
    });

    const generateProtocol = async () => {
        const pdfData = getPdfData();
        if (pdfData.meetingId) {
            // setToast('spinner', 'Generating protocol PDF...');
            const authHeader = await AuthStore.authHeader();
            await axios.post(
                // 'http://localhost:4000/dev/generate-protocol',
                `https://${BACKEND_API_DOMAIN}/generate-protocol`,
                pdfData,
                authHeader,
            )
                .then(() => fetchProtocol({ contentType: 'application/pdf' }));
            // .then(() => setToast('success', 'Protocol successfully generated.', 5000))
            // .catch(error => {
            //     if (error.name === 'DownloadFailedError') {
            //         setToast('error', 'No protocol was generated. Please retry or contact support.');
            //     } else {
            //         setToast('error', error.toString());
            //     }
            // });
        }
    };

    return [protocolUrl, generateProtocol];
};
